export const liste_bank = [
  {
    label: "Banque Atlantique - Côte d’Ivoire (BACI)",
    value: "Banque Atlantique - Côte d’Ivoire (BACI)",
  },
  {
    label: "Bridge Bank Group Côte d’Ivoire (BBG-CI)",
    value: "Bridge Bank Group Côte d’Ivoire (BBG-CI)",
  },
  { label: "Banque D'Abidjan", value: "Banque D'Abidjan" },
  { label: "Afriland first bank", value: "Afriland first bank" },
  {
    label: "Banque de l'Union Côte d'Ivoire",
    value: "Banque de l'Union Côte d'Ivoire",
  },
  { label: "BGFIBANK Cote d’Ivoire", value: "BGFIBANK Cote d’Ivoire" },
  {
    label: "Banque de l’Habitat de Côte d’Ivoire (BHCI)",
    value: "Banque de l’Habitat de Côte d’Ivoire (BHCI)",
  },
  {
    label:
      "Banque Internationale pour le Commerce et l’Industrie de la Côte d’Ivoire ( BICICI )",
    value:
      "Banque Internationale pour le Commerce et l’Industrie de la Côte d’Ivoire ( BICICI )",
  },
  {
    label: "Banque Malienne de Solidarité",
    value: "Banque Malienne de Solidarité",
  },
  {
    label: "Banque Nationale d’Investissement (BNI)",
    value: "Banque Nationale d’Investissement (BNI)",
  },
  {
    label: "Bank Of Africa-Côte d’Ivoire (BOA-Côte d’Ivoire)",
    value: "Bank Of Africa-Côte d’Ivoire (BOA-Côte d’Ivoire)",
  },
  {
    label: "Banque Régionale des Marchés",
    value: "Banque Régionale des Marchés",
  },
  {
    label:
      "Banque Sahélo-Saharienne pour l’Investissement et le Commerce - Côte d’Ivoire (BSIC - Côte d’Ivoire",
    value:
      "Banque Sahélo-Saharienne pour l’Investissement et le Commerce - Côte d’Ivoire (BSIC - Côte d’Ivoire",
  },
  { label: "CORIS Bank Internationale", value: "CORIS Bank Internationale" },
  { label: "CITIBANK CI", value: "CITIBANK CI" },
  {
    label: "Caisse Nationale des Caisses d’Epargne de la Côte d’Ivoire (CNCE)",
    value: "Caisse Nationale des Caisses d’Epargne de la Côte d’Ivoire (CNCE)",
  },
  { label: "Ecobank - Côte d’Ivoire", value: "Ecobank - Côte d’Ivoire" },
  { label: "Fidelis finance", value: "Fidelis finance" },
  {
    label: "Guaranty Trust Bank Cote d’Ivoire (GTBANK-CI)",
    value: "Guaranty Trust Bank Cote d’Ivoire (GTBANK-CI)",
  },
  { label: "MANSA BANK", value: "MANSA BANK" },
  { label: "NSIA BANQUE", value: "NSIA BANQUE" },
  { label: "Orange Abidjan Compagnie", value: "Orange Abidjan Compagnie" },
  { label: "ORABANK", value: "ORABANK" },
  { label: "Alios finance", value: "Alios finance" },
  {
    label: "Standard Chartered Bank Côte d’Ivoire (SCB)",
    value: "Standard Chartered Bank Côte d’Ivoire (SCB)",
  },
  {
    label: "Société Générale Côte d’Ivoire (SG-CI)",
    value: "Société Générale Côte d’Ivoire (SG-CI)",
  },
  {
    label: "Société Ivoirienne de Banque (SIB)",
    value: "Société Ivoirienne de Banque (SIB)",
  },
  { label: "Stanbic Côte d’Ivoire", value: "Stanbic Côte d’Ivoire" },
  {
    label: "United Bank for Africa Côte d'Ivoire (UBA)",
    value: "United Bank for Africa Côte d'Ivoire (UBA)",
  },
  { label: "VERSUS BANK S.A.", value: "VERSUS BANK S.A." },
  { label: "Autres", value: "Autres" },
];
