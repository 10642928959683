import { Api } from './Api';

const  ENPOINTS = {
    File: 'upload-file',
};

const upload = (payload, signal) => {
    return Api.postFormData(ENPOINTS.File, payload, signal)
}

export const FileService = {
    upload
}