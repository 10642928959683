import { Api } from './Api';

const  ENPOINTS = {
    Article: 'user/articles',
};

const getAll = signal => {
    return Api.get(ENPOINTS.Article, signal)
}

export const ArticleService = {
    getAll
}