import { useState } from "react";
import { Services } from "../../services";
import * as Icons from 'react-feather';

export function ObservationForm(props) {
    const abortController = new AbortController();

    const [content, setContent] = useState('');
    const [file, setFile] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    const handleObservationSubmit = async e => {
        e.preventDefault();
        setIsDisabled(true);

        try {
            let file_url = null;
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
    
                const response = await Services.FileService.upload(
                    formData, abortController.signal);
                file_url = response.file_url;
            }
    
            const payload = {
                demande_id: props.useDemande.id,
                type_document_id: props.type_document.id,
                document_url: file_url ?? "",
                content
            }
    
            await Services.ObservationService.create(
                JSON.stringify(payload), abortController.signal);

            setIsDisabled(false);
            setIsComplete(true);
        } catch (error) {
            setIsDisabled(false);
            if ('messages' in error) return;
                //Toaster
        }
    }
    return (
        <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        intro-y mb-4">
            <h4 className="col-span-12 text-lg leading-none">
                {props.type_document.libelle}
            </h4>
            {isComplete ? 
             <div className="rounded-md flex items-center px-5 py-4 mb-2 border 
             border-theme-9 text-theme-9 dark:border-theme-9 col-span-12"> 
                <Icons.AlertTriangle className="w-6 h-6 mr-2"/> 
                Votre obervation a bien été envoyée 
                <Icons.X className="w-4 h-4 ml-auto" onClick={e => setIsComplete(false)}/>
                </div>
            : null} 
            {!isComplete ? 
                <>
                    <div className="col-span-12 sm:col-span-6">
                        <div className="mb-2">Joindre un fichier</div>
                        <input type="file" className="input w-full border flex-1"
                        disabled={isDisabled} onChange={e => setFile(e.target.files[0])}/>
                    </div>              
                    <div className="col-span-12 sm:col-span-6">
                        <div className="mb-2">Observation</div>
                        <input type="text" className="input w-full border flex-1" 
                        disabled={isDisabled} placeholder="Observation" 
                        onChange={e => setContent(e.target.value)}/>
                    </div> 
                    <div className="col-span-12 flex justify-end">
                        <button className="button justify-center block bg-theme-1 
                        text-white ml-2" onClick={handleObservationSubmit} disabled={isDisabled}>
                                {isDisabled ? "Chargement..." : "Envoyer"}
                        </button>             
                    </div>
                </>
            : null}
        </div>
    )
}