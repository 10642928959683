import { useState } from "react";
import { Utils } from "../../../utils";
import Alert from "react-bootstrap/Alert";
import * as Icons from "react-feather";

export function PresidentCommision(props) {
  // console.log("props", props);

  const formContainer = "associes-form-container";
  // const nombreAssocies =
  //   props.nombreAssocies && Number.isInteger(parseInt(props.nombreAssocies))
  //     ? parseInt(props.nombreAssocies)
  //     : 0;
  // const nombreAssocies = JSON.parse(props.associes) ?? "[]";

  const [associes, setAssocies] = useState({});
  const [typePresident, setTypePresident] = useState("");
  // const [isMeme, setIsMeme] = useState(100);

  var test = Utils.Auth.getUser();
  const handleTypeAssocieChange = (value) => {
    setTypePresident(value);
  };
  const handleInputChange = (name, value) => {
    let associesCopy = { ...associes };

    if (name === "associe-gerant" && props.demande.includes("SCOOP")) {
      associesCopy = { "associe-gerant": "oui", ...JSON.parse(value) };
    } else {
      associesCopy[name] = value;
    }

    associesCopy[name] = value;

    setAssocies(associesCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();

    const associesCopy = { ...associes };

    if (typePresident === "moi-meme") {
      var ligne = test["username"].indexOf(" "); // Gets the first index where a space occours

      associesCopy["nom"] = test["username"].substr(0, ligne); // Gets the first part
      associesCopy["prenoms"] = test["username"].substr(ligne + 1);
      associesCopy["numero-telephone"] = test["numero-telephone"];
    }

    associesCopy["type-president"] = typePresident;
    props.handleNextClick(
      e,
      "president-commission",
      JSON.stringify(associesCopy)
    );
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12  ">
        <strong>
          Qui est le président de la commission de surveillance de la SCOOPS ?
        </strong>
      </div>
      <div className="intro-y col-span-12  ">
        <Alert variant="danger">
          <Alert.Heading>
            {" "}
            <Icons.Info className="w-4 h-4 " />
          </Alert.Heading>
          Renseignez les informations personnelles du Président de la commission de surveillance de la SCOOPS . Si vous n’avez pas certaines informations, vous pourrez
          compléter plus tard
        </Alert>
      </div>

      <div className="intro-y col-span-12">
        <div className="intro-y col-span-12" id={`${formContainer}`}>
          <div className="grid grid-cols-12 mx-auto max-w-md  items-center mt-4 mb-2">
            <div
              className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in "
              id="main-select"
            >
              <input
                type="radio"
                className="overlay opacity-0"
                id={`horizontal-radio-moi-meme-${0}`}
                name={`associe-${0}`}
                value="moi-meme"
                onChange={(e) => handleTypeAssocieChange("moi-meme")}
              />

              <div className="ml-2 mr-auto">
                <div className="font-medium">Moi même</div>
              </div>

              <div className="radio-circle">
                <div className="radio-circle__outer-circle">
                  <div className="radio-circle__inner-circle"></div>
                </div>
              </div>
            </div>

            <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
              <input
                type="radio"
                className="overlay opacity-0"
                id={`horizontal-radio-particulier-${0}`}
                name={`associe-${0}`}
                value="particulier"
                onChange={(e) => handleTypeAssocieChange("particulier")}
              />
              <div className="radio-circle">
                <div className="radio-circle__outer-circle">
                  <div className="radio-circle__inner-circle"></div>
                </div>
              </div>

              <div className="ml-2 mr-auto">
                <div className="font-medium">Un particulier</div>
              </div>
            </div>
          </div>
          <div
            className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12
               ${           
                 typePresident !== undefined &&
                 (typePresident === "moi-meme" ||
                   typePresident === "particulier")
                   ? ""
                   : "hidden"
               }`}
          >
            {typePresident === "particulier" ? (
              <>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <div className="mb-2">Nom</div>
                  <input
                    type="text"
                    className="input w-full border flex-1 field"
                    placeholder="Nom"
                    onChange={(e) => handleInputChange("nom", e.target.value)}
                  />
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <div className="mb-2">Prénom(s)</div>
                  <input
                    type="text"
                    className="input w-full border flex-1 field"
                    placeholder="Prénom(s)"
                    onChange={(e) =>
                      handleInputChange("prenoms", e.target.value)
                    }
                  />
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <div className="mb-2">Numéro de téléphone du président </div>
                  <input
                    type="text"
                    className="input w-full border flex-1 field"
                    placeholder="Numéro de téléphone du président"
                    onChange={(e) =>
                      handleInputChange("numero-telephone", e.target.value)
                    }
                  />
                </div>
              </>
            ) : null}
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Nationalité du président</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Nationalité du président"
                onChange={(e) =>
                  handleInputChange("nationalite", e.target.value)
                }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Nombre de part detenue</div>
              <input
                type="number"
                min={0}
                className="input w-full border flex-1 field"
                placeholder="Nombre de part detenue"
                onChange={(e) =>
                  handleInputChange("nombre-de-part-detenue", e.target.value)
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
