import { Utils } from "../utils";

export function InfoItem(props) {
  // console.log("InfoItem", props);
  const { stringifyBool, parseArray } = Utils.String;
  return (
    <div className="relative sm:flex block items-center py-1">
      <div className="mr-auto">
        <div className="font-medium">
          {(props.demande === "commerciale" || props.demande === "civile") &&
          props.attribute === "Le président a t'il associé(s) ?"
            ? "L'administrateur a t'il associé(s) ?"
            : props.attribute === "Le président fait il partie des associes ?"
            ? "L'administrateur fait il partie des associes ?"
            : props.attribute}
          :
        </div>
      </div>
      <div className="text-gray-600 mr-5 sm:mr-5">
        {props.attribute === "Dénomination"
          ? stringifyBool(props.value ?? "")
          : parseArray(stringifyBool(props.value ?? ""))}
      </div>
    </div>
  );
}
