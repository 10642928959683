import * as Icons from 'react-feather';
import { Components } from '../components';
import { useCallback, useEffect, useState } from 'react';

export function DocumentListView(props) {

    const tableActions = [];
    const tableAttributes = {
        "id": {tdClassName: "w-40"},
        "nom": {thClassName:"text-center", tdClassName:"text-center"}
    };

    const [documents, setDocument] = useState([]);

    const handleReadClick = e => null;
    const handleEditClick = e => null;
    const handleDeleteClick = e => null;
    const init = useCallback(async () => {

        try {
            // const {documents} = Services.UserService.getDemandes(abortController.signal)
            setDocument([{
                id: "#3at0",
                nom: "Creation SA \"TAMPICO SA\""                             
            }]);
        }catch(error) {
            if ('messages' in error)
                return
                //Toat
        }

    }, [])

    const downloadIcon = <Icons.Download size={20} />

    useEffect(() => {
        init();
    }, [init])

    return (
        <div className="col-span-12 mt-8">
            <h2 className="intro-y text-lg font-medium">
                Liste de vos documents
            </h2>
            <div className="grid grid-cols-12 gap-6 mt-5">
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap 
                items-center mt-2">
                    <button className="button text-white bg-theme-1 shadow-md mr-2">
                        Ajouter un document
                    </button>
                    <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                        <div className="w-56 relative text-gray-700 dark:text-gray-300">
                            <input type="text" className="input w-56 box pr-10 placeholder-theme-13"
                            placeholder="Rechercher..." />
                            <Icons.Search className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
                        </div>
                    </div>
                </div>
                <div className="intro-y lg:col-span-8 col-span-12 overflow-auto lg:overflow-visible">
                    <Components.Table tableAttributes={tableAttributes}
                    tableData={documents} tableActions={tableActions}
                    controllers={{handleEditClick, handleReadClick, handleDeleteClick}} />
                </div>
                <div className="col-span-12 lg:col-span-4">
                    <div className="intro-y box">
                        <div className="p-5" id="basic-accordion">
                            <div className="preview">
                                <div className="accordion">
                                    <div className="accordion__pane active border-b border-gray-200 
                                    dark:border-dark-5 pb-4">
                                        <a href="/" className="accordion__pane__toggle font-medium block">
                                            Facture
                                        </a> 
                                        <div className="accordion__pane__content mt-3 text-gray-700 
                                        dark:text-gray-600 leading-relaxed">
                                            <a href="/" download={"file.txt"} className='flex justify-between items-center'>
                                                <span>Facture</span> {downloadIcon}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="accordion__pane active border-b border-gray-200 
                                    dark:border-dark-5 pb-4">
                                        <a href="/" className="accordion__pane__toggle font-medium block">
                                            Status
                                        </a> 
                                        <div className="accordion__pane__content mt-3 text-gray-700 
                                        dark:text-gray-600 leading-relaxed">
                                        <a href="/" download={"file.txt"} className='flex justify-between items-center'>
                                                <span>Status 1</span> {downloadIcon}
                                            </a>
                                        </div>
                                        <div className="accordion__pane__content mt-3 text-gray-700 
                                        dark:text-gray-600 leading-relaxed">
                                            <a href="/" download={"file.txt"} className='flex justify-between items-center'>
                                                <span>Status 1</span> {downloadIcon}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="accordion__pane active border-b border-gray-200 
                                    dark:border-dark-5 pb-4">
                                        <a href="/" className="accordion__pane__toggle font-medium block">
                                            Domiciliation
                                        </a> 
                                        <div className="accordion__pane__content mt-3 text-gray-700 
                                        dark:text-gray-600 leading-relaxed">
                                            <a href="/" download={"file.txt"} className='flex justify-between items-center'>
                                                <span>Domiciliation</span> {downloadIcon}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}