import { useState } from "react";
import { Services } from "../services";

export const useDemande = () => {
  const [id, setId] = useState("");
  const [dossier_id, setDossier_id] = useState("");
  const [type_demande_id, setType_demande_id] = useState("");
  const [etape_traitement_id, setEtape_traitement_id] = useState("");
  const [pays_id, setPays_id] = useState("");
  const [etat_demande_id, setEtat_demande_id] = useState("");
  const [numero_demande, setNumero_demande] = useState("");
  const [montant_total, setMontant_total] = useState("");
  const [montant_paye, setMontant_paye] = useState("");
  const [champs_demande, setChamps_demande] = useState("");
  const [champs_questionnaire, setChamps_questionnaire] = useState("");
  const [champs_etape_traitements, setChamps_etape_traiements] = useState("");
  const [created_at, setCreated_at] = useState("");

  const [type_demande, setType_demande] = useState(null);
  const [etape_traitement, setEtape_traitement] = useState(null);
  const [etat_demande, setEtat_demande] = useState(null);
  const [dossier, setDossier] = useState(null);
  const [pays, setPays] = useState(null);

  const [errors, setErrors] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const getDemande = async (demandeId, signal) => {
    const response = await Services.DemandeService.getById(demandeId, signal);
    fillDemande(response.demande);
    setIsDisabled(false);
    // console.log("response", response);
    return response;
  };


  const createDemande = (signal) => {
    const payload = {
      dossier_id,
      type_demande_id,
      etape_traitement_id,
      pays_id,
      etat_demande_id,
      numero_demande,
      champs_demande,
      montant_total,
    };
    // console.log("payload", payload);

    return Services.DemandeService.create(JSON.stringify(payload), signal);
  };
  const updateDemande = (demandeId, signal) => {
    const payload = {
      dossier_id,
      type_demande_id,
      etape_traitement_id,
      pays_id,
      etat_demande_id,
      numero_demande,
      champs_demande,
    };

    return Services.DemandeService.update(
      demandeId,
      JSON.stringify(payload),
      signal
    );
  };
  const deleteDemande = (demandeId, signal) => {
    return Services.DemandeService.destroy(demandeId, signal);
  };
  const fillDemande = (demande) => {
    setId(demande.id);
    setDossier_id(demande.dossier_id ?? "");
    setType_demande_id(demande.type_demande_id ?? "");
    setEtape_traitement_id(demande.etape_traitement_id ?? "");
    setPays_id(demande.pays_id ?? "");
    setEtat_demande_id(demande.etat_demande_id ?? "");
    setNumero_demande(demande.numero_demande ?? "");
    setMontant_total(demande.montant_total ?? "");
    setMontant_paye(demande.montant_paye ?? "");
    setChamps_demande(demande.champs_demande ?? "");
    setChamps_questionnaire(demande.champs_questionnaire ?? "");
    setChamps_etape_traiements(demande.champs_etape_traitements ?? "");
    setCreated_at(demande.created_at ?? "");

    setType_demande(demande.type_demande ?? null);
    setEtape_traitement(demande.etape_traitement ?? null);
    setEtat_demande(demande.etat_demande ?? null);
    setDossier(demande.dossier ?? null);
    setPays(demande.pays ?? null);
  };
  const emptyDemande = () => {
    setId("");
    setDossier_id("");
    setType_demande_id("");
    setEtape_traitement_id("");
    setPays_id("");
    setEtat_demande_id("");
    setNumero_demande("");
    setMontant_total("");
    setMontant_paye("");
    setChamps_demande("");
    setChamps_questionnaire("");
    setChamps_etape_traiements("");
    setCreated_at("");

    setType_demande(null);
    setEtape_traitement(null);
    setEtat_demande(null);
    setDossier(null);
    setPays(null);
  };

  return {
    id,
    dossier_id,
    type_demande_id,
    etape_traitement_id,
    pays_id,
    etat_demande_id,
    numero_demande,
    montant_total,
    montant_paye,
    champs_demande,
    champs_questionnaire,
    champs_etape_traitements,
    created_at,

    type_demande,
    etape_traitement,
    etat_demande,
    dossier,
    pays,

    errors,
    isDisabled,
    setDossier_id,
    setType_demande_id,
    setEtape_traitement_id,
    setPays_id,
    setEtat_demande_id,
    setNumero_demande,
    setChamps_demande,
    setMontant_paye,
    setMontant_total,
    setChamps_questionnaire,
    setChamps_etape_traiements,

    setId,
    setErrors,
    setIsDisabled,
    getDemande,
    createDemande,
    updateDemande,
    deleteDemande,
    fillDemande,
    emptyDemande,
  };
};
