import { useCategorieUser } from './useCategorieUser';
import { useUser } from './useUser';
import { useRole } from './useRole';
import { usePermission } from './usePermission';
import { useDossier } from './useDossier';
import { usePays } from './usePays';
import { useEtatDemande } from './useEtatDemande';
import { useEtapeTraitement } from './useEtapeTraitement';
import { useTypePaiement } from './useTypePaiement';
import { useMoyenPaiement } from './useMoyenPaiement';
import { useDemande } from './useDemande';
import { useCodePromo } from './useCodePromo';
import { usePaiement } from './usePaiement';
import { useTypeDocument } from './useTypeDocument';
import { useEtatDocument } from './useEtatDocument';
import { useDocument } from './useDocument';
import { useTypePiece } from './useTypePiece';
import { usePiece } from './usePiece';
import { useTypeDemande } from './useTypeDemande';
import { useObservation } from './useObservation';

export const Hooks = {
    useCategorieUser,
	useUser,
	useRole,
	usePermission,
	useDossier,
	usePays,
	useEtatDemande,
	useEtapeTraitement,
	useTypePaiement,
	useMoyenPaiement,
	useDemande,
	useCodePromo,
	usePaiement,
	useTypeDocument,
	useEtatDocument,
	useDocument,
	useTypePiece,
	usePiece,
	useTypeDemande,
	useObservation
	
}