import { useState, useEffect } from "react";
import { Components } from "../../../..";
import { Utils } from "../../../../../utils";

export function RecapStepStatutaire(props) {
  console.log("props", props);
  const { isJsonParseable, parseJson } = Utils.String;
  // const { getCurrencyFromOffre, getAssocieName } = Utils.Demande;

  const [isDisabled, setIsDisabled] = useState(false);
  const [nouveauDirigeant, setNouveauDirigeant] = useState({});

  const {
    denomination,
    "localisation-siege": localisationSiege,
    "nouveau-siege": nouveauLocal,
    // offre,
  } = props.champsDemandeObject;
  const capital = props.champsDemandeObject["capital-social"]
    ?.toString()
    .replace(" ", "");

  const {
    "nouvelle-forme-juridique": nouvelleFormeDeande,
    "ville-immatriculation": villeImm,
    "numero-rccm": numeroRcc,
    associes: associess,
    "nouvelle-activite": nouvelActivite,
    // "nouvelle-capital": nouvelCapital,
    "nouveau-nom-entreprise": nouveaunomEntreprise,
  } = JSON.parse(props.dataGlobal.useDemande.champs_demande);

  const {
    associes,
    // activites,
    gerants,
    "parts-des-associe": partAssocie,
  } = props.questionnaireObject;

  const associesArray = isJsonParseable(associes) ? parseJson(associes) : [];
  const gerantsAssemblee = isJsonParseable(gerants) ? parseJson(gerants) : [];

  const infosGenerales = {
    denomination: denomination.toString(),
    "Forme juridique": nouvelleFormeDeande,
    "Numéro RCCM": numeroRcc,
    "Date de création": partAssocie
      ? JSON.parse(partAssocie)["date-creation"]
      : "",
    "Capital social": capital,
    "Valeur nominale d'une part": partAssocie
      ? (JSON.parse(partAssocie)["valeur-nominale"] += " CFA")
      : "",
    Adresse: localisationSiege,
    "Ville immatriculation": villeImm,
  };

  const denominationEntreprise = {
    "Nouvelle denomination": nouveaunomEntreprise
      ? JSON.parse(nouveaunomEntreprise)
      : "",
  };
  const infoPresidentAssemblee = {
    "civilite-president-assemble":
      gerantsAssemblee["civilite-president-assemble"],
    "nom-president-assemble": gerantsAssemblee["nom-president-assemble"],
    "prenom-president-assemble": gerantsAssemblee["prenom-president-assemble"],
  };

  const infosAssemble = {
    "Civilite du dirigeant": gerantsAssemblee["civilite-president-assemble"],
    "Nom et prénoms du dirigeant": `${gerantsAssemblee["nom-dirigeant-societe"]} ${gerantsAssemblee["prenoms-dirigeant-societe"]}`,
    "Numero de téléphone": gerantsAssemblee["numero-telephone"],
    "Pays de naissance": gerantsAssemblee["pays-naissance-dirigeant-societe"],
    "Vile de naissance": gerantsAssemblee["vile-naissance-dirigeant-societe"],
    "Nationalite du dirigeant":
      gerantsAssemblee["nationalite-dirigeant-societe"],
    "Adresse du dirigeant": gerantsAssemblee["adresse-dirigeant-societe"],
    "Adresse mail du dirigeant":
      gerantsAssemblee["adresse-mail-dirigeant-societe"],
    "Date de naissance du dirigeant":
      gerantsAssemblee["date-naissance-dirigeant-societe"],
    "Date d'établissement de la pièce":
      gerantsAssemblee["date-piece-identite-dirigeant-societe"],
    "Date de fin de validite de la pièce d'identite":
      gerantsAssemblee["date-fin-validite-piece-identite"],
    "Nom du père du dirigeant": gerantsAssemblee["nom-pere-dirigeant-societe"],
    "Numero de pièce d'identité":
      gerantsAssemblee["numero-piece-dirigeant-societe"],
  };

  useEffect(() => {
    if (partAssocie && JSON.parse(partAssocie)) {
      setNouveauDirigeant({
        "civilite-nouveau-gerant":
          JSON.parse(partAssocie)["civilite-nouveau-gerant"],
        "nom-nouveau-gerant": JSON.parse(partAssocie)["nom-nouveau-gerant"],
        "prenoms-nouveau-gerant":
          JSON.parse(partAssocie)["prenoms-nouveau-gerant"],
        "adresse-mail": JSON.parse(partAssocie)["adresse-mail"],
        "adresse-nouveau-gerant":
          JSON.parse(partAssocie)["adresse-nouveau-gerant"],
        "numero-telephone": JSON.parse(partAssocie)["numero-telephone"],
        "nationalite-nouveau-gerant":
          JSON.parse(partAssocie)["nationalite-nouveau-gerant"],
        "lieu-naissance-nouveau-gerant":
          JSON.parse(partAssocie)["lieu-naissance-nouveau-gerant"],
        "numero-piece-identite":
          JSON.parse(partAssocie)["numero-piece-identite"],
        "date-piece-identite": JSON.parse(partAssocie)["date-piece-identite"],
        "date-fin-validite-piece-identite":
          JSON.parse(partAssocie)["date-fin-validite-piece-identite"],
        "nom-pere": JSON.parse(partAssocie)["nom-pere"],
        "nom-mere": JSON.parse(partAssocie)["nom-mere"],
      });
    }
  }, [partAssocie]);

  const handleEditClick = (e, name, index = "") => {
    e.preventDefault();
    // setIsEditModalOpen(true);
    // setValueName(name);
    // setValueArrayIndex(index);
  };

  const handleStepSubmit = (e) => {
    setIsDisabled(true);
    e.preventDefault();
    props.handleSubmit(e, "", "");
  };

  return (
    <div
      className={`grid grid-cols-1 col-span-12 
        ${props.step !== props.index ? "hidden" : ""} `}
    >
      <div className="flex-content-column col-span-12">
        <Components.RecaptItem
          title={"Informations relatives à l'entreprise"}
          name={"infos"}
          value={infosGenerales}
          hasEditIcon2={true}
          handleEditClick={handleEditClick}
        />
        <Components.RecaptItem
          title={"Informations relatives aux Associés"}
          name={"associes"}
          value={associesArray}
          hasEditIcon={true}
          handleEditClick={handleEditClick}
        />
        <h2 className="font-medium text-2xl text-theme-1 mt-8 border-b ">
          Informations relatives à l'assemblé generale
        </h2>
        <Components.RecaptItem
          title={"Président de l'assemblé général"}
          name={"infos"}
          value={infoPresidentAssemblee}
          hasEditIcon2={true}
          handleEditClick={handleEditClick}
        />
        <Components.RecaptItem
          title={"Dirigeant"}
          name={"infos"}
          value={infosAssemble}
          hasEditIcon2={true}
          handleEditClick={handleEditClick}
        />
        <h2 className="font-medium text-2xl text-theme-1 mt-8 border-b ">
          INFORMATIONS RELATIVES AUX MODIFICATIONS À APPORTER
        </h2>{" "}
        {partAssocie && JSON.parse(partAssocie)["status-gerant"] ? (
          <Components.RecaptItem
            title={"Nomination du nouveau dirigeant"}
            name={"infos"}
            value={nouveauDirigeant}
            hasEditIcon2={true}
            handleEditClick={handleEditClick}
          />
        ) : null}
        {nouveaunomEntreprise ? (
          <Components.RecaptItem
            title={"Modification liée à la denomination "}
            name={"infos"}
            value={denominationEntreprise}
            hasEditIcon2={true}
            handleEditClick={handleEditClick}
          />
        ) : null}
        {nouveauLocal["nouveau-local"] ? (
          <Components.RecaptItem
            title={"Modification liée à l'adresse du siège "}
            name={"infos"}
            value={{ "Nouvelle adresses": nouveauLocal["nouveau-local"] }}
            hasEditIcon2={true}
            handleEditClick={handleEditClick}
          />
        ) : null}
        {nouvelActivite ? (
          <Components.RecaptItem
            title={"Modification liée à l'activité"}
            name={"infos"}
            value={JSON.parse(nouvelActivite)}
            hasEditIcon2={true}
            handleEditClick={handleEditClick}
          />
        ) : null}
        <h2 className="font-medium text-2xl text-theme-1 mt-8 border-b ">
          Modification liée à la cession des parts
        </h2>
        <div className="flex-content-column">
          <div className="flex-content-item">
            {associess &&
              JSON.parse(associess).map((data, index) => {
                return (
                  <div key={index} className="flex-content-item-body-fex">
                    <div className="flex-content-item-body-wrapper">
                      <div className="item-body-wrapper-header">
                        <h6 className={"item-body-wrapper-header-title"}>
                          Vendeur : {index + 1}
                        </h6>
                      </div>
                      <div className="flex-content-item-body-wrapper-mapper gap-4">
                        <div className="col-md-3 col-sm-3 col-xs-12">
                          <span className="flex-item-text text-lg">
                            Nom et prénom :
                          </span>
                          {data["nom-prenom-vendeur"]}
                        </div>

                        <div className="col-md-3 col-sm-3 col-xs-12">
                          <span className="flex-item-text text-lg">
                            Pourcentage parts (%):{" "}
                          </span>
                          {data["pourcentage-parts"]}
                        </div>

                        <div className="col-md-3 col-sm-3 col-xs-12">
                          <span className="flex-item-text text-lg">
                            Part(s) d'action(s):{" "}
                          </span>
                          {data["actionnaire-parts"]}
                        </div>

                        <div className="col-md-3 col-sm-3 col-xs-12">
                          <span className="flex-item-text text-lg">
                            Valeurs :
                          </span>{" "}
                          {data["montant-part-vendu"]} XOF
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="flex-content-column">
          <div className="flex-content-item">
            {partAssocie && JSON.parse(partAssocie).AssociesParts ? (
              <>
                {JSON.parse(partAssocie).AssociesParts.map((item, index) => {
                  return (
                    <div key={index} className="flex-content-item-body-fex">
                      {item["associe-part"] === "oui" ? (
                        <div className="flex-content-item-body-wrapper">
                          <div className="item-body-wrapper-header">
                            <h6 className={"item-body-wrapper-header-title"}>
                              Acheteur :{index + 1}
                            </h6>
                          </div>
                          <div className="flex-content-item-body-wrapper-mapper gap-4">
                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                L'associé :
                              </span>
                              {item.value}
                            </div>

                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                Pourcentage de part acheté:
                              </span>
                              {item["pourcentage-parts"]}
                            </div>

                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                Vendeur de part:
                              </span>
                              {item["vendeur"]}
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {item["associe-part"] === "non" ? (
                        item.type === "particulier" ? (
                          <div className="flex-content-item-body-wrapper">
                            <div className="item-body-wrapper-header">
                              <h6 className={"item-body-wrapper-header-title"}>
                                Acheteur :{index + 1}
                              </h6>
                            </div>
                            <div className="flex-content-item-body-wrapper-mapper gap-4">
                              <div className="col-md-3 col-sm-3 col-xs-12">
                                <span className="flex-item-text text-lg">
                                  Type acheteur :
                                </span>
                                {item.type}
                              </div>
                              <div className="col-md-3 col-sm-3 col-xs-12">
                                <span className="flex-item-text text-lg">
                                  Nom :
                                </span>
                                {item.nom}
                              </div>

                              <div className="col-md-3 col-sm-3 col-xs-12">
                                <span className="flex-item-text text-lg">
                                  Prénom(s) :
                                </span>
                                {item.prenoms}
                              </div>

                              <div className="col-md-3 col-sm-3 col-xs-12">
                                <span className="flex-item-text text-lg">
                                  Vendeur de part :
                                </span>
                                {item.vendeur}
                              </div>
                              <div className="col-md-3 col-sm-3 col-xs-12">
                                <span className="flex-item-text text-lg">
                                  Pourcentage de part acheté :
                                </span>
                                {item["pourcentage-parts"]}
                              </div>
                            </div>
                          </div>
                        ) : item.type === "societe" ? (
                          <div className="flex-content-item-body-wrapper ">
                            <div className="item-body-wrapper-header">
                              <h6 className={"item-body-wrapper-header-title"}>
                                Acheteur :{index + 1}
                              </h6>
                            </div>
                            <div className="flex-content-item-body-wrapper-mapper gap-4">
                              <div className="col-md-3 col-sm-3 col-xs-12">
                                <span className="flex-item-text text-lg">
                                  Type acheteur :
                                </span>
                                {item.type}
                              </div>
                              <div className="col-md-3 col-sm-3 col-xs-12">
                                <span className="flex-item-text text-lg">
                                  Dénomination :
                                </span>
                                {item["denomination-sociale"]}
                              </div>

                              <div className="col-md-3 col-sm-3 col-xs-12">
                                <span className="flex-item-text text-lg">
                                  Forme sociale :
                                </span>
                                {item["forme-sociale"]}
                              </div>
                              <div className="col-md-3 col-sm-3 col-xs-12">
                                <span className="flex-item-text text-lg">
                                  Vendeur de part :
                                </span>
                                {item.vendeur}
                              </div>
                              <div className="col-md-3 col-sm-3 col-xs-12">
                                <span className="flex-item-text text-lg">
                                  Pourcentage de part acheté :
                                </span>
                                {item["pourcentage-parts"]}
                              </div>
                            </div>
                          </div>
                        ) : null
                      ) : null}
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
        </div>
        {partAssocie && JSON.parse(partAssocie).appoteur ? (
          <>
            <h2 className="font-medium text-2xl text-theme-1 mt-8 border-b ">
              Modification liée à l'augmentation du capital
            </h2>
            <div className="flex-content-column">
              <div className="flex-content-item">
                {JSON.parse(partAssocie).appoteur.map((item, index) => {
                  // const idx = index + 1;
                  return (
                    <div key={index} className="flex-content-item-body-fex ">
                      {item["associe-part"] === "oui" ? (
                        <div className="flex-content-item-body-wrapper">
                          <div className="item-body-wrapper-header">
                            <h6 className={"item-body-wrapper-header-title"}>
                              Apporteur :{index + 1}
                            </h6>
                          </div>
                          <div className="flex-content-item-body-wrapper-mapper gap-8">
                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                Nom et prénom du porteur :
                              </span>
                              {item.value
                                ? item.value["nom-prenom-vendeur"]
                                : ""}
                            </div>

                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                Montant :
                              </span>
                              {item.value
                                ? item.value["montant-part-vendu"]
                                : ""}{" "}
                              XOF
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {item.type === "particulier" ? (
                        <div className="flex-content-item-body-wrapper">
                          <div className="item-body-wrapper-header">
                            <h6 className={"item-body-wrapper-header-title"}>
                              Apporteur :{index + 1}
                            </h6>
                          </div>
                          <div className="flex-content-item-body-wrapper-mapper gap-8">
                            {/* <div className="col-md-3 col-sm-3 col-xs-12">
                                    <span className="flex-item-text text-lg">
                                      Civilité :
                                    </span>
                                    {item["civilite-apporteur"]}
                                  </div> */}
                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                Nom :
                              </span>
                              {item["nom-apporteur"]}
                            </div>

                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                Prénom(s) :
                              </span>
                              {item["prenoms-apporteur"]}
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                Apport :
                              </span>
                              {item["apport-nouvel-associe"]} XOF
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                Type Apporteur :
                              </span>
                              {item.type}
                            </div>
                          </div>
                        </div>
                      ) : item.type === "societe" ? (
                        <div className="flex-content-item-body-wrapper">
                          <div className="item-body-wrapper-header">
                            <h6 className={"item-body-wrapper-header-title"}>
                              Apporteur :{index + 1}
                            </h6>
                          </div>
                          <div className="flex-content-item-body-wrapper-mapper gap-8">
                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg ">
                                Dénomination :
                              </span>
                              {item["denomination-sociale"]}
                            </div>

                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                Forme sociale :
                              </span>
                              {item["forme-sociale"]}
                            </div>
                            {/* <div className="col-md-3 col-sm-3 col-xs-12">
                                    <span className="flex-item-text text-lg">
                                      Civilité :
                                    </span>
                                    {item.civilite}
                                  </div> */}
                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                Nom :
                              </span>
                              {item.nom}
                            </div>

                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                Prénom (s):
                              </span>
                              {item.prenoms}
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                Apport :
                              </span>
                              {item["apport-nouvel-associe"]} XOF
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-12">
                              <span className="flex-item-text text-lg">
                                Type Apporteur :
                              </span>
                              {item.type}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : null}
        <div
          className="intro-y col-span-12 flex items-center justify-center
            sm:justify-end mt-10"
        >
          <button
            className="button w-24 justify-center block bg-gray-200 
                text-gray-600 dark:bg-dark-1 dark:text-gray-300"
            onClick={props.handleQuestionnaireBackClick ?? null}
          >
            Revenir
          </button>
          <button
            className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
            onClick={handleStepSubmit}
            disabled={isDisabled}
          >
            {isDisabled ? " Chargement en cours..." : "Suivant"}
          </button>
        </div>
      </div>
    </div>
  );
}
