import { Fragment, useEffect, useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import * as Icons from "react-feather";

export function Table(props) {
  const ACTIONS = {
    EDIT: "edit",
    READ: "read",
    DELETE: "delete",
    DOWNLOAD: "download",
  };
  const actionsDropdown = [
    "Voir les paiements",
    "Voir les details",
    "Suivre le traitement",
  ];
  const { tableAttributes, tableData, tableActions, controllers } = props;
  const {
    handleEditClick,
    handleReadClick,
    handleDeleteClick,
    handleVoirSoleClick,
    handleVoirDetailClick,
  } = controllers;

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // console.log('props',props);
  const renderEditButton = (data) => (
    <button
      className="flex items-center mr-3"
      onClick={(e) => handleEditClick(e, data)}
      key={1}
    >
      <Icons.Edit className="w-4 h-4 mr-1" /> Modifier
    </button>
  );

  const renderReadButton = (data) => (
    <Fragment key={Math.random()}>
      {data.canShow ? (
        <>
          <button
            className="sm:hidden button flex items-center bg-theme-1 
                text-white px-4"
            onClick={(e) => handleReadClick(e, data)}
            key={2}
          >
            <Icons.Eye className="h-4" /> Voir
          </button>

          <OverlayTrigger
            trigger="click"
            placement="bottom-end"
            overlay={
              <Popover
                id="popover-basic"
                style={{ zIndex: "9999999", color: "white" }}
              >
                {/* <Popover.Header as="h3">
                          Popover right
                        </Popover.Header> */}
                <Popover.Body>
                  {actionsDropdown.map((item, index) => (
                    <div key={index} className="p-1">
                      {item === "Suivre le traitement" ? (
                        <button
                          className="button flex items-center block p-2 transition duration-300 
                              ease-in-out bg-theme-1 hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                          style={{ width: "100%" }}
                          onClick={(e) => handleReadClick(e, data)}
                        >
                          {item}
                        </button>
                      ) : null}
                      {item === "Voir les details" ? (
                        <button
                          className="button flex items-center block p-2 transition duration-300 
                              ease-in-out bg-theme-1 hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                          style={{ width: "100%" }}
                          onClick={(e) => handleVoirDetailClick(e, data)}
                        >
                          {item}
                        </button>
                      ) : null}
                      {item === "Voir les paiements" ? (
                        <button
                          className="button flex items-center block p-2 transition duration-300 
                              ease-in-out bg-theme-1 hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                          style={{ width: "100%" }}
                          onClick={(e) => handleVoirSoleClick(e, data)}
                        >
                          {item}
                        </button>
                      ) : null}
                    </div>
                  ))}
                </Popover.Body>
              </Popover>
            }
          >
            <Button
              // onClick={() => {
              //   handleActions(item);
              // }}
              className="p-2 border-2 rounded-lg md:hidden lg:hidden"
            >
              Actions
            </Button>
          </OverlayTrigger>
        </>
      ) : null}

      {!data.canShow && data.demande.props.children === "Recouvrement" ? (
        <>
          <button
            className=" button flex items-center bg-theme-1 
                text-white px-4"
            onClick={(e) => handleReadClick(e, data)}
            key={2}
          >
            <Icons.Eye className="h-4" /> Voir
          </button>

          <OverlayTrigger
            trigger="click"
            placement="bottom-end"
            overlay={
              <Popover
                id="popover-basic"
                style={{ zIndex: "9999999", color: "white" }}
              >
                {/* <Popover.Header as="h3">
                          Popover right
                        </Popover.Header> */}
                <Popover.Body>
                  {actionsDropdown.map((item, index) => (
                    <div key={index} className="p-1">
                      <button
                        className="button flex items-center block p-2 transition duration-300 
                            ease-in-out bg-theme-1 hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                        style={{ width: "100%" }}
                      >
                        {item}
                      </button>
                    </div>
                  ))}
                </Popover.Body>
              </Popover>
            }
          >
            <Button
              // onClick={() => {
              //   handleActions(item);
              // }}
              className="p-2 border-2 rounded-lg md:hidden lg:hidden"
            >
              Actions
            </Button>
          </OverlayTrigger>
        </>
      ) : null}
    </Fragment>
  );

  const renderDownloadButton = (data) => (
    <a
      className=" button flex items-center bg-theme-1 text-white px-4"
      href={data.url}
      target="_blank"
      rel="noreferrer"
      key={3}
    >
      <Icons.Download className="h-4" /> Télécharger
    </a>
  );

  const renderDeleteButton = (data) => (
    <button
      className="flex items-center text-theme-6"
      onClick={(e) => handleDeleteClick(e, data)}
      key={3}
    >
      <Icons.Trash2 className="w-4 h-4 mr-1" /> Supprimer
    </button>
  );

  const renderTableHeads = () => {
    let keyHeader = tableAttributes;
    if (
      screenSize.width <= 720 &&
      keyHeader !== "paiement" &&
      keyHeader !== "reference"
    ) {
      const rowDatas = { ...keyHeader };

      delete rowDatas.paiement;
      delete rowDatas.reference;
      keyHeader = rowDatas;
    }

    const tableHeads = Object.keys(keyHeader).map((key, index) => {
      let newKey = key;
      if (screenSize.width <= 720 && newKey === "derniere mise a jour")
        return null;
      const regEx = new RegExp("[-_]", "gi");
      return (
        <th
          className={`${keyHeader[newKey].thClassName ?? ""} 
                whitespace-no-wrap`}
          key={index}
        >
          {newKey.replace(regEx, "").toUpperCase()}
        </th>
      );
    });

    if (tableActions.length > 0) {
      tableHeads.push(
        <th
          key={9999}
          className="text-center 
            whitespace-no-wrap"
        >
          ACTIONS
        </th>
      );
    }

    return tableHeads;
  };

  const renderTableCell = (data, attribute, index) => {
    // console.log("tableAttributes[attribute]", attribute, data);
    // console.log('screenSize.width <=720+',);

    return (
      <td
        className={`${tableAttributes[attribute].tdClassName} table--sm` ?? ""}
        key={index}
      >
        {data && data !== NaN ? data : "Non defini"}
      </td>
    );
  };

  const renderTableActionCell = (data, index) => {
    // console.log("renderTableActionCell", data);

    return (
      <td className="table-report__action p-0 w-56" key={999}>
        {tableActions.map((action, index) => {
          switch (action) {
            case ACTIONS.EDIT:
              return renderEditButton(data);
            case ACTIONS.READ:
              return renderReadButton(data);
            case ACTIONS.DELETE:
              return renderDeleteButton(data);
            case ACTIONS.DOWNLOAD:
              return renderDownloadButton(data);
            default:
              return null;
          }
        })}
      </td>
    );
  };

  const renderTableRow = (rowData, index) => {
    // console.log("rowData", Object.keys(rowData));
    let rowDatass = rowData;
    if (screenSize.width <= 720) {
      const rowDatas = { ...rowData };
      delete rowDatas.paiement;
      delete rowDatas.reference;
      delete rowDatas["derniere mise a jour"];
      rowDatass = rowDatas;
      // console.log("rowDatas", rowDatas);
      //   // rowDatas
    }

    const tableCells = Object.keys(rowDatass).map((key, i) => {
      // if (screenSize.width <= 720 && rowData[key] !== "reference") {
      // console.log("rowData[key]", key);
      // }
      if (rowDatass[key] === "DSV") {
      }
      if (key in tableAttributes === false) return null;

      return renderTableCell(rowDatass[key], key, i);
    });
    // console.log("tableCells1", tableCells);

    tableCells.push(renderTableActionCell(rowData));
    // console.log("tableCells2", tableCells);

    return (
      <tr key={index} className="intro-x">
        {tableCells}
      </tr>
    );
  };

  return (
    <table className="table table-report table--sm -mt-2 col-span-12">
      <thead>
        <tr>{renderTableHeads()}</tr>
      </thead>
      <tbody>
        {tableData.map((rowData, index) => renderTableRow(rowData, index))}
      </tbody>
    </table>
  );
}
