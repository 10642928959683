import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";

export function RegisterForm(props) {
  const navigate = useNavigate();

  return (
    <form name="loginForm" id="loginForm" onSubmit={props.handleSubmit}>
      <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
        Inscrivez-vous
      </h2>
      <div className="intro-x mt-2 text-gray-500 dark:text-gray-500 xl:hidden text-center">
        Créez votre compte et accédez à votre espace client, téléchargez vos
        documents et suivez en temps réel l’évolution de votre dossier !
      </div>
      <div className="intro-x mt-8" style={{ maxWidth: "350px" }}>
        <input
          type="text"
          className="intro-x login__input w-full input input--lg border 
                border-gray-300 block"
          placeholder="Nom Prénom"
          name="username"
          id="username"
          value={props.useUser.username}
          onChange={(e) => props.useUser.setUsername(e.target.value)}
          required
        />
        <input
          type="email"
          className="intro-x login__input w-full input input--lg border 
                border-gray-300 block mt-4"
          placeholder="Adresse Mail"
          name="email"
          id="email"
          value={props.useUser.email}
          onChange={(e) => props.useUser.setEmail(e.target.value)}
          required
        />
        <div className="relative flex items-center justify-start mt-4 intro-x">
          {/* <select className="input input--sm border" style={{padding:"12px 30px 12px 10px", 
                    borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: "none"}} 
                    value={props.code} onChange={e => props.setCode(e.target.value)}> 
                        <option hidden>...</option>
                        {props.phoneCodes.map((code, index) => {
                            return (
                                <option value={code} key={index}>{code}</option>
                            )
                        })}
                    </select>
                    <input type="tel" className="intro-x login__input w-full input input--lg border
                    border-gray-300" placeholder="Numéro de téléphone" name="numero_telephone" 
                    id="numero_telephone" value={props.useUser.numero_telephone} style={{minWidth: "auto", 
                    borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} onChange={e => 
                    props.useUser.setNumero_telephone(e.target.value)} required/> */}
          <PhoneInput
            country={"ci"}
            value={props.useUser.numero_telephone}
            onChange={(phone) => props.useUser.setNumero_telephone(phone)}
            autoFormat={false}
            inputStyle={{
              padding: "21px 20px 21px 52px",
              width: "100%",
              boxShadow: "0px 3px 5px #00000007",
              borderColor: "rgba(226, 232, 240, 1)",
            }}
            placeholder="Numéro de téléphone"
          />
        </div>
        <input
          type="password"
          className="intro-x login__input w-full input input--lg border 
                border-gray-300 block mt-4"
          placeholder="Mot de passe"
          name="password"
          id="password"
          value={props.useUser.password}
          onChange={(e) => props.useUser.setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          className="intro-x login__input w-full input input--lg border 
                border-gray-300 block mt-4"
          placeholder="Confirmer le mot de passe"
          name="password_confirmation"
          id="password_confirmation"
          value={props.password_confirmation}
          onChange={(e) => props.setPassword_confirmation(e.target.value)}
          required
        />
      </div>
      <div
        className="intro-x flex items-center text-gray-700 
            dark:text-gray-600 mt-4 text-xs sm:text-sm"
      >
        <input
          type="checkbox"
          className="input border mr-2"
          id="remember-me"
          checked={props.hasAgreed ?? false}
          onChange={(e) => props.setHasAgreed(!props.hasAgreed)}
        />
        <label className="cursor-pointer select-none" htmlFor="remember-me">
          J'accepte les
        </label>
        <Link className="text-theme-1 dark:text-theme-10 ml-1" href="/">
          Conditions d'utilisation
        </Link>
        .
      </div>
      <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
        <button
          className="button button--lg w-full xl:w-32 text-white bg-theme-1 
                xl:mr-3 align-top"
          type="submit"
          onClick={props.handleSubmit}
          disabled={props.useUser.isDisabled}
        >
          {props.useUser.isDisabled ? "Chargement" : "Inscription"}
        </button>
        <button
          className="button button--lg w-full xl:w-40 text-gray-700 border 
                border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
          onClick={() => navigate("/connexion")}
          disabled={props.useUser.isDisabled}
        >
          Connectez-vous
        </button>
      </div>
    </form>
  );
}
