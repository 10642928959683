import { useState } from 'react';
import { Services } from '../services';

export const usePiece = () => {
    const [id, setId] = useState('');
	const [user_id, setUser_id] = useState('');
	const [type_piece_id, setType_piece_id] = useState('');
	const [piece_url, setPiece_url] = useState('');
	

    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getPiece = (pieceId, signal) => {        
        return Services.PieceService.getById(pieceId, signal)
        .then(response => {
            fillPiece(response.piece);
            setIsDisabled(false);
        });
    }

    const createPiece = signal => {
        const payload = {
            user_id,
		type_piece_id,
		piece_url,
		
        };

        return Services.PieceService.create(JSON.stringify(payload), signal);
    }
    const updatePiece = (pieceId, signal) => {
        const payload = {
            user_id,
		type_piece_id,
		piece_url,
		
        };

        return Services.PieceService.update(pieceId, JSON.stringify(payload), signal);
    }
    const deletePiece = (pieceId, signal) => {
        return Services.PieceService.destroy(pieceId, signal);
    }
    const fillPiece = (piece) => {
        setId(piece.id);
        setUser_id(piece.user_id ?? '');
		setType_piece_id(piece.type_piece_id ?? '');
		setPiece_url(piece.piece_url ?? '');
		
    }
    const emptyPiece = () => {
        setId('');
        setUser_id('');
		setType_piece_id('');
		setPiece_url('');
		
    }

    return {
        id,
        user_id,
		type_piece_id,
		piece_url,
		
        errors,
        isDisabled,
        setUser_id,
		setType_piece_id,
		setPiece_url,
		
        setId,
        setErrors,
        setIsDisabled,
        getPiece,
        createPiece,
        updatePiece,
        deletePiece,
        fillPiece,
        emptyPiece
    };
}