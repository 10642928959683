import { Api } from './Api';

const  ENPOINTS = {
    User: 'users',
    Demande: 'demandes',
    Dossier: 'dossier',
};

const getAll = signal => {
    return Api.get(ENPOINTS.User, signal)
}

const getCurrentUser = signal => {
    return Api.get('user', signal)
}

const getDemandes = (signal, page=1) => {
    return Api.get(`user/${ENPOINTS.Demande}?page=${page}`, signal)
}

const getDossier = signal => {
    return Api.get(`user/${ENPOINTS.Dossier}`, signal)
}

const getDossiers = signal => {
    return Api.gets(`user/${ENPOINTS.Dossier}`, signal)
}
const getById = (id, signal) => {
    return Api.get(`${ENPOINTS.User}/${id}`, signal);
}

const create = (payload, signal) => {
    return Api.post(ENPOINTS.User, payload, signal)
}

const update = (payload, signal) => {
    return Api.put(`user/profile`, payload, signal)
}
const updatePassword = (payload, signal) => {
    return Api.put(`user/password`, payload, signal)
}
const destroy = (id, signal) => {
    return Api.erase(`${ENPOINTS.User}/${id}`, signal)
}

export const UserService = {
    getAll,
    getCurrentUser,
    getDemandes,
    getDossier,
    getDossiers,
    getById,
    create,
    update,
    updatePassword,
    destroy
}