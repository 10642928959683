// import { Components } from '..'

export function DemandeForm(props) {
    return (
        <form className='form' disabled={props.isDisabled ?? false}
        onSubmit={props.handleFormSubmit ?? null}>
            <div className='row'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='dossier_id'>Dossier_id</label>
                        <select className='select2 form-control' id='dossier_id' name='dossier_id' value={props.useDemande.dossier_id ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useDemande.setDossier_id(e.target.value) ?? null} required>
                            {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
                        </select>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='type_demande_id'>Type_demande_id</label>
                        <select className='select2 form-control' id='type_demande_id' name='type_demande_id' value={props.useDemande.type_demande_id ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useDemande.setType_demande_id(e.target.value) ?? null} required>
                            {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
                        </select>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='etape_traitement_id'>Etape_traitement_id</label>
                        <select className='select2 form-control' id='etape_traitement_id' name='etape_traitement_id' value={props.useDemande.etape_traitement_id ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useDemande.setEtape_traitement_id(e.target.value) ?? null} required>
                            {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
                        </select>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='pays_id'>Pays_id</label>
                        <select className='select2 form-control' id='pays_id' name='pays_id' value={props.useDemande.pays_id ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useDemande.setPays_id(e.target.value) ?? null} required>
                            {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
                        </select>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='etat_demande_id'>Etat_demande_id</label>
                        <select className='select2 form-control' id='etat_demande_id' name='etat_demande_id' value={props.useDemande.etat_demande_id ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useDemande.setEtat_demande_id(e.target.value) ?? null} required>
                            {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
                        </select>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='numero_demande'>Numero_demande</label>
                        <input className='form-control' type='text' id='numero_demande' name='numero_demande' 
                        placeholder='Numero_demande' value={props.useDemande.numero_demande ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useDemande.setNumero_demande(e.target.value) ?? null} required/>
                    </div>
                </div>
				
                <div className='col-12 text-right'>
                    <button disabled={props.isDisabled ?? false} type='submit' className='btn btn-primary' 
                    onClick={props.handleFormSubmit}>
                        <span>Enregistrer</span>
                    </button>
                </div>
            </div>
        </form>
    )
}