/**
 * 
 * @param {*} data 
 * @returns 
 */

const dataDataGreffeNom = async (data) => {
  try {
    // https://api.datagreffe.ci/recouv/search
    const response = await fetch("https://api.datagreffe.ci/api/search", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: process.env.REACT_APP_DATAGREFFE_HEADER_KEY,
      },
      body: JSON.stringify({ value: data }),
    });

    // console.log("Success", response);
    return { response: await response.json(), status: response.status };
  } catch (error) {
    console.error("Error:", error);
    // return error;
  }
};

const dataDataGreffeRccm = async (rccm) => {
  try {
    // https://api.datagreffe.ci/recouv/search
    const response = await fetch("https://api.datagreffe.ci/api/detail", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: process.env.REACT_APP_DATAGREFFE_HEADER_KEY,
      },
      body: JSON.stringify({ value: rccm }),
    });

    // console.log("Success11:", response);
    return { response: await response.json(), status: response.status };
  } catch (error) {
    console.error("Error:", error);
  }
};

export { dataDataGreffeNom, dataDataGreffeRccm };
