import { useState } from "react";
import { Services } from "../services";

export const useTypeDemande = () => {
  const [id, setId] = useState("");
  const [libelle, setLibelle] = useState("");

  const [errors, setErrors] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const getTypeDemande = async (type_demandeId, signal) => {
    const { type_demande } = await Services.TypeDemandeService.getById(
      type_demandeId,
      signal
    );

    fillTypeDemande(type_demande);
    setIsDisabled(false);

    return type_demande;
  };

  const createTypeDemande = (signal) => {
    const payload = {
      libelle,
    };

    return Services.TypeDemandeService.create(JSON.stringify(payload), signal);
  };
  const updateTypeDemande = (type_demandeId, signal) => {
    const payload = {
      libelle,
    };

    return Services.TypeDemandeService.update(
      type_demandeId,
      JSON.stringify(payload),
      signal
    );
  };
  const deleteTypeDemande = (type_demandeId, signal) => {
    return Services.TypeDemandeService.destroy(type_demandeId, signal);
  };
  const fillTypeDemande = (type_demande) => {
    setId(type_demande.id);
    setLibelle(type_demande.libelle ?? "");
  };
  const emptyTypeDemande = () => {
    setId("");
    setLibelle("");
  };

  return {
    id,
    libelle,

    errors,
    isDisabled,
    setLibelle,

    setId,
    setErrors,
    setIsDisabled,
    getTypeDemande,
    createTypeDemande,
    updateTypeDemande,
    deleteTypeDemande,
    fillTypeDemande,
    emptyTypeDemande,
  };
};
