import {  useState } from "react";
// import { Utils } from "../../../../../utils";
import PhoneInput from "react-phone-input-2";

export function InfoAssemble(props) {
  // const formContainer = "gerants-form-container";
  // const associes = JSON.parse(props.associes);

  // const [nombreGerant, setNombreGerant] = useState("");
  const [gerant, setGerant] = useState({});
  // const [listeInfo, setListeInfo] = useState([]);

  // const getAssocieName = (associe) => {
  //   switch (associe.type) {
  //     case "particulier":
  //       return `${associe?.nom} ${associe?.prenoms}`;
  //     case "moi-meme":
  //       return Utils.Auth.getUser().username;
  //     case "societe":
  //       return associe["denomination"];
  //     default:
  //       return null;
  //   }
  // };
  // let result;
  // if (props.chamDemande["modification-du-statut"]) {
  //   let tab = props.chamDemande["modification-du-statut"];
  //   result = Object.entries(tab).map(([key, value]) => ({ key, value }));

  //   // console.log('aza',result)
  // }

  const handleInputChange = (name, value) => {
    const gerantsCopy = { ...gerant };
    gerantsCopy[name] = value;

    setGerant(gerantsCopy);
  };
  // const handleTypeGerantChange = (index, value) => {
  //   const gerantsCopy = gerants.map((gerant) => {
  //     return { ...gerant };
  //   });
  //   gerantsCopy[index] = {};
  //   gerantsCopy[index]["fait-partie-des-associes"] = value;

  //   const formContainerElem = window.document.querySelector(
  //     `#${formContainer}-${index}`
  //   );
  //   Array.from(formContainerElem.querySelectorAll(".field")).forEach(
  //     (field) => (field.value = "")
  //   );

  //   setGerants(gerantsCopy);
  // };
  const handleStepSubmit = (e) => {
    e.preventDefault();
    // console.log('info-assemble-generale',JSON.stringify(gerant));
    if (!gerant) return alert("Vous devez remplir le formulaire !");
    props.handleNextClick(e, "gerants", JSON.stringify(gerant));
  };

  // useEffect(() => {
  //   if (!nombreGerant) return;
  //   setGerants(Array(parseInt(nombreGerant)).fill({}));
  // }, [nombreGerant]);
  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <h4 className="intro-y col-span-12 text-lg leading-none">
        <strong>
          Renseignez les Informations relatives à l’assemblée générale
        </strong>
      </h4>
      {/* <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Combien de gérants voulez-vous nommer ? </div>
        <input
          type="number"
          className="input w-full border flex-1"
          value={nombreGerant}
          min={0}
          max={4}
          placeholder="Combien de gérants voulez-vous nommer ?"
          onChange={(e) => setNombreGerant(e.target.value)}
        />
      </div> */}
      <div className="col-span-12 text-md underline">
        Qui est le Président de l’assemblée générale
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Civilité</div>
        <select
          type="text"
          className="input w-full border flex-1 field"
          onChange={(e) =>
            handleInputChange("civilite-president-assemble", e.target.value)
          }
        >
          <option value="">Veuillez selectionner la civilité ?</option>
          <option value="monsieur">Monsieur</option>
          <option value="madame">Madame</option>
          <option value="mademoiselle">Mademoiselle</option>
        </select>
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2 ">Nom </div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Nom président de l'assemblée"
          onChange={(e) =>
            handleInputChange("nom-president-assemble", e.target.value)
          }
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2 ">Prénom(s)</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Prénom(s)"
          onChange={(e) =>
            handleInputChange("prenom-president-assemble", e.target.value)
          }
        />
      </div>
      <div className="col-span-12 text-md underline">
        Quelles sont les informations du dirigeant actuel de la société :
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Civilité</div>
        <select
          type="text"
          className="input w-full border flex-1 field"
          onChange={(e) =>
            handleInputChange("civilite-dirigeant-societe", e.target.value)
          }
        >
          <option value="">Veuillez selectionner la civilité ?</option>
          <option value="monsieur">Monsieur</option>
          <option value="madame">Madame</option>
          <option value="mademoiselle">Mademoiselle</option>
        </select>
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2 ">Nom(s)</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Nom(s) du gérant"
          onChange={(e) =>
            handleInputChange("nom-dirigeant-societe", e.target.value)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2 ">Prénom(s)</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Prénom(s) du gérant"
          onChange={(e) =>
            handleInputChange("prenoms-dirigeant-societe", e.target.value)
          }
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Adresse</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Adresse"
          onChange={(e) =>
            handleInputChange("adresse-dirigeant-societe", e.target.value)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Date de naissance</div>
        <input
          type="date"
          className="input w-full border flex-1 field"
          placeholder="Date de naissance"
          onChange={(e) =>
            handleInputChange(
              "date-naissance-dirigeant-societe",
              e.target.value
            )
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Pays de naissance</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Pays de naissance"
          onChange={(e) =>
            handleInputChange(
              "pays-naissance-dirigeant-societe",
              e.target.value
            )
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Ville de naissance</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Ville de naissance"
          onChange={(e) =>
            handleInputChange(
              "vile-naissance-dirigeant-societe",
              e.target.value
            )
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Nationalite</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Nationalite"
          onChange={(e) =>
            handleInputChange("nationalite-dirigeant-societe", e.target.value)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Adresse mail</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Adresse mail"
          onChange={(e) =>
            handleInputChange("adresse-mail-dirigeant-societe", e.target.value)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Numéro de téléphone (whatsapp)</div>
        {/* <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Numéro de téléphone  (whatsapp)"
          onChange={(e) =>
            handleInputChange(
              "numero-telephone-dirigeant-societe",
              e.target.value
            )
          }
          
        /> */}
        <PhoneInput
          country={"ci"}
          // value={e.target.value
          onChange={(e) =>
            handleInputChange("numero-telephone", e)
          }
          autoFormat={false}
          inputStyle={{
            padding: "21px1 20px 21px 52px",
            width: "100%",
            boxShadow: "0px 3px 5px #00000007",
            borderColor: "rgba(226, 232, 240, 1)",
          }}
          placeholder="Numéro de téléphone"
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Type de la piece d'identité</div>
        <select
          type="text"
          className="input w-full border flex-1 field"
          onChange={(e) =>
            handleInputChange(
              "type-piece-identite-dirigeant-societe",
              e.target.value
            )
          }
        >
          <option value="CNI">CNI</option>
          <option value="Passeport">Passeport</option>
          <option value="Attestation d'identité">Attestation d'identité</option>
          <option value="Titre de séjour">Titre de séjour</option>
          <option value="Carte de résident">Carte de résident</option>
          <option value="Autres">Autres</option>
        </select>
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Numéro de la pièce</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Numéro de la pièce"
          onChange={(e) =>
            handleInputChange("numero-piece-dirigeant-societe", e.target.value)
          }
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Date d’établissement de la pièce</div>
        <input
          type="date"
          className="input w-full border flex-1 field"
          placeholder="Date d’établissement de la pièce"
          onChange={(e) =>
            handleInputChange(
              "date-piece-identite-dirigeant-societe",
              e.target.value
            )
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Date de validité de la pièce</div>
        <input
          type="date"
          className="input w-full border flex-1 field"
          placeholder="Date de validité de la pièce"
          onChange={(e) =>
            handleInputChange(
              "date-fin-validite-piece-identite",
              e.target.value
            )
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Nom du père</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Nom du père"
          onChange={(e) =>
            handleInputChange("nom-pere-dirigeant-societe", e.target.value)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Nom de la mère</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Nom de la mère"
          onChange={(e) =>
            handleInputChange("nom-pere-dirigeant-societe", e.target.value)
          }
        />
      </div>
      {/* <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12">
        {result?.map((val, idx) => {
          return (
            <Fragment key={idx}>
              <div className="intro-y col-span-12 sm:col-span-6">
                {val.value.includes("modifie mes activités") ? (
                  <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="intro-y col-span-12 sm:col-span-12 text-lg">
                      Quel est le nouvel objet social de la société ?
                    </div>
                    <span className="intro-y col-span-12 sm:col-span-12">
                      Changement de dénomination
                    </span>{" "}
                    <span className="mb-2">
                      Indiquez le nouveau nom de la Société
                    </span>
                    <input
                      type="text"
                      className="input w-full border flex-1 field"
                      placeholder="Ex : Nickitech"
                      onChange={(e) =>
                        handleInputChange(
                          "changement-denomination",
                          e.target.value
                        )
                      }
                    />
                  </div>
                ) : null}

                {val.value.includes("transfère mon siège social") ? (
                  <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="intro-y col-span-12 sm:col-span-12">
                      Transfert de siège social
                    </div>
                    <div className="mb-2">
                      Indiquez le nouveau siège de la société
                    </div>
                    <textarea
                      placeholder="Ex : Abidjan Cocody Les II Plateaux 7ème tranche"
                      className={`input w-full border flex-1 field`}
                      rows="5"
                      cols="33"
                      onChange={(e) =>
                        handleInputChange("transfer-siege", e.target.value)
                      }
                    />
                  </div>
                ) : null}

                {val.value.includes("changement du gérant de la société") ? (
                  <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="intro-y col-span-12 sm:col-span-12">
                      changement du gérant de la société
                    </div>
                    <div className="mb-2">
                      Le gérant remplacé est-il mentionné dans les statuts ?
                    </div>
                    <div className="flex flex-col sm:flex-row mb-2 col-span-12">
                      <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                        <input
                          type="radio"
                          className="input border mr-2 field"
                          id={`horizontal-radio-status-oui-0`}
                          name={`status-gerant`}
                          value="Oui"
                          onChange={(e) =>
                            handleInputChange("status-gerant", "Oui")
                          }
                        />
                        <label
                          className="cursor-pointer select-none"
                          htmlFor={`horizontal-radio-status-oui-0`}
                        >
                          Oui
                        </label>
                      </div>
                      <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                        <input
                          type="radio"
                          className="input border mr-2 field"
                          id={`horizontal-radio-status-non-0`}
                          name={`status-gerant`}
                          value="marie"
                          onChange={(e) =>
                            handleInputChange("status-gerant", "Non")
                          }
                        />
                        <label
                          className="cursor-pointer select-none"
                          htmlFor={`horizontal-radio-status-non-0`}
                        >
                          Non
                        </label>
                      </div>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="mb-2">Civilité</div>
                      <select
                        type="text"
                        className="input w-full border flex-1 field"
                        onChange={(e) =>
                          handleInputChange(
                            "civilite-nouveau-gerant",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Veuillez selectionner  la civilité ?</option>
                        <option value="monsieur">Monsieur</option>
                        <option value="madame">Madame</option>
                        <option value="mademoiselle">Mademoiselle</option>
                      </select>
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="mb-2 ">Nom du gérant</div>
                      <input
                        type="text"
                        className="input w-full border flex-1 field"
                        placeholder="Nom du gérant"
                        onChange={(e) =>
                          handleInputChange(
                            "nom-nouveau-gerant",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="mb-2 ">Prénom(s) du gérant</div>
                      <input
                        type="text"
                        className="input w-full border flex-1 field"
                        placeholder="Prénom(s) du gérant"
                        onChange={(e) =>
                          handleInputChange(
                            "prenoms-nouveau-gerant",
                            e.target.value
                          )
                        }
                      />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="my-2">Adresse</div>
                      <input
                        type="text"
                        className="input w-full border flex-1 field"
                        placeholder="Adresse"
                        onChange={(e) =>
                          handleInputChange(
                            "adresse-nouveau-gerant",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="mb-2">Date de naissance</div>
                      <input
                        type="date"
                        className="input w-full border flex-1 field"
                        placeholder="Date de naissance"
                        onChange={(e) =>
                          handleInputChange(
                            "date-naissance-nouveau-gerant",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="mb-2">Lieu de naissance</div>
                      <input
                        type="text"
                        className="input w-full border flex-1 field"
                        placeholder="Lieu de naissance"
                        onChange={(e) =>
                          handleInputChange(
                            "lieu-naissance-nouveau-gerant",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="mb-2">Nationalite</div>
                      <input
                        type="text"
                        className="input w-full border flex-1 field"
                        placeholder="Nationalite"
                        onChange={(e) =>
                          handleInputChange(
                            "nationalite-nouveau-gerant",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </Fragment>
          );
        })}
      </div>

     {gerants.map((gerant, index) => {
        return (
          <div
            className="intro-y col-span-12"
            key={index}
            id={`${formContainer}-${index}`}
          >
            <div className="mb-2">
              Le gérant (numéro {index + 1}) fait partie des Associés ?
            </div>
            <div className="flex flex-col sm:flex-row mt-4 mb-2">
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                <input
                  type="radio"
                  className="input border mr-2"
                  id={`horizontal-radio-oui-${index}`}
                  name={`gerant-${index}`}
                  value="non"
                  onChange={(e) => handleTypeGerantChange(index, "oui")}
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`horizontal-radio-oui-${index}`}
                >
                  Oui
                </label>
              </div>
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                <input
                  type="radio"
                  className="input border mr-2"
                  id={`horizontal-non-${index}`}
                  name={`gerant-${index}`}
                  value="oui"
                  onChange={(e) => handleTypeGerantChange(index, "non")}
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`horizontal-non-${index}`}
                >
                  Non
                </label>
              </div>
            </div>
            <div
              className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${
                          gerant["fait-partie-des-associes"] !== undefined &&
                          gerant["fait-partie-des-associes"] === "oui"
                            ? ""
                            : "hidden"
                        }`}
            >
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Indiquez l’associé gérant</div>
                <select
                  type="text"
                  className="input w-full border flex-1 field"
                  onChange={(e) =>
                    handleInputChange(index, "associe-gerant", e.target.value)
                  }
                >
                  {associes.map((associe, index) => {
                    return (
                      <option value={getAssocieName(associe)} key={index}>
                        {getAssocieName(associe)}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom & Prénom(s) du père du gérant</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nom & Prénom(s) du père du gérant"
                  onChange={(e) =>
                    handleInputChange(index, "nom-pere", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom & Prénom(s) de la mère du gérant</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nom & Prénom(s) de la mère du  gérant"
                  onChange={(e) =>
                    handleInputChange(index, "nom-mere", e.target.value)
                  }
                />
              </div>
            </div>
            <div
              className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${
                          gerant["fait-partie-des-associes"] !== undefined &&
                          gerant["fait-partie-des-associes"] === "non"
                            ? ""
                            : "hidden"
                        }`}
            >
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nom"
                  onChange={(e) =>
                    handleInputChange(index, "nom", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Prenom</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Prenom"
                  onChange={(e) =>
                    handleInputChange(index, "prenoms", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de téléphone</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Numéro de téléphone"
                  onChange={(e) =>
                    handleInputChange(index, "numero-telephone", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Date de naissance</div>
                <input
                  type="date"
                  className="input w-full border flex-1 field"
                  placeholder="Date de naissance"
                  onChange={(e) =>
                    handleInputChange(index, "date-naissance", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Lieu de naissance</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Lieu de naissance"
                  onChange={(e) =>
                    handleInputChange(index, "lieu-naissance", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom & Prénom(s) du père du gérant</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nom & Prénom(s) du père du gérant"
                  onChange={(e) =>
                    handleInputChange(index, "nom-pere", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom & Prénom(s) de la mère du gérant</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nom & Prénom(s) de la mère du  gérant"
                  onChange={(e) =>
                    handleInputChange(index, "nom-mere", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        );
      })} */}
      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
