export function InfoEdit(props) {
    const handleSigleChange = value => {
        const sigleObject = {
            sigle: value,
            'sigle-obligatoire': ''
        }
        props.setSigle(sigleObject);
    }
    return (
        <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12">
            <div className="intro-y col-span-12">
                <div className="mb-2">Activites</div>
                <textarea value={props.activites}
                className={`input w-full border flex-1 field`} rows="5" cols="33" 
                onChange={e => props.setActivites(e.target.value)} />
            </div>
            <div className="intro-y col-span-12">
                <div className="mb-2">Sigle</div>
                <input type="text" className={`input w-full border flex-1 field}`} 
                value={props.sigle.sigle} placeholder="Votre réponse" 
                onChange={e => handleSigleChange(e.target.value)}/>
            </div>
        </div>
    )
}