import React from 'react';
import { Route, Routes } from "react-router-dom";
import { Views } from "../views";
import { Layouts } from "../layouts";

export function MainRoutes(props){
    return (
        <Layouts.MainLayout>
            <Routes>
                <Route path='/demandes/:id/paiement' element={<Views.PaiementView />}/>
                <Route path='/commandes/:type/:id' element={<Views.DemandeCreateView />}/>
                <Route path='/commandes/:id/etape-traitements' element={<Views.DemandeEtapeTraitementView />}/>
                <Route path='/commandes/:id/modifier' element={<Views.DemandeEditView />}/>
                <Route path='/commandes/:id/solde' element={<Views.DemandePaiementView />}/>
                <Route path='/commandes/:id' element={<Views.DemandeDetailView />}/>
                <Route path='/commandes' element={<Views.DemandeListView />}/>
                <Route path='/aides' element={<Views.AideView />}/>

                <Route path='/documents' element={<Views.DocumentListView />}/>
                <Route path='/profil' element={<Views.ProfileView />}/>
                <Route path='/nouveau-mot-de-passe' element={<Views.NewPasswordView />}/>
                <Route path='/test' element={<Views.TestView />}/>
                <Route path="/checkout/payment/:token?" element={<Views.PaiementCheckoutView />} />

                <Route path="" element={<Views.DashboardView />}/>
            </Routes>
        </Layouts.MainLayout>
    )
}