import { useState } from 'react';
import { Services } from '../services';

export const useCodePromo = () => {
    const [id, setId] = useState('');
	const [pourcentage, setPourcentage] = useState('');
	

    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getCodePromo = (codepromoId, signal) => {        
        return Services.CodePromoService.getById(codepromoId, signal)
        .then(response => {
            fillCodePromo(response.codepromo);
            setIsDisabled(false);
        });
    }

    const createCodePromo = signal => {
        const payload = {
            pourcentage,
		
        };

        return Services.CodePromoService.create(JSON.stringify(payload), signal);
    }
    const updateCodePromo = (codepromoId, signal) => {
        const payload = {
            pourcentage,
		
        };

        return Services.CodePromoService.update(codepromoId, JSON.stringify(payload), signal);
    }
    const deleteCodePromo = (codepromoId, signal) => {
        return Services.CodePromoService.destroy(codepromoId, signal);
    }
    const fillCodePromo = (codepromo) => {
        setId(codepromo.id);
        setPourcentage(codepromo.pourcentage ?? '');
		
    }
    const emptyCodePromo = () => {
        setId('');
        setPourcentage('');
		
    }

    return {
        id,
        pourcentage,
		
        errors,
        isDisabled,
        setPourcentage,
		
        setId,
        setErrors,
        setIsDisabled,
        getCodePromo,
        createCodePromo,
        updateCodePromo,
        deleteCodePromo,
        fillCodePromo,
        emptyCodePromo
    };
}