import { Utils } from '../utils';
import { Api } from './Api';

const  ENPOINTS = {
    Login: 'login',
    Logout: 'logout',
    Register: 'register',
    Password:'init/password',
    Mail: 'forgot/password'
};

const login = (payload, signal) => {
    return Api.post(ENPOINTS.Login, payload, signal)
}

const register = (payload, signal) => {
    return Api.post(ENPOINTS.Register, payload, signal)
}

const password = (payload, signal) => {
    return Api.post(ENPOINTS.Password, payload, signal)
}

const mail = (payload, signal) => {
    return Api.post(ENPOINTS.Mail, payload, signal)
}

const logout = (navigate) => {
    Utils.Auth.removeSessionToken();

    if (navigate) {
        navigate('/connexion')
        return;
    }
    
    window.location.replace('/connexion');
}

export const AuthService = {
    login,
    logout,
    register,
    password,
    mail
}