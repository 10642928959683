import { Link, NavLink, useLocation } from 'react-router-dom';
import logoImg from '../assets/images/logo-legafrik.png';
import * as Icons from 'react-feather';

export function SideNav(props){
    const {pathname} = useLocation();

    const activeClassName = 'side-menu--active';
    const dynamicClassName = ({ isActive }) => {;
        return isActive ? `side-menu ${activeClassName}` : 'side-menu';
    }

    return (
        <nav className="side-nav">
            <Link to="/" className="intro-x flex items-center pl-5 pt-4"
            style={{position: "relative", zIndex: 3}}>
                <img alt="Midone Tailwind HTML Admin Template" 
                className="w-6" src={logoImg} />
                <span className="hidden xl:block text-lg ml-3"> 
                    Leg<span className="font-medium">afrik</span> 
                </span>
            </Link>
            <div className="side-nav__devider my-6"></div>
            <ul>
                <li>
                    <Link to="/" className={`side-menu ${pathname === '/' ? activeClassName : ''}`}>
                        <div className="side-menu__icon"> <Icons.Home /> </div>
                        <div className="side-menu__title"> Accueil </div>
                    </Link>
                </li>
                <li>
                    <NavLink to="/commandes" className={dynamicClassName}>
                        <div className="side-menu__icon">
                            <Icons.ShoppingCart />
                        </div>
                        <div className="side-menu__title"> 
                            Commandes
                        </div>
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink to="/documents" className={dynamicClassName}>
                        <div className="side-menu__icon">
                            <Icons.Folder />
                        </div>
                        <div className="side-menu__title"> Documents </div>
                    </NavLink>
                </li> */}
                <li>
                    <NavLink to="/aides" className={dynamicClassName}>
                        <div className="side-menu__icon">
                            <Icons.HelpCircle />
                        </div>
                        <div className="side-menu__title"> Aides </div>
                    </NavLink>
                </li>
                <li className="side-nav__devider my-6"></li>
            </ul>
        </nav>
    )
}