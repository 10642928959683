export const DemandeLabels = {
  "nombre-associes": "Le nombre d'associés",
  banque: "Banque",
  "entreprise-anterieur": "Avez-vous déjà créé une entreprise",
  "date-reception-certificat": "Date désirée pour la réception du certificat",
  "localisation-siege": "Localisation du siège de votre entreprise ?",
  "capital-social": "Capital social",
  "est-president": "Est-il le président",
  offre: "Offre",
  "gestion-domiciliation": "Domiciliation",
  "gestion-dfe": "Déclaration Fiscale D'existence",
  "gestion-graphique": "Identité visuelle",
  "gestion-facture-normalisees": "Facture normalisée",
  "solution-pay": "Gestion comptable",
  email: "Email",
  nom: "Nom",
  prenoms: "Prénom(s)",
  "numero-telephone": "Numéro de téléphone",
  monnaie: "Monnaie utilisée",
  ville: "Ville",
  "code-postale": "Numéro de boîte postale",
  adresse: "Adresse",
  quartier: "Quartier",
  commune: "Commune",
  lot: "Lot",
  ilot: "Ilot",
  section: "Numéro de section",
  parcelle: "Numéro de parcelle",
  "nom-proprietaire": "Nom du propriétaire",
  "prenoms-proprietaire": "Prénom(s) du propriétaire",
  "numero-telephone-proprietaire": "Numéro téléphone propriétaire",
  type: "Nature",
  civilite: "Civilité",
  "type-piece-identite": "Nature de la pièce d’identité",
  "numero-piece-identite": "Numéro de la pièce",
  "date-naissance": "Date de naissance de l'associé",
  "lieu-naissance": "Lieu de naissance de l'associé",
  "pays-naissance": "Pays de naissance de l'associé",
  nationalite: "Nationnalite de l'associé",
  "secteur-activite": "Secteur d’activités",
  sigle: "Sigle",
  "situation-matrimoniale": "Situation matrimoniale",
  "regime-matrimonial": "Régime matrimonial",
  apport: "Apport de l'associé",
  denomination: "Dénomination",
  "forme-sociale": "Forme sociale",
  "capital-sociale": "Capitale social",
  "siege-sociale": "Siège social",
  "numero-sociale": "Numéro registre de commerce",
  "ville-immatriculation": "Ville immatriculation",
  "nom-representant": "Nom du représentant",
  "prenoms-representant": "Prénom(s) du représentant",
  "civilite-representant": "Civilité du représentant",
  "associe-gerant": "L'associé est-il un gérant ?",
  "nom-pere": "Nom et prenom(s) du père",
  "nom-mere": "Nom et prenom(s) de la mère",
  premiere_proposition: "1ere proposition de nom",
  deuxieme_proposition: "2eme proposition de nom",
  troisieme_proposition: "3eme proposition de nom",
  sigle_obligatoire: "Le sigle est-il obligatoire",
  "Numero telephone": "Numéro de téléphone",
  "Secteur activite": "Secteur d’activités",
  Prenoms: "Prénom(s)",
  "Entreprise anterieur": "Entreprise anterieure",
  "Localisation siege": "Localisation du siège",
  "Gestion dfe": "Gestion DFE",
  "Gestion facture normalisees": "Gestion facture normalisée",
  Denomination: "Dénomination",
  "Nombre associes": "Nombre d'associés",
  "Date reception certificat": "Date de réception certificat",
  "date-piece-identite": "Date d'établissement de la pièce",
  "date-fin-validite-piece-identite": "Date de validité de la pièce",
  "pays-piece-identite": "Pays d'établissement de la pièce",
  Statut: "Status",
  "numero-boite-postale": "Numéro de boite postale",
  "associe-president": "Le président a t'il associé(s) ?",
  "fait-partie-des-associes": "Le président fait il partie des associes ?",
  activites: "activité(s)",
  Nationalite: "Nationalité",
  "civilite-proprietaire": "Civilité du proprietaire",
  "nom-president-assemble": "Nom du président",
  "prenom-president-assemble": "Prénom du président",
  "civilite-president-assemble": "Civilité du président",
  "adresse-dirigeant-societe": "Adresse dudirigeant de la société",
  "adresse-mail-dirigeant-societe": "Adresse mail du dirigeant de la société",
  "civilite-dirigeant-societe": "Civilité du dirigeant de la société",
  "date-naissance-dirigeant-societe": "Date de naissance",
  "date-piece-identite-dirigeant-societe" :"Date de pièce d'identité",
  "nombre-de-part-detenue":"Nombre de parts detenues",
  "nombre-de-voix":"Nombre de voies"
};
