import { Components } from "../..";
import { Utils } from "../../../utils";

export function RecapStepV2(props) {
    const { isJsonParseable, parseJson } = Utils.String;

    return (
        <div className={`grid grid-cols-1 col-span-12 
        ${props.step !== props.index ? "hidden" : ""} `}>
            {Object.keys(props.questionnaireObject).map((section, index) => {
                const sectionValue = props.questionnaireObject[section];
                return (
                    <div className="flex-content-column col-span-12" key={index}>
                        <Components.RecaptItem title={section} 
                        name={section} value={!isJsonParseable(sectionValue) ? 
                            sectionValue : parseJson(sectionValue)}/>
                    </div>
                )
            })}
            <div className="intro-y col-span-12 flex items-center justify-center
            sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 
                text-gray-600 dark:bg-dark-1 dark:text-gray-300">
                    Revenir
                </button>
                <button className="button w-24 justify-center block bg-theme-1 
                text-white ml-2">
                    Suivant
                </button>
            </div>
        </div>
    )
}