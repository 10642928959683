import { useState, useEffect } from "react";

import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";
import * as Icons from "react-feather";
import { Utils } from "../../../../../utils";
import PhoneInput from "react-phone-input-2";

export function AssociesStatutaire(props) {
  // console.log("Associes",props);
  // const formContainer = "associes-form-container";
  const [nombreAssocies, setNombreAssocies] = useState(0);

  const [associes, setAssocies] = useState([]);
  const [, setIsMeme] = useState(100);
  var test = Utils.Auth.getUser();

  const handleTypeAssocieChange = (index, value) => {
    // console.log("handleTypeAssocieChange", index, value);
    const associesCopy = associes.map((associe) => {
      return { ...associe };
    });
    associesCopy[index] = {};
    associesCopy[index]["type"] = value;

    // const formContainerElem = window.document.querySelector(
    //   `#${formContainer}-${index}`
    // );
    // Array.from(formContainerElem.querySelectorAll(".field")).forEach(
    //   (field) => (field.value = "")
    // );

    if (value === "moi-meme") {
      var ligne = test["username"].indexOf(" "); // Gets the first index where a space occours
      associesCopy[index]["nom"] = test["username"].substr(0, ligne); // Gets the first part
      associesCopy[index]["prenoms"] = test["username"].substr(ligne + 1);

      associesCopy[index]["numero-telephone"] = test["numero-telephone"];
      setIsMeme(index);
    }

    // console.log("associesCopy",associesCopy);
    setAssocies(associesCopy);
  };
  const handleInputChange = (index, name, value) => {
    // console.log("index, name, value", index, name, value);
    const associesCopy = associes.map((associe) => {
      return { ...associe };
    });
    associesCopy[index][name] = value;
    // console.log("associesCopy",associes);

    setAssocies(associesCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();
    props.handleNextClick(e, "associes", JSON.stringify(associes));
  };

  useEffect(() => {
    if (!nombreAssocies) return;
    if (nombreAssocies <= 50) {
      setAssocies(Array(parseInt(nombreAssocies)).fill({}));
    }
  }, [nombreAssocies]);

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12  ">
        <strong> Qui sont les associés de la société ?</strong>
      </div>
      <div className="intro-y col-span-12  ">
        <Alert variant="danger">
          <Alert.Heading>
            <Icons.Info className="w-4 h-4 " />
          </Alert.Heading>
          Renseignez les informations personnelles de
          <Alert.Link href="#"> chaque associé </Alert.Link> (y compris
          vous-mêmes si vous êtes associé). Si vous n’avez pas certaines
          informations, vous pourrez compléter plus tard
        </Alert>
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">
          Indiquez le nombre d’associés présents ou représentés à l’assemblée
          générale :
        </div>
        <input
          type="number"
          min={0}
          className="input w-full border flex-1 field"
          placeholder="Le nombre d’associés présents ou représentés"
          maxLength={10}
          onChange={(e) => setNombreAssocies(e.target.value)}
        />
      </div>
      {nombreAssocies && nombreAssocies > 50 ? (
        <div className="intro-y col-span-12 text-center ">
          <Alert variant="danger">
            Le nombre d'associés ne doit pas dépasser 50 personnes !
          </Alert>
        </div>
      ) : null}
      {associes.map((associe, index) => {
        return (
          <div className="intro-y col-span-12" key={index}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                  ASSOCIÉ (NUMÉRO {index + 1})
                  <br />
                  Indiquez les informations relatives à l'associé {index + 1}
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    className="intro-y col-span-12"
                    key={index}
                    id={`${index}`}
                  >
                    <div className="grid grid-cols-12 mx-auto max-w-md  items-center mt-4 mb-2">
                      <div
                        className="box px-4 py-4 mb-3  col-span-12 sm:col-span-8  items-center zoom-in "
                        id="main-select"
                      >
                        <input
                          type="radio"
                          className="overlay opacity-0"
                          id={`horizontal-radio-moi-meme-${index}`}
                          name={`associe-${index}`}
                          value="moi-meme"
                          onChange={(e) =>
                            handleTypeAssocieChange(index, "moi-meme")
                          }
                        />

                        <div className="ml-2 mr-auto">
                          <div className="font-medium">Moi même</div>
                        </div>

                        <div className="radio-circle">
                          <div className="radio-circle__outer-circle">
                            <div className="radio-circle__inner-circle"></div>
                          </div>
                        </div>
                      </div>

                      <div className="box px-4 py-4 mb-3 col-span-12 sm:col-span-8  items-center zoom-in ">
                        <input
                          type="radio"
                          className="overlay opacity-0"
                          id={`horizontal-radio-particulier-${index}`}
                          name={`associe-${index}`}
                          value="particulier"
                          onChange={(e) =>
                            handleTypeAssocieChange(index, "particulier")
                          }
                        />
                        <div className="radio-circle">
                          <div className="radio-circle__outer-circle">
                            <div className="radio-circle__inner-circle"></div>
                          </div>
                        </div>

                        <div className="ml-2 mr-auto">
                          <div className="font-medium">Un particulier</div>
                        </div>
                      </div>

                      <div className="box px-4 py-4 mb-3 col-span-12 sm:col-span-8  items-center zoom-in ">
                        <input
                          type="radio"
                          className="overlay opacity-0"
                          id={`horizontal-radio-societe-${index}`}
                          name={`associe-${index}`}
                          value="societe"
                          onChange={(e) =>
                            handleTypeAssocieChange(index, "societe")
                          }
                        />
                        <div className="ml-2 mr-auto">
                          <div className="font-medium">Une société</div>
                        </div>

                        <div className="radio-circle">
                          <div className="radio-circle__outer-circle">
                            <div className="radio-circle__inner-circle"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12
               ${
                 associe["type"] !== undefined &&
                 (associe["type"] === "moi-meme" ||
                   associe["type"] === "particulier")
                   ? ""
                   : "hidden"
               }`}
                    >
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="my-2">Civilité</div>
                        <select
                          type="text"
                          className="input w-full border flex-1 field"
                          onChange={(e) =>
                            handleInputChange(index, "civilite", e.target.value)
                          }
                        >
                          <option value="monsieur">Monsieur</option>
                          <option value="madame">Madame</option>
                          <option value="mademoiselle">Mademoiselle</option>
                        </select>
                      </div>

                      {associe["type"] === "particulier" ? (
                        <>
                          <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Nom</div>
                            <input
                              type="text"
                              className="input w-full border flex-1 field"
                              placeholder="Nom"
                              onChange={(e) =>
                                handleInputChange(index, "nom", e.target.value)
                              }
                            />
                          </div>
                          <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Prénom(s)</div>
                            <input
                              type="text"
                              className="input w-full border flex-1 field"
                              placeholder="Prénom(s)"
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "prenoms",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Adresse mail</div>
                            <input
                              type="text"
                              className="input w-full border flex-1 field"
                              placeholder="adresse mail"
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "adresse-mail",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </>
                      ) : null}
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">
                          Numéro de téléphone (whatsapp){" "}
                        </div>
                        {/* <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Numéro de téléphone (whatsapp)"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "numero-telephone",
                              e.target.value
                            )
                          }
                        /> */}
                        <PhoneInput
                          country={"ci"}
                          // value={e.target.value
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "numero-telephone",
                              e
                            )
                          }
                          autoFormat={false}
                          inputStyle={{
                            padding: "21px1 20px 21px 52px",
                            width: "100%",
                            boxShadow: "0px 3px 5px #00000007",
                            borderColor: "rgba(226, 232, 240, 1)",
                          }}
                          placeholder="Numéro de téléphone"
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Nombre de parts détenues</div>
                        <input
                          type="number"
                          min={0}
                          className="input w-full border flex-1 field"
                          placeholder="Nombre de parts détenues"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "nombre-de-part-detenue",
                              e.target.value
                            )
                          }
                        />
                      </div>

                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">nombre de voies </div>
                        <input
                          type="number"
                          min={0}
                          className="input w-full border flex-1 field"
                          placeholder="nombre de voies "
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "nombre-de-voies",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12
               ${
                 associe["type"] !== undefined && associe["type"] === "societe"
                   ? ""
                   : "hidden"
               }`}
                    >
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">
                          Quel est le nom de la société ?
                        </div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Quel est le nom de la société ?"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "denomination",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">
                          Quelle est sa forme sociale ?
                        </div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Quelle est sa forme sociale ?"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "forme-sociale",
                              e.target.value
                            )
                          }
                        />
                      </div>

                      <div className="col-span-12 text-lg underline">
                        Représentant Légal de la société associéé :
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Civilité</div>
                        <select
                          type="text"
                          className="input w-full border flex-1 field"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "civilite-representant",
                              e.target.value
                            )
                          }
                        >
                          <option value="monsieur">Monsieur</option>
                          <option value="madame">Madame</option>
                          <option value="mademoiselle">Mademoiselle</option>
                        </select>
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Nom</div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Nom"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "nom-representant",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Prénom(s)</div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Prénom"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "prenoms-representant",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Adresse mail</div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="adresse mail"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "adresse-mail",
                              e.target.value
                            )
                          }
                        />
                      </div>

                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">
                          Numéro de téléphone (whatsapp){" "}
                        </div>
                        {/* <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Numéro de téléphone (whatsapp)"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "numero-telephone",
                              e.target.value
                            )
                          }
                        /> */}

                        <PhoneInput
                          country={"ci"}
                          // value={this.e.target}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "numero-telephone",
                              e
                            )
                          }
                          autoFormat={false}
                          inputStyle={{
                            padding: "21px1 20px 21px 52px",
                            width: "100%",
                            boxShadow: "0px 3px 5px #00000007",
                            borderColor: "rgba(226, 232, 240, 1)",
                          }}
                          placeholder="Numéro de téléphone"
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Nombre de parts détenues</div>
                        <input
                          type="number"
                          min={0}
                          className="input w-full border flex-1 field"
                          placeholder="Nombre de parts détenues"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "nombre-de-part-detenue",
                              e.target.value
                            )
                          }
                        />
                      </div>

                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">nombre de voies </div>
                        <input
                          type="number"
                          min={0}
                          className="input w-full border flex-1 field"
                          placeholder="nombre de voies "
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "nombre-de-voies",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        );
      })}
      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        {/* <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button> */}
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
