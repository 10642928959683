export const RECOUVREMENT = [
    {
        name: "denomination",
        question: "Quel est le nom de votre recouvrement ?",
        description: "",
        type: "simple", //"mutiple",
        options: [
          // {value: "", price: ""}
        ],
      },
      {
        name: "localisation-ville",
        question: "Dans quelle ville se situe votre recouvrement ?",
        description:
          "Il faudra impérativement une adresse physique et postale pour votre recouvrement.",
        type: "mutiple", //"mutiple",
        options: [
          { value: "Abidjan", price: "" },
          { value: "Hors d'Abidjan", price: "" },
        ],
      },
      {
        name: "localisation-siege",
        question: "Où se situera le siège de votre organisation ?",
        description:
          "Il faudra impérativement une adresse physique et postale pour votre recouvrement.",
        type: "mutiple", //"mutiple",
        options: [
          { value: "A mon domicile", price: "" },
          // { value: "Je domicilie à Legafrik (30 000 FCFA/mois)", price: "" },
          { value: "Je loue un local commercial", price: "" },
          { value: "Je n'ai pas de siège", price: "" },
        ],
      },
      {
        name: "secteur-activite",
        question: "Quel est votre domaine d’action ?",
        description: "",
        type: "mutiple", //"mutiple",
        options: [
          { value: "Santé", price: "" },
          { value: "Action sociale", price: "" },
          { value: "Humanitaire", price: "" },
          { value: "Environnement", price: "" },
          { value: "Art & Culture", price: "" },
          { value: "Religion", price: "" },
          { value: "Autres", price: "" },
        ],
      },
      // {
      //   name: "cabinet-formation",
      //   question:
      //     "Souhaitez vous être accompagné par Legafrik pour obtenir votre agrément FDFP ?",
      //   description: "",
      //   type: "mutiple", //"mutiple",
      //   options: [
      //     {
      //       value:
      //         "Je me fais accompagner pour obtenir l'agrément FDFP (100 000 FCFA)",
      //       price: 100_000,
      //     },
      //     { value: "Je ne suis pas Intéressé ", price: "" },
      //   ],
      // },
      {
        name: "est-president",
        question: "Êtes vous le président ?",
        description: "",
        type: "mutiple", //"mutiple",
        options: [
          { value: "Oui", price: "" },
          { value: "Non", price: "" },
        ],
      },
      {
        name: "offre",
        question: "Quelle Offre choisissez-vous ?",
        type: "mutiple", //"mutiple",
        options: [
          { value: "Abidjan (150 000 FCFA)", price: 150_000 },
          { value: "Interieur du pays (199 000 FCFA)", price: '' },
        ],
      },
      {
        name: "gestion-domiciliation",
        question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
        description: "Choisissez une formule",
        type: "mutiple", //"mutiple",
        options: [
          { value: "6 mois à 180 000 FCFA(30 000 F/MOIS)", price: 180_000 },
          { value: "12 mois à 300 000 FCFA (25 000 F/MOIS)", price: 300_000 },
        ],
      },
];
