import { BrowserRouter, Route, Routes } from "react-router-dom";

import { MainRoutes } from "./routes/MainRoutes";
import { Views } from "./views";
// import app from './assets/js/app.js';

function App() {
  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = app;

  //   document.body.appendChild(script);

  //   // return () => {
  //   //   document.body.removeChild(script);
  //   // }

  // }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/suivi/:reference?" element={<Views.SuiviDemande />} />
        <Route
          path="/suivi-questionaire"
          element={<Views.QuestionnaireSuivi />}
        />
        <Route path="/connexion" element={<Views.LoginView />} />
        <Route path="/mot-de-passe" element={<Views.MailView />} />
        <Route
          path="/new-passwords/:token?/:email?"
          element={<Views.PasswordViews />}
        />
        <Route
          path="/new-password/:id/:email"
          element={<Views.PasswordView />}
        />

        {/* https://form.legafrik.com/commandes/test/ci/sarl*/}
        <Route
          path="/commandes/initiale/:paraPays?/:type_demande?"
          element={<Views.DemandeCreateViewInitiale />}
        />
        <Route
          path="/checkout/payment/:token?"
          element={<Views.PaiementCheckoutView />}
        />

        <Route path="/inscription" element={<Views.RegisterView />} />
        <Route path="*" element={<MainRoutes />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
