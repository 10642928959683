import { SigleStep } from "./sarl/SigleStep";
import { AdresseStep } from "./sarl/AdresseStep";
import { AssocieTypes } from "../AssocieTypes";
import { CapitalStep } from "../CapitalStep";
import { ActiviteStep } from "../ActiviteStep";
import { GerantStepMa } from "../GerantStepMa";
import { PresidentStepMa } from "../PresidentStepMa";
import { DirecteurStepMa } from "../DirecteurStepMa";
import { MandatSarlStep } from "./MandatSarlStep";
import { PouvoirSarlStep } from "./PouvoirSarlStep";
import { SignatureSarlStep } from "./SignatureSarlStep";
import { TribunalSarlStep } from "./TribunalSarlStep";
import { RecapStepSarl } from "./sarl/RecapStepSarl";
import { RecapStepSas } from "../RecapStepSas";
import { AssociesMa } from '../AssociesMa';
import { AssociesUniMa } from '../AssociesUniMa';
import { RecapStepSarlMaV2 } from "./sarl/RecapStepSarlMaV2";
import { RecapStepSasMaV2 } from "./sas/RecapStepSasMaV2";


export const MR = {
    
        SARL : { SigleStep, AdresseStep,  Associes: AssociesMa, CapitalStep, ActiviteStep, 
            MandatSarlStep, PouvoirSarlStep, SignatureSarlStep, TribunalSarlStep,
            GerantStep: GerantStepMa, RecapStepSarl, RecapStepSarlMaV2},
        SARLU : { SigleSarlUniStep: SigleStep, AdresseSarlUniStep: AdresseStep,  
            AssociesSarlUniStep: AssociesUniMa, AssocieTypesSarlUniStep: AssocieTypes,
            ActiviteStepSarlUni: ActiviteStep, TribunalSarlUniStep: TribunalSarlStep,
            GerantSarlUniStep: GerantStepMa, RecapSarlUniStep: RecapStepSarl, 
            PouvoirSarlUniStep: PouvoirSarlStep, SignatureSarlUniStep: SignatureSarlStep,
            MandatSarlUniStep: MandatSarlStep, RecapStepSarlMaV2
        },
        SASU : { SigleSasuStep: SigleStep, AdresseSasuStep: AdresseStep, 
            AssociesSasuStep: AssociesUniMa, AssocieTypesSasuStep: AssocieTypes, 
            CapitalSasuStep: CapitalStep, ActiviteStepSasUni: ActiviteStep,
            PresidentSasuStep: PresidentStepMa, DirecteurSasuStep: DirecteurStepMa,
            RecapSasuStep: RecapStepSas,   PouvoirSasuStep: PouvoirSarlStep, 
            SignatureSasuStep: SignatureSarlStep, TribunalSasuStep: TribunalSarlStep, 
            MandatSasuStep: MandatSarlStep, RecapStepSasMaV2
        },
        SAS : { SigleSasStep: SigleStep, AdresseSasStep: AdresseStep, AssociesSasStep: AssociesMa, 
            ActiviteStepSas: ActiviteStep, CapitalSasStep: CapitalStep, PresidentSasStep: PresidentStepMa, 
            DirecteurSasStep: DirecteurStepMa, RecapSasStep: RecapStepSas, PouvoirSasStep: PouvoirSarlStep,
            SignatureSasStep: SignatureSarlStep, TribunalSasStep: TribunalSarlStep, 
            MandatSasStep: MandatSarlStep, RecapStepSasMaV2
        }
    
}