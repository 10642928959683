import { useEffect, useState } from "react";
// import Alert from "react-bootstrap/Alert";
// import * as Icons from "react-feather";
import PhoneInput from "react-phone-input-2";

export function RapporteurCommission(props) {
  // const [adresse, setAddresse] = useState({});
  const [nombreRapporteur, setNombreRapporteur] = useState("");
  const [rapporteur, setRapporteur] = useState([]);

  const handleInputChange = (index, name, value) => {
    const rapporteurCopy = rapporteur.map((rapport) => {
      return { ...rapport };
    });
    rapporteurCopy[index][name] = value;

    // console.log('rapporteurCopy',rapporteurCopy);

    setRapporteur(rapporteurCopy);
  };

  // console.log("rapporteur", rapporteur);

  const handleStepSubmit = (e) => {
    e.preventDefault();
    if (!nombreRapporteur) return alert("Vous devez remplir le formulaire !");
    props.handleNextClick(e, "rapporteurs", JSON.stringify(rapporteur));
  };
  useEffect(() => {
    if (!nombreRapporteur) return;
    setRapporteur(Array(parseInt(nombreRapporteur)).fill({}));
  }, [nombreRapporteur]);

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 box p-4   
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Combien de rapporteur voulez-vous nommer ? </div>
        <input
          type="number"
          className="input w-full border flex-1"
          value={nombreRapporteur}
          min={0}
          max={10}
          placeholder="Combien de rapporteur voulez-vous nommer pour a commission de surveillance de la
          SCOOPS ?"
          onChange={(e) => setNombreRapporteur(e.target.value)}
        />
      </div>
      {rapporteur.map((rappor, index) => (
        <div
          key={index}
          className="intro-y col-span-12 border-b-2 border-theme-1 py-4"
        >
          <strong className="text-lg mb-">
            Indiquez les informations relatives au rapporteur {index + 1}
          </strong>
          <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12 mt-8">
            <div className="intro-y col-span-6 sm:col-span-6">
              <div className="my-2">Civilité </div>
              <select
                type="text"
                className="input w-full border flex-1 field"
                onChange={(e) =>
                  handleInputChange(index, "civilite", e.target.value)
                }
              >
                <option value="monsieur">Monsieur</option>
                <option value="madame">Madame</option>
                <option value="mademoiselle">Mademoiselle</option>
              </select>
            </div>
            <div className="intro-y col-span-6 sm:col-span-6">
              <div className="mb-2">Nom </div>
              <input
                type="text"
                className="input w-full border flex-1"
                placeholder="Nom proprietaire"
                onChange={(e) =>
                  handleInputChange(index, "nom", e.target.value)
                }
              />
            </div>
            <div className="intro-y col-span-6 sm:col-span-6">
              <div className="mb-2">Prénom(s) </div>
              <input
                type="text"
                className="input w-full border flex-1"
                placeholder="Prenoms"
                onChange={(e) =>
                  handleInputChange(index, "prenoms", e.target.value)
                }
              />
            </div>
            <div className="intro-y col-span-6 sm:col-span-6">
              <div className="mb-2">Numéro de téléphone </div>
              <PhoneInput
                country={"ci"}
                // value={e.target.value
                onChange={(e) =>
                  handleInputChange(index, "numero-telephone", e)
                }
                autoFormat={false}
                inputStyle={{
                  padding: "21px1 20px 21px 52px",
                  width: "100%",
                  boxShadow: "0px 3px 5px #00000007",
                  borderColor: "rgba(226, 232, 240, 1)",
                }}
                placeholder="Numéro de téléphone"
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Nationalité du rapporteur</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Nationalité du rapporteur"
                onChange={(e) =>
                  handleInputChange(index,"nationalite", e.target.value)
                }
              />
            </div>
            <div className="intro-y col-span-6 sm:col-span-6">
              <div className="mb-2">Ville</div>
              <input
                type="text"
                className="input w-full border flex-1"
                placeholder="Ville"
                name="Ville"
                onChange={(e) =>
                  handleInputChange(index, "ville", e.target.value)
                }
              />
            </div>
            <div className="intro-y col-span-6 sm:col-span-6">
              <div className="mb-2">Quartier</div>
              <input
                type="text"
                className="input w-full border flex-1"
                placeholder="Quartier"
                onChange={(e) =>
                  handleInputChange(index, "quartier", e.target.value)
                }
              />
            </div>
            <div className="intro-y col-span-6 sm:col-span-6">
              <div className="mb-2">Commune</div>
              <input
                type="text"
                className="input w-full border flex-1"
                placeholder="Commune"
                onChange={(e) =>
                  handleInputChange(index, "commune", e.target.value)
                }
              />
            </div>
            <div className="intro-y col-span-6 sm:col-span-6">
              <div className="mb-2">Numéro de boîte postale</div>
              <input
                type="text"
                className="input w-full border flex-1"
                placeholder="Numéro boite postale"
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "numero-boite-postale",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="intro-y col-span-6 sm:col-span-6">
              <div className="mb-2">Nombre de part detenue</div>
              <input
                type="number"
                min={0}
                className="input w-full border flex-1 field"
                placeholder="Nombre de part detenue"
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "nombre-de-part-detenue",
                    e.target.value
                  )
                }
              />
            </div>
          </div>
        </div>
      ))}

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 
                text-gray-600 dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
