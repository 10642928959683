export function DemandeStepOptionItem(props) {
  // console.log("defaultValue", props.itemValue, props.defaultValue);
  // console.log(
  //   "props.itemValue",
  //   props.itemValue,
  //   props.defaultValue,
  //   props.itemValue === props.defaultValue
  // );
  // console.log('===', props );

  return (
    <div className="intro-y grid grid-cols-12 gap-4">
      {props.itemName === "modification-du-statut" ? (
        <div className="box p-4  mb-3 flex items-center  intro-y col-span-12 ">
          <input
            type="checkbox"
            name={props.itemName ?? ""}
            className="overlay opacity-0"
            value={props.itemValue}
            onChange={(e) => props.handleChoiceChange(e)}
          />
          <div className="">
            <div className="font-medium">{props.itemValue ?? ""}</div>
          </div>
          <div className="checkBoxed m-3">
            <div className="checkBoxed__outer">
              <div className="checkBoxed__inner"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="box p-4  mb-3 flex items-center  intro-y col-span-12 ">
          {props.defaultValue}
          <input
            type="radio"
            checked={
              props.defaultValue === props.itemValue
                ? // ||
                  //   (props.defaultValue?.includes("Je le confie") &&
                  //     props.itemValue.includes("rattachement")) ||
                  //   (props.defaultValue?.includes("Je le confie") &&
                  //     props.itemValue.includes("logo")) ||
                  //   (props.defaultValue?.includes("compta") &&
                  //     props.itemValue.includes("compta"))
                  true
                : //     : Number.isInteger(parseInt(props.defaultValue)) &&
                  //       props.itemValue === "Abidjan"
                  //     ? true
                  //     : !Number.isInteger(parseInt(props.defaultValue)) &&
                  //       props.itemValue.includes("Hors d'Abidjan")
                  //     ? true
                  null
            }
            name={props.itemName ?? ""}
            className="overlay opacity-0"
            value={props.itemValue}
            onChange={(e) => props.handleChoiceChange(e)}
          />
          <div className="ml-2 mr-auto">
            <div className="font-medium">{props.itemValue ?? ""}</div>
          </div>
          <div className="radio-circle">
            <div className="radio-circle__outer-circle">
              <div className="radio-circle__inner-circle"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
