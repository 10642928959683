import * as Icons from 'react-feather';
import placeholderProfileImg from '../assets/images/profile-1.jpg';
import placeholderPostImg from '../assets/images/preview-12.jpg';

export function ArticleItem(props) {
    return (
        <div className="intro-y col-span-12 md:col-span-6 xl:col-span-4 box">
            <div className="flex items-center border-b border-gray-200 
            dark:border-dark-5 px-5 py-4">
                <div className="w-10 h-10 flex-none image-fit">
                    <img alt="Midone Tailwind HTML Admin Template" className="rounded-full" 
                    src={props.article.photo_auteur ?? placeholderProfileImg} />
                </div>
                <div className="ml-3 mr-auto">
                    <a href={props.article.url_article} rel='noreferrer' target='_blank' 
                    className="font-medium">{props.article.nom_auteur}</a> 
                    <div className="flex text-gray-600 truncate text-xs mt-1"> 
                    <a className="text-theme-1 dark:text-theme-10 inline-block truncate" 
                        href={props.article.url_article} rel='noreferrer' target='_blank'>
                            {props.article.category ?? ""}
                        </a> 
                        <span className="mx-1">•</span> {props.article.duree_article ?? ""} 
                    </div>
                </div>
                <div className="dropdown ml-3">
                    <a href={props.article.url_article} rel='noreferrer' target='_blank'
                     className="w-5 h-5 text-gray-500 dark:text-gray-300"> 
                        <Icons.MoreVertical className="w-4 h-4"/> 
                    </a>
                </div>
            </div>
            <div className="p-5">
                <a className="h-40 xxl:h-56 image-fit block" href={props.article.url_article}>
                    <img alt="Midone Tailwind HTML Admin Template" className="rounded-md" 
                    src={props.article.image_article ?? placeholderPostImg} />
                </a>
                <a href={props.article.url_article} rel='noreferrer' target='_blank' 
                className="block font-medium text-theme-1 mt-5">
                    {props.article.titre_article ?? ""}</a> 
                <div className="text-gray-700 dark:text-gray-600 mt-2">
                    {props.article.resume_article ?? ""}
                </div>
            </div>
        </div>
    )
}