import { Api } from "./Api";

const ENPOINTS = {
  Dossier: "dossiers",
  Check: "check",
};

const getAll = (signal) => {
  return Api.get(ENPOINTS.Dossier, signal);
};

const getById = (id, signal) => {
  return Api.get(`${ENPOINTS.Dossier}/${id}`, signal);
};

const checkDossier = (numRef, signal) => {
  return Api.gets(`${ENPOINTS.Check}/${ENPOINTS.Dossier}/${numRef}`, signal);
};

const create = (payload, signal) => {
  return Api.post(ENPOINTS.Dossier, payload, signal);
};

const update = (id, payload, signal) => {
  return Api.put(`${ENPOINTS.Dossier}/${id}`, payload, signal);
};
const destroy = (id, signal) => {
  return Api.erase(`${ENPOINTS.Dossier}/${id}`, signal);
};

export const DossierService = {
  getAll,
  checkDossier,
  getById,
  create,
  update,
  destroy,
};
