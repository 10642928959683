import { useEffect, useState } from "react";
import { Utils } from "../../../utils";

export function PresidentStepMa(props) {
    const formContainer = "presidents-form-container";
    const associes = JSON.parse(props.associes);

    const [nombreGerant] = useState('');
    const [president, setPresident] = useState({});

    const [presidentType, setPresidentType] = useState('');
    Utils.Auth.getUser();

    const handleInputChange = (name, value) => {
        let presidentCopy = {...president}
        
        if (name === "associe-president") {
            presidentCopy = {"associe-president": "oui", ...JSON.parse(value)};
        }else {
            presidentCopy['type'] = 'particulier';
            presidentCopy[name] = value;
        }
        
        setPresident(presidentCopy);
    }
    const handleTypePresidentChange = ( value) => {
        setPresident({});

        const formContainerElem = window.document.querySelector(
            `#${formContainer}-0`)
        Array.from(formContainerElem.querySelectorAll('.field'))
        .forEach(field => field.value = "");

        setPresidentType(value);
    }
    const handleStepSubmit = e => {
        e.preventDefault();
        
        if (!president) return alert("Vous devez remplir le formulaire !");
         props.handleNextClick(e, 'presidents', JSON.stringify([president]));
    }

    useEffect(() => {
        if (!nombreGerant) return;
    }, [nombreGerant])

    
    return (
        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? 'hidden' : ''}`}>
            <small className="intro-y col-span-12"> Indiquez les informations relatives aux président </small>
                <div className="intro-y col-span-12"  id={`${formContainer}-0`}>
                    <div className={`mb-2 `}> Le président fait-il partie des associés ? </div>
                    <div className="flex flex-col sm:flex-row mt-4 mb-2">
                        <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2"> 
                            <input type="radio" className="input border mr-2" 
                            id={`horizontal-radio-oui-0`} 
                            name={`president-0`} value="non" 
                            onChange={e => handleTypePresidentChange("oui")}/> 
                            <label className="cursor-pointer select-none" 
                            htmlFor={`horizontal-radio-oui-0`}>
                                Oui
                            </label> 
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-gray-500 
                        mr-2 mt-2 sm:mt-0"> 
                            <input type="radio" className="input border mr-2" id={`horizontal-non-0`} 
                            name={`president-0`} value="oui" onChange={e => 
                            handleTypePresidentChange("non")}/> 
                            <label className="cursor-pointer select-none" htmlFor={`horizontal-non-0`}>
                                Non
                            </label> 
                        </div>
                    </div>
                    <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                    ${(presidentType !== undefined && 
                    presidentType === 'oui') ? '' : 'hidden'}`}>
                            <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="my-2">Lequel des associés est il le président ?</div>
                            <select type="text" className="input w-full border flex-1 field"
                            onChange={e => handleInputChange( 'associe-president', e.target.value)}>
                                {associes.map((associe, index) => {
                                    return (
                                        <option value={JSON.stringify(associe)} key={index}>
                                            {Utils.Demande.getAssocieName(associe)}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                    ${(presidentType !== undefined && 
                    presidentType === 'non') ? '' : 'hidden'}`}>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="my-2">Civilité</div>
                            <select type="text" className="input w-full border flex-1 field"
                            onChange={e => handleInputChange('civilite', e.target.value)}>
                                <option value="monsieur">Monsieur</option>
                                <option value="madame">Madame</option>
                                <option value="mademoiselle">Mademoiselle</option>
                            </select>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="my-2">Nationalite</div>
                                <input type="text" className="input w-full border flex-1 field"
                                placeholder="Nationalite" onChange={e => handleInputChange(
                                    'nationalite', e.target.value)}/>
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="my-2">Adresse</div>
                                <input type="text" className="input w-full border flex-1 field"
                                placeholder="Adresse" onChange={e => handleInputChange(
                                    'adresse', e.target.value)}/>
                            </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Nom</div>
                            <input type="text" className="input w-full border flex-1 field"
                            placeholder="Nom" onChange={e => handleInputChange(
                                    'nom', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Prenom</div>
                            <input type="text" className="input w-full border flex-1 field"
                            placeholder="Prenom" onChange={e => handleInputChange(
                                    'prenoms', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Numéro de téléphone</div>
                            <input type="text" className="input w-full border flex-1 field"
                            placeholder="Numéro de téléphone" onChange={e => handleInputChange(
                                    'numero-telephone', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Date de naissance</div>
                            <input type="date" className="input w-full border flex-1 field"
                            placeholder="Date de naissance" onChange={e => handleInputChange(
                                    'date-naissance', e.target.value)}/>
                        </div>
                        <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Lieu de naissance</div>
                            <input type="text" className="input w-full border flex-1 field"
                            placeholder="Lieu de naissance" onChange={e => handleInputChange(
                                    'lieu-naissance', e.target.value)}/>
                        </div>
                    </div>
                </div>
        
            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300" onClick={props.handleQuestionnaireBackClick ?? null}>
                    Revenir
                </button>
                <button className="button w-24 justify-center block bg-theme-1 
                text-white ml-2" onClick={handleStepSubmit}>
                    Suivant
                </button>
            </div>
        </div>
    )
}