import { useState } from "react";
import { Services } from "../services";

export const usePaiement = () => {
  const [id, setId] = useState("");
  const [moyen_paiement_id, setMoyen_paiement_id] = useState("");
  const [montant, setMontant] = useState(0);
  const [type_paiement_id, setType_paiement_id] = useState("");
  const [demande_id, setDemande_id] = useState("");
  const [status, setStatus] = useState("");
  const [code_promo_id, setCode_promo_id] = useState("");
  const [recu_paiement_url, setRecu_paiement_url] = useState("");

  const [errors, setErrors] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const getPaiement = (paiementId, signal) => {
    return Services.PaiementService.getById(paiementId, signal).then(
      (response) => {
        fillPaiement(response.paiement);
        setIsDisabled(false);
      }
    );
  };

  const createPaiement = (signal) => {
    const payload = {
      moyen_paiement_id,
      montant,
      type_paiement_id,
      demande_id,
      code_promo_id,
    };

    return Services.PaiementService.create(JSON.stringify(payload), signal);
  };
  const updatePaiement = (paiementId, signal) => {
    const payload = {
      moyen_paiement_id,
      montant,
      type_paiement_id,
      demande_id,
      code_promo_id,
    };

    return Services.PaiementService.update(
      paiementId,
      JSON.stringify(payload),
      signal
    );
  };
  const deletePaiement = (paiementId, signal) => {
    return Services.PaiementService.destroy(paiementId, signal);
  };
  const fillPaiement = (paiement) => {
    setId(paiement.id);
    setMoyen_paiement_id(paiement.moyen_paiement_id ?? "");
    setMontant(paiement.montant ?? 0);
    setType_paiement_id(paiement.type_paiement_id ?? "");
    setDemande_id(paiement.demande_id ?? "");
    setStatus(paiement.status ?? "");
    setCode_promo_id(paiement.code_promo_id ?? "");
    setRecu_paiement_url(paiement.recu_paiement_url ?? "");
  };
  const emptyPaiement = () => {
    setId("");
    setMoyen_paiement_id("");
    setMontant(0);
    setType_paiement_id("");
    setDemande_id("");
    setStatus("");
    setCode_promo_id("");
    setRecu_paiement_url("");
  };

  return {
    id,
    moyen_paiement_id,
    montant,
    type_paiement_id,
    demande_id,
    status,
    code_promo_id,
    recu_paiement_url,

    errors,
    isDisabled,
    setMoyen_paiement_id,
    setMontant,
    setType_paiement_id,
    setDemande_id,
    setStatus,
    setCode_promo_id,
    setRecu_paiement_url,

    setId,
    setErrors,
    setIsDisabled,
    getPaiement,
    createPaiement,
    updatePaiement,
    deletePaiement,
    fillPaiement,
    emptyPaiement,
  };
};
