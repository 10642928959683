import { useMemo, useState } from "react";
import { Components } from "../components";
import { Services } from "../services";

export function NewPasswordView(props) {
    const abortController = useMemo(() => new AbortController(), []);

    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [, setErrorMessages] = useState([]);



    const handlePasswordSubmit = async e => {
        e.preventDefault();
        setIsDisabled(true);

        try {
            const payload = {
                password,
                password_confirmation
            }
            await Services.UserService.updatePassword(
                JSON.stringify(payload), abortController.signal);
            alert('Votre mot de passe à bien été modifié !');
        } catch (error) {
            if ('messages' in error) {
                const messages = await error.messages

                setErrorMessages(messages);
                alert(messages.join(' '));
            }
        }finally {
            setIsDisabled(false);
        }
    }

    return (
        <div className="col-span-12">
        <div className="intro-y flex items-center mt-8">
            <h2 className="text-lg font-medium mr-auto">
                Modifier votre mot de passe
            </h2>
        </div>
        <div className="grid grid-cols-12 gap-6 mt-4">
            <div className="sm:col-span-7 col-span-12">
                <Components.NewPasswordForm isDisabled={isDisabled}
                handleFormSubmit={handlePasswordSubmit} password={password}
                password_confirmation={password_confirmation} setPassword={setPassword}
                setPassword_confirmation={setPassword_confirmation}/>
            </div>
        </div>
        </div>
    )
}