export class CinetPaySerivce {
    constructor() {
        this.cinetPay = window.CinetPay
    }

    setConfig() {
        this.cinetPay.setConfig({
            apikey: process.env.REACT_APP_API_CINETPAY_KEY,
            site_id: process.env.REACT_APP_API_CINETPAY_SITE_ID,
            notify_url: '',
            mode: process.env.REACT_APP_API_CINETPAY_MODE
        })
    }

    getCheckout(payload) {
        return this.cinetPay.getCheckout({
            transaction_id: payload.id,
            amount: payload.amount,
            currency: payload['payment_data'].currency,
            channels: 'ALL',
            description: payload['payment_data'].description,
            //Fournir ces variables pour le paiements par carte bancaire
            customer_name: payload['payment_data'].customer_name ?? "",//Le nom du client
            customer_surname: payload['payment_data'].customer_surname ?? "",//Le prenom du client
            customer_email:  payload['payment_data'].customer_email ?? "",//l'email du client
            customer_phone_number:  payload['payment_data'].customer_phone_number,//l'email du client
            customer_address :  payload['payment_data'].customer_address,//addresse du client
            customer_city:  payload['payment_data'].customer_city,// La ville du client
            customer_country :  payload['payment_data'].customer_country,// le code ISO du pays
            customer_state :  payload['payment_data'].customer_state,// le code ISO l'état
            customer_zip_code :  payload['payment_data'].customer_zip_code, // code postal
    
        })
    }

    makePayment(payload) {
        return new Promise((resolve, reject) => {
            this.setConfig();
            this.getCheckout(payload);
            this.cinetPay.onError(data => reject(data))

            this.cinetPay.waitResponse(data => {
                if (data.status === "REFUSED") {
                   reject(data);
                } else if (data.status === "ACCEPTED") {
                  resolve(data);
                }
            })
        })
        
    }
}