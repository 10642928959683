import { useState } from 'react';
import { Services } from '../services';

export const useCategorieUser = () => {
    const [id, setId] = useState('');
	const [libelle, setLibelle] = useState('');
	

    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getCategorieUser = (categorieuserId, signal) => {        
        return Services.CategorieUserService.getById(categorieuserId, signal)
        .then(response => {
            fillCategorieUser(response.categorieuser);
            setIsDisabled(false);
        });
    }

    const createCategorieUser = signal => {
        const payload = {
            libelle,
		
        };

        return Services.CategorieUserService.create(JSON.stringify(payload), signal);
    }
    const updateCategorieUser = (categorieuserId, signal) => {
        const payload = {
            libelle,
		
        };

        return Services.CategorieUserService.update(categorieuserId, JSON.stringify(payload), signal);
    }
    const deleteCategorieUser = (categorieuserId, signal) => {
        return Services.CategorieUserService.destroy(categorieuserId, signal);
    }
    const fillCategorieUser = (categorieuser) => {
        setId(categorieuser.id);
        setLibelle(categorieuser.libelle ?? '');
		
    }
    const emptyCategorieUser = () => {
        setId('');
        setLibelle('');
		
    }

    return {
        id,
        libelle,
		
        errors,
        isDisabled,
        setLibelle,
		
        setId,
        setErrors,
        setIsDisabled,
        getCategorieUser,
        createCategorieUser,
        updateCategorieUser,
        deleteCategorieUser,
        fillCategorieUser,
        emptyCategorieUser
    };
}