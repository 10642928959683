import { SARL } from "./sarl";
import { SARLU } from "./sarlu";
import { SAS } from "./sas";
import { SASU } from "./sasu";
import { ONG } from "./ong";
import { DOMICILIATION } from "./domiciliation";
import { DEPOT_MARQUE } from "./depot_marque";
import { ASSOCIATION } from "./association";
import { FONDATION } from "./fondation";
import { MODIFICATION_STATUTAIRE } from "./modification_statutaire";
import {SCI} from './sci'
import { INDIVIDUELLE } from "./individuelle";
import { SUIVIE_DEMANDE } from "./suiviDemande";

export const TYPES_DEMANDE_CI = {
    SARL, 
    SARLU, 
    SAS, 
    SASU,
    SCI,
    ONG, 
    DOMICILIATION,
    INDIVIDUELLE,
    DEPOT_MARQUE,
    ASSOCIATION,
    FONDATION,
    MODIFICATION_STATUTAIRE,
    SUIVIE_DEMANDE
}
