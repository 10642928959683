export const SAS = [
  {
    name: "numero-telephone",
    question: "Veuillez saisir votre numéro de téléphone (Whatsapp)",
    description: "",
    type: "simple",
    options: [
      // {value: "Veuillez saisir votre numéro de téléphone (Whatsapp) ", price: ""},
    ],
  },
  {
    name: "email",
    question: "Veuillez saisir votre adresse mail",
    description: "",
    type: "simple",
    options: [
      // {value: "Veuillez saisir votre adresse mail", price: ""},
    ],
  },
  {
    name: "nom",
    question: "Veuillez saisir votre nom",
    description: "",
    type: "simple",
    options: [
      // {value: "Veuillez saisir votre nom", price: ""},
    ],
  },
  {
    name: "prenoms",
    question: "Veuillez saisir votre prénom(s)",
    description: "",
    type: "simple",
    options: [
      // {value: "Veuillez saisir votre prénom(s)", price: ""},
    ],
  },
  {
    name: "verifiEntreprise",
    question: "Assurez-vous que le nom choisi pour votre entreprise est unique et disponible avant de l'enregistrer",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  // {
  //   name: "denomination",
  //   question: "Veuillez entrer le nom de votre entreprise",
  //   description: "",
  //   type: "simple", //"mutiple",
  //   options: [
  //     // {value: "", price: ""}
  //   ],
  // },
  {
    name: "secteur-activite",
    question: "Quel est votre secteur d’activité ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Commerce général", price: "" },
      { value: "Activités Immobilières", price: "" },
      { value: "Activités Informatiques", price: "" },
      { value: "Restauration", price: "" },
      { value: "Agence de voyage", price: "" },
      { value: "Transport VTC", price: "" },
      { value: "Agence de transfert d’argent", price: "" },
      { value: "Cabinet de conseil", price: "" },
      { value: "Cabinet de formation", price: "" },
      { value: "Société Transit", price: "" },
      { value: "Société Mines", price: "" },
      { value: "Centre Médical", price: "" },
      { value: "Aménagement Foncier", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  {
    name: "cabinet-formation",
    question: "Souhaitez vous être accompagné par Legafrik pour obtenir votre agrément FDFP ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Je me fais accompagner pour obtenir l'agrément FDFP (100 000 FCFA)", price: 100_000 },
      { value: "Je ne suis pas Intéressé ", price: "" },
    ],
  },
  
  {
    name: "cabinet-transit",
    question: "Souhaitez-vous d'être accompagné pour l'obtention du code import/export ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Je me fais accompagner pour obtenir le code Import/Export (50 000 FCFA)", price: 50_000 },
      { value: "Je ne suis pas Intéressé ", price: "" },
    ],
  },
  {
    name: "transport",
    question:
      "Souhaitez-vous être accompagné par Legafrik pour vous immatriculer au Registre des transporteurs ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      {
        value:
          "Je me fais accompagner pour mon immatriculation (100 000 Fr CFA)",
        price: 100_000,
      },
      { value: "Je ne suis pas Intéressé ", price: "" },
    ],
  },
  
  {
    name: "nombre-associes",
    question: "Combien d’associés êtes-vous ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "banque",
    question: "Dans quelle banque le compte sera-t-il ouvert ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "entreprise-anterieur",
    question: "Avez-vous déjà créé une entreprise ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Oui", price: "" },
      { value: "Non", price: "" },
    ],
  },
  {
    name: "date-reception-certificat",
    question:
      "Quand voulez-vous recevoir votre certificat d'immatriculation (IDU) ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "1 semaine", price: "" },
      { value: "Je ne sais pas encore", price: "" },
    ],
  },
  {
    name: "localisation-ville",
    question: "Dans quelle ville se situe votre organisation ?",
    description:
      "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Abidjan", price: "" },
      { value: "Hors d'Abidjan", price: "" },
    ],
  },
  {
    name: "localisation-siege",
    question: "Où se situera le siège de votre entreprise ?",
    description:
      "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "A mon domicile", price: "" },
      // { value: "Je domicilie à Legafrik (30 000 FCFA/mois)", price: "" },
      { value: "Je loue un local commercial", price: "" },
      { value: "Je n'ai pas de siège", price: "" },
    ],
  },
  {
    name: "capital-social",
    question: "Quel est le montant de votre capital social ?",
    description:
      "Pas de capital minimum exigé pour une SARL ou une SAS en Côte d'Ivoire",
    type: "mutiple", //"mutiple",
    options: [
      { value: "100 000 FCFA", price: "" },
      { value: "1 000 000 FCFA", price: "" },
      { value: "100 000 000 FCFA", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  // {
  //   name: "offre",
  //   question: "Quelle Offre choisissez-vous ?",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     {
  //       value: "Abidjan: capital inferieur ou egal à 1 million (330 000 FCFA)",
  //       price: 330_000,
  //     },
  //     {
  //       value:
  //         "Interieur du pays: capital inferieur ou egal à 1 millon (399 000 FCFA)",
  //       price: 399_000,
  //     },
  //   ],
  // },
  {
    name: "gestion-domiciliation",
    question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
    description: "Choisissez une formule",
    type: "mutiple", //"mutiple",
    options: [
      { value: "6 mois à 180 000 FCFA (30 000 F/MOIS)", price: 180_000 },
      { value: "12 mois à 300 000 FCFA (25 000 F/MOIS)", price: 300_000 },
      { value: "Je ne suis pas intéressé", price: "" },
    ],
  },
  // {
  //   name: "gestion-dfe",
  //   question:
  //     "Souhaitez-vous que Legafrik s'occupe de votre formalité de rattachement fiscal et d'obtention de Déclaration Fiscale D'existence (DFE) ?",
  //   description:
  //     "Il est obligatoire d'effectuer une formalité de rattachement au centre d’impôt de votre entreprise dans les 10 jours suivant sa création sous peine d'une amende de 100 000 FCFA.",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     {
  //       value: "Je confie mon rattachement fiscal à Legafrik",
  //       price: 79_000,
  //     },
  //     // {
  //     //   value: "Je confie à Légafrik sans rattachement  DFE",
  //     //   price: 30_000,
  //     // },
  //     { value: "Je le gère moi-même", price: "" },
  //   ],
  // },
  {
    name: "gestion-graphique",
    question:
      "Avez-vous pensé à donner une image de marque à votre entreprise avec un logo et une charte graphique faits par des professionnels ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Je confie mon logo et ma charte graphique à Legafrik (100 000 FCFA)", price: 100_000 },
      { value: "Je ne suis pas intéressé", price: "" },
    ],
  },
  {
    name: "gestion-comptable",
    question: "Souhaitez-vous bénéficier de notre solution d'accompagnement comptable ?",
    description: "",
    type: "mutiple", //"mutiple",
    options : [
        {value: "Je confie ma compta à Legafrik (85 000/mois)", price: 255_000},
        {value: "J'ai déjà un comptable", price: ""}
    ]
},
  {
    name: "gestion-facture-normalisees",
    question:
      "Souhaitez-vous que Legafrik s'occupe des formalités d'obtention de vos factures normalisées ?",
    description:
      "Toutes entreprises exerçant en en Côte d'Ivoire a l'obligation de délivrer des factures normalisées sous peine d'amende.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Lot de 50 factures normalisées à 75 000 FCFA", price: 75_000 },
      {
        value: "Lot de 100 factures normalisées à 130 000 FCFA",
        price: 130_000,
      },
      {
        value: "Lot de 150 factures normalisées à 165 000 FCFA",
        price: 165_000,
      },
      {
        value: "Lot de 200 factures normalisées à 260 000 FCFA",
        price: 260_000,
      },
      { value: "Je ne suis pas intéressé", price: "" },
    ],
  },
  {
    name: "solution-pay",
    question:
      "Souhaitez-vous bénéficier de notre solution d'accompagnement RH ?",
    description:
      "(Automatisation de la paie; rédaction contrat de travail; édition bulletin de paie; Déclaration rapide des salariés; gestion des absences et congés) ",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Je confie ma gestion RH à Legafrik (85 000 Fr/Mois)", price: 255_000 },
      { value: "J'ai déja un RH", price: "" },
    ],
  },
];
