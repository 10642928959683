export const SARLU = [
    {
        name: "numero-telephone",
        question: "Veuillez saisir votre numéro de téléphone (Whatsapp)",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre numéro de téléphone (Whatsapp) ", price: ""},
           
        ]
    },
    {
        name: "email",
        question: "Veuillez saisir votre adresse mail",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre adresse mail", price: ""},
           
        ]
    },
    {
        name: "nom",
        question: "Veuillez saisir votre nom",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre nom", price: ""},
           
        ]
    },
    {
        name: "prenoms",
        question: "Veuillez saisir votre prénom(s)",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre prénom(s)", price: ""},
           
        ]
    },
    {
        name: "denomination",
        question: "A quel premier nom  avez-vous pensé pour votre entreprise ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "denomination",
        question: "A quel deuxième nom avez-vous pensé pour votre entreprise ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "denomination",
        question: "A quel troisième nom  avez-vous pensé pour votre entreprise ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "secteur-activite",
        question: "Quel est votre secteur d’activité ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Commerce général", price: ""},
            {value: "Activités Immobilières", price: ""},
            {value: "Activités Informatiques", price: ""},
            {value: "Restauration", price: ""},
            {value: "Agence de voyage", price: ""},
            {value: "Transport VTC", price: ""},
            {value: "Agence de transfert d’argent", price: ""},
            {value: "Cabinet de conseil", price: ""},
            {value: "Autres", price: ""},
        ]
    },
    // {
    //     name: "nombre-associes",
    //     question: "Combien d’associés êtes-vous ?",
    //     description: "",
    //     type: "simple", //"mutiple",
    //     options : [
    //         // {value: "", price: ""}
    //     ]
    // },
    {
        name: "banque",
        question: "Dans quelle banque le compte sera-t-il ouvert ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    
    {
        name: "date-reception-certificat",
        question: "Quand voulez-vous recevoir votre Registre du commerce ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "1 semaine", price: ""},
            {value: "Je ne sais pas encore", price: ""}
        ]
    },
    {
        name: "localisation-siege",
        question: "Où se situera le siège de votre entreprise ?",
        description: "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Je domicilie à Legafrik", price: ""},
            {value: "Je loue un local commercial", price: ""},
            {value: "Je n'ai pas de siège", price: ""}
        ]
    },
    {
        name: "capital-social",
        question: "Quel est le montant de votre capital social ?",
        description: "Pas de capital minimum exigé pour une SARLU ou une SAS au Maroc",
        type: "mutiple", //"mutiple",
        options : [
            {value: "10 000 MAD", price: ""},
            {value: "50 000 MAD", price: ""},
            {value: "100 000 MAD", price: ""},
            {value: "200 000 MAD", price: ""},
            {value: "Autres", price: ""},
        ]
    },
    {
        name: "offre",
        question: "Quelle Offre choisissez-vous ?",
        type: "mutiple", //"mutiple",
        options : [
            {value: `START BUSINESS 3660 MAD (Certificat negatif,
                Statuts enregistrés ,
                Immatriculation au registre de commerce,
                Inscription à la taxe professionnelle,
                Identifiant fiscal,
                Affiliation à la CNSS,
                Publicité au journal d’annonces légales,
                Tampons)`, price: 3_660},
                {value: `CONFORT : 5260 MAD Certificat negatif,
                Domiciliation 6 Mois,
                Statuts et contrat de domiciliation  enregistrés ,
                Immatriculation au registre de commerce,
                Inscription à la taxe professionnelle,
                Identifiant fiscal,
                Affiliation à la CNSS,
                Accompagnement à l’ouverture du compte bancaire,
                Publicité au journal d’annonces légales,
                Tampons,
                Adhésion au service de télédéclaration de la DGI,
                Adhésion au service de damancom`, price: 5_260},
                {value: `CONFORT PLUS : 6110 MAD Certificat negatif,
                Domiciliation 12 Mois,
                Statuts et contrat de domiciliation enregistrés ,
                Immatriculation au registre de commerce,
                Inscription à la taxe professionnelle,
                Identifiant fiscal,
                Affiliation à la CNSS,
                Accompagnement à l’ouverture du compte bancaire,
                Publicité au journal d’annonces légales,
                Tampons,
                Adhésion au service de télédéclaration de la DGI,
                Adhésion au service de damancom`, price: 6_110},
        ]
    }  
]