import { useCallback, useEffect, useMemo, useState } from "react";
import { Components } from ".";
import { Services } from "../services";
import { Utils } from "../utils";

export function VerificationDisponibiliteNomStep(props) {
    const abortController = useMemo(() => new AbortController(), []);

    const [documents, setDocuments] = useState([]);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [names, setNames] = useState([]);
    const [statusLibelle, setStatusLibelle] = useState('');

    const handleNameChange = (e, index) => {
        const namesCopy = [...names];
        namesCopy[index] = e.target.value;

        setNames([...namesCopy]);
    };

    const handleNamesSubmit = async e => {
        props.useDemande.setIsDisabled(true);

        try {
            const champs_etape_traitements = Utils.String.parseJson(
                props.useDemande?.champs_etape_traitements);
            const verificationNom = {
                ...champs_etape_traitements,
                "choix-noms-entreprise": names
            }
            const payload = {
                champs_etape_traitements: JSON.stringify(verificationNom)
            };

            const {demande} = await Services.DemandeService.updateChampsEtapeTraitement(
                props.useDemande.id, JSON.stringify(payload));

            setIsModalOpen(false);
            props.useDemande.fillDemande(demande);
        } catch (error) {
            if ('messages' in error) return;
                //Toaster
        }finally {
            props.useDemande.setIsDisabled(false);
        }
    }

    const initialize = useCallback(async () => {
        try {
            const {documents} = await Services.DemandeService.getDocuments(
                props.useDemande.id, abortController.signal, 
                props.etapeTraitement.id);
            setDocuments(documents);
        } catch (error) {
            if ('messages' in error) return;
                //Toaster
        } finally {
            setIsDisabled(false);
            props.useDemande.setIsDisabled(false);
        }

      },
      [abortController],
    )
    
    useEffect(() => {
        initialize();
    }, [initialize])
    return (
        <>
            <Components.FormalitesStep etapeTraitement={props.etapeTraitement}
            useDemande={props.useDemande} setStatusLibelle={setStatusLibelle}/>
            {statusLibelle.toLowerCase().includes('refus') ? 
                <div className="py-1 mt-2" onClick={e => setIsModalOpen(true)} role="button">
                    <u>Ciquer ici pour choisir d'autres noms</u>
                </div>
            : null}
            <div className="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-10 col-span-12" 
            style={{animationName: "none", opacity: 1, transform: "none"}}>
                <Components.DocumentList documents={documents} 
                isDisabled={isDisabled}/>
            </div>
             {isModalOpen ? 
                <Components.Modal handleModalClose={() => setIsModalOpen(false)}
                title={"Choix des noms"} isControlVisible={true} 
                handleModalValidate={handleNamesSubmit} isDisabled={props.useDemande.isDisabled}>
                    {Array(3).fill(0).map((val, index) => {
                        return (
                            <div className='intro-y col-span-12 mb-3' key={index}>
                                <label htmlFor={`name-${index}`}>Choix N° {index + 1}</label>
                                <input className='input w-full border flex-1' type='text' id={`name-${index}`} 
                                name={`name-${index}`} placeholder="Entrez votre choix" 
                                value={names[index] ?? ''} disabled={props.useDemande.isDisabled} 
                                onChange={ e => handleNameChange(e,index) ?? null}/>
                            </div>
                        )
                    })}
                </Components.Modal>
            : null}
        </>
    )
}