import Moment from "moment";
import { Utils } from "../../../utils";
import { useState } from "react";

export function RecapStepOng(props) {
  // console.log('props',props)
  const [isDisabled, setIsDisabled] = useState(false);

  const { parseJson, isJsonParseable } = Utils.String;
  const demande = JSON.parse(props?.dataGlobal?.useDemande?.champs_demande);

  const sigle = props?.dataGlobal?.champs_questionnaireObject["sigle"]
    ? props?.dataGlobal?.champs_questionnaireObject["sigle"]
    : null;

  // const activites = props?.dataGlobal?.champs_questionnaireObject["activites"]
  //   ? props?.dataGlobal?.champs_questionnaireObject["activites"]
  //   : null;

  // const associes = props?.dataGlobal?.champs_questionnaireObject?.associes
  //   ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.associes)
  //   : [];

  // const adresse1 = props?.dataGlobal?.champs_questionnaireObject?.adresse1
  //   ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.adresse1)
  //   : "{}";

  const adresse = props?.dataGlobal?.champs_questionnaireObject?.adresse
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.adresse)
    : null;

  const president = props?.dataGlobal?.champs_questionnaireObject?.president
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.president)
    : "{}";

  const secretaire = props?.dataGlobal?.champs_questionnaireObject?.secretaire
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.secretaire)
    : "{}";

  const tresorier = props?.dataGlobal?.champs_questionnaireObject?.tresorier
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.tresorier)
    : "{}";

  const bureau = props?.dataGlobal?.champs_questionnaireObject?.bureau
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.bureau)
    : "{}";

  const commissaire = props?.dataGlobal?.champs_questionnaireObject?.commissaire
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.commissaire)
    : "{}";

  const dure = props?.dataGlobal?.champs_questionnaireObject?.dure
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.dure)
    : "{}";

  const montant = props?.dataGlobal?.champs_questionnaireObject?.montant
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.montant)
    : "{}";

  // const gerants = props?.dataGlobal?.champs_questionnaireObject?.gerants
  //   ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.gerants)
  //   : [];

  const handleStepSubmit = (e) => {
    setIsDisabled(true);
    e.preventDefault();

    props.handleSubmit(e, "", "");
  };

  // const renderArray = (array) => {
  //   if (props?.dataGlobal != null) {
  //     let itemData = array;
  //     return itemData;
  //   }
  // };

  const renderData = (items) => {
    // console.log("items", items);
    if (
      props?.dataGlobal?.champs_questionnaireObject != null &&
      items != null
    ) {
      let itemData = items;
      const rendDatas = Object.keys(itemData).map((item_field, index) => {
        let attribute = `${
          item_field[0].toUpperCase() + String(item_field).substring(1)
        }`;
        attribute = attribute.replace(/_/g, " ");
        attribute = attribute.replace(/-/g, " ");
        if (
          attribute !== "Type" &&
          attribute !== "Fait partie des associes" &&
          itemData[item_field] != null &&
          itemData[item_field] !== ""
        ) {
          if (attribute === "Date naissance") {
            return (
              <div className="flex-item" key={item_field.toString()}>
                <span className="flex-item-text">Date de naissance:</span>
                <span className="flex-item-description">
                  {Moment(
                    itemData[item_field].toString().replace(/_/g, " ")
                  ).format("DD/MM/YYYY")}
                </span>
              </div>
            );
          } else if (attribute === "Apport") {
            return (
              <div className="flex-item" key={item_field.toString()}>
                <span className="flex-item-text">Apport:</span>
                <span className="flex-item-description">
                  {typeof itemData[item_field] !== "object"
                    ? itemData[item_field].toString().replace(/_/g, " ")
                    : itemData[item_field].toString()}
                  FCFA
                </span>
              </div>
            );
          } else if (attribute === "Capital sociale") {
            return (
              <div className="flex-item" key={item_field.toString()}>
                <span className="flex-item-text">Capital social:</span>
                <span className="flex-item-description">
                  {typeof itemData[item_field] !== "object"
                    ? itemData[item_field].toString().replace(/_/g, " ")
                    : itemData[item_field].toString()}
                  FCFA
                </span>
              </div>
            );
          } else if (items["dure-president"]) {
            return (
              <div className="flex-content-item" key={item_field.toString()}>
                <div className="flex-content-item-header">
                  <h3>Durée de Mandat </h3>
                </div>

                <div className="flex-content-item-body-fex">
                  <div className="flex-content-item-body-wrapper">
                    <div className="item-body-wrapper-header">
                      <h6 className="item-body-wrapper-header-title">
                        Durée de Mandat :
                      </h6>
                    </div>
                    <div className="flex-content-item-body-wrapper-mapper">
                      {itemData[item_field] === 9999
                        ? "Illimité"
                        : itemData[item_field]}
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="flex-item" key={item_field.toString()}>
                <span className="flex-item-text">
                  {item_field === "denomination"
                    ? "Nom de l'entreprise"
                    : item_field === "secteur-activite"
                    ? "Secteur d'activité"
                    : item_field === "nationalite"
                    ? "Nationalité"
                    : item_field === "date-naissance"
                    ? "Date de naissance"
                    : item_field === "date-reception-certificat"
                    ? "Date de réception du registre de commerce"
                    : item_field === "numero-telephone"
                    ? "Numéro de téléphone"
                    : item_field === "numero-telephone"
                    ? "Numéro de téléphone"
                    : item_field === "date-reception-certificat"
                    ? "Date de réception du certificat"
                    : item_field === "gestion-facture-normalisees"
                    ? "Gestion de factures normalisées"
                    : item_field === "gestion-dfe"
                    ? "Gestion de DFE"
                    : item_field === "prenoms"
                    ? "Prénom(s)"
                    : item_field === "type-nationalite"
                    ? "Type de nationalité"
                    : item_field === "type-mandat"
                    ? "Type de mandat"
                    : item_field === "ville-naissance"
                    ? "Ville de naissance"
                    : item_field === "ville_habitation"
                    ? "Ville d'habitation"
                    : item_field === "prenoms"
                    ? "Prénom(s)"
                    : item_field === "nom-pere"
                    ? "Nom du père"
                    : item_field === "nom-vice-president"
                    ? "Nom du Vice président"
                    : item_field === "nationalite-vice-president"
                    ? "Nationalité du Vice président"
                    : item_field === "numero-vice-president"
                    ? "Numéro du Vice président"
                    : item_field === "nom-secretaire-adjoint"
                    ? "Nom du Sécrétaire Adjoint"
                    : item_field === "nationalite-secretaire-adjoint"
                    ? "Nationalité du Sécrétaire Adjoint"
                    : item_field === "numero-secretaire-adjoint"
                    ? "Numéro du Sécrétaire Adjoint"
                    : item_field === "nom-tresorier-adjoint"
                    ? "Nom du Trésorier Adjoint"
                    : item_field === "nationalite-tresorier-adjoint"
                    ? "Nationalité du Trésorier Adjoint"
                    : item_field === "numero-tresorier-adjoint"
                    ? "Numéro du Trésorier Adjoint"
                    : item_field === "nom-mere"
                    ? "Nom de la mère"
                    : item_field === "nombre-associes"
                    ? "Nombre d'associés"
                    : item_field === "entreprise-anterieur"
                    ? "Avez-vous une entreprise"
                    : item_field === "date-reception-registre-de-commerce"
                    ? "Date de réception du registre de commerce"
                    : item_field === "localisation-siege"
                    ? "Siège de l'entreprise"
                    : item_field === "capital-social"
                    ? "Capital social"
                    : item_field === "code-postale"
                    ? "Numéro de boîte postale"
                    : item_field === "nom-prenoms-proprietaire"
                    ? "Nom et prénom(s) du propriétaire"
                    : item_field === "numero-telephone"
                    ? "Numéro de téléphone"
                    : item_field === "lot"
                    ? "Numéro de lot"
                    : item_field === "ilot"
                    ? "Numéro d'ilot"
                    : item_field === "section"
                    ? "Numéro de section"
                    : item_field === "parcelle"
                    ? "Numéro de parcelle"
                    : item_field === "numero-piece-identite"
                    ? "Numéro de pièce d'identité"
                    : item_field === "regime-matrimonial"
                    ? "Régime matrimonial"
                    : item_field === "denomination"
                    ? "Nom de la société"
                    : item_field === "forme-sociale"
                    ? "Forme sociale"
                    : item_field === "capital-social"
                    ? "Capital social"
                    : item_field === "prenoms-representant"
                    ? "Prénom(s) du représentant"
                    : item_field === "associe-gerant"
                    ? "Associé gérant"
                    : item_field === "fait-partie-des-associes"
                    ? "Fait partie des associés"
                    : item_field === "numero-telephone"
                    ? "Numéro de téléphone"
                    : item_field === "regime-matrimonial"
                    ? "Régime matrimonial"
                    : item_field === "denomination"
                    ? "Nom de la société"
                    : item_field === "capital-social"
                    ? "Capital social"
                    : item_field === "prenoms-representant"
                    ? "Prénom(s) du représentant"
                    : item_field === "associe-gerant"
                    ? "Associé gérant"
                    : item_field === "fait-partie-des-associes"
                    ? "Fait partie des associés"
                    : item_field === "numero-telephone"
                    ? "Numéro de téléphone"
                    : item_field === "lieu-naissance"
                    ? "Lieu de naissance"
                    : item_field === "pays-naissance"
                    ? "Pays de naissance"
                    : item_field === "situation-matrimoniale"
                    ? "Situation matrimoniale"
                    : item_field === "regime-matrimonial"
                    ? "telephoneRégime matrimonial"
                    : item_field === "denomination"
                    ? "Nom de la société"
                    : item_field === "denomination"
                    ? "Nom de la société"
                    : item_field === "capital-social"
                    ? "Capital social"
                    : item_field === "siege-sociale"
                    ? "Siège social"
                    : item_field === "numero-sociale"
                    ? "Numéro RCCM"
                    : item_field === "ville-immatriculation"
                    ? "Ville d'immatriculation"
                    : item_field === "nom-representant"
                    ? "Nom du représentant"
                    : item_field === "prenoms-representant"
                    ? "Prénom(s) du représentant"
                    : item_field === "associe-gerant"
                    ? "Associé gérant"
                    : item_field === "fait-partie-des-associes"
                    ? " Président Associé"
                    : item_field === "fait-partie-des-associes"
                    ? "Fait partie des associés"
                    : item_field === "numero-telephone"
                    ? "Numéro de téléphone"
                    : attribute}
                  :
                </span>
                <span className="flex-item-description">
                  {typeof itemData[item_field] !== "object"
                    ? itemData[item_field].toString().replace(/_/g, " ")
                    : itemData[item_field].toString()}
                </span>
              </div>
            );
          }
        }
      });
      return rendDatas;
    }
  };

  return (
    <div
      className={`grid grid-cols-1 col-span-12 ${
        props.step !== props.index ? "hidden" : ""
      } `}
    >
      <div className="flex-content-column">
        <div className="flex-content-item">
          <div className="flex-content-item-header">
            <h3>Demande</h3>
          </div>
          <div className="flex-content-item-body">{renderData(demande)}</div>
        </div>
        {props?.dataGlobal?.champs_questionnaireObject && sigle !== null ? (
          <div className="flex-content-item">
            <div className="flex-content-item-header">
              <h3>Sigle</h3>
            </div>
            <div className="flex-content-item-body">
              <div className="flex-item">
                <span className="flex-item-description">
                  {isJsonParseable(sigle) ? parseJson(sigle)?.sigle : sigle}
                </span>
              </div>{" "}
            </div>
          </div>
        ) : null}
        ​
        {props?.dataGlobal?.champs_questionnaireObject && president !== null ? (
          <div className="flex-content-item">
            <div className="flex-content-item-header">
              <h3>Président</h3>
            </div>
            ​
            <div className="flex-content-item-body-fex">
              <div className="flex-content-item-body-wrapper">
                <div className="item-body-wrapper-header">
                  <h6 className="item-body-wrapper-header-title">
                    Président :
                  </h6>
                </div>
                <div className="flex-content-item-body-wrapper-mapper">
                  {renderData(president)}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {props?.dataGlobal?.champs_questionnaireObject &&
        secretaire !== null ? (
          <div className="flex-content-item">
            <div className="flex-content-item-header">
              <h3>Sécrétaire Général</h3>
            </div>
            ​
            <div className="flex-content-item-body-fex">
              <div className="flex-content-item-body-wrapper">
                <div className="item-body-wrapper-header">
                  <h6 className="item-body-wrapper-header-title">
                    Sécrétaire :
                  </h6>
                </div>
                <div className="flex-content-item-body-wrapper-mapper">
                  {renderData(secretaire)}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {props?.dataGlobal?.champs_questionnaireObject && tresorier !== null ? (
          <div className="flex-content-item">
            <div className="flex-content-item-header">
              <h3>Trésorier</h3>
            </div>
            ​
            <div className="flex-content-item-body-fex">
              <div className="flex-content-item-body-wrapper">
                <div className="item-body-wrapper-header">
                  <h6 className="item-body-wrapper-header-title">
                    Trésorier :
                  </h6>
                </div>
                <div className="flex-content-item-body-wrapper-mapper">
                  {renderData(tresorier)}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {props?.dataGlobal?.champs_questionnaireObject && bureau !== null ? (
          <div className="flex-content-item">
            <div className="flex-content-item-header">
              <h3>Bureau</h3>
            </div>
            ​
            <div className="flex-content-item-body-fex">
              <div className="flex-content-item-body-wrapper">
                <div className="item-body-wrapper-header">
                  <h6 className="item-body-wrapper-header-title">Bureau :</h6>
                </div>
                <div className="flex-content-item-body-wrapper-mapper">
                  {renderData(bureau)}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {props?.dataGlobal?.champs_questionnaireObject &&
        commissaire !== null ? (
          <div className="flex-content-item">
            <div className="flex-content-item-header">
              <h3>Commissaire</h3>
            </div>
            ​
            <div className="flex-content-item-body-fex">
              <div className="flex-content-item-body-wrapper">
                <div className="item-body-wrapper-header">
                  <h6 className="item-body-wrapper-header-title">
                    Commissaire :
                  </h6>
                </div>
                <div className="flex-content-item-body-wrapper-mapper">
                  {renderData(commissaire)}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {props?.dataGlobal?.champs_questionnaireObject && dure !== null ? (
          <div>{renderData(dure)}</div>
        ) : null}
        {props?.dataGlobal?.champs_questionnaireObject && montant !== null ? (
          <div className="flex-content-item">
            <div className="flex-content-item-header">
              <h3>Montant</h3>
            </div>
            ​
            <div className="flex-content-item-body-fex">
              <div className="flex-content-item-body-wrapper">
                <div className="item-body-wrapper-header">
                  <h6 className="item-body-wrapper-header-title">Montant :</h6>
                </div>
                <div className="flex-content-item-body-wrapper-mapper">
                  {renderData(montant)}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        ​
        {props?.dataGlobal?.champs_questionnaireObject && adresse !== null ? (
          <div className="flex-content-item">
            <div className="flex-content-item-header">
              <h3>Adresse</h3>
            </div>
            <div className="flex-content-item-body">{renderData(adresse)}</div>
          </div>
        ) : (
          <div className="flex-content-item">
            <div className="flex-content-item-header">
              <h3>Adresse</h3>
            </div>
            <div className="flex-content-item-body">
              <div className="flex-item">
                <span className="flex-item-description">
                  Domicilié à Legafrik
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="intro-y col-span-12 flex items-center justify-center
    sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
    dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
    text-white ml-2"
          onClick={handleStepSubmit}
          disabled={isDisabled}
        >
          {isDisabled ? " Chargement en cours..." : "Suivant"}
        </button>
      </div>
    </div>
  );
}
