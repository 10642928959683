import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

function Spinner() {
  return (
    <div style={{ textAlign: "center", margin: "10px auto" }}>
      <BeatLoader color="#1C3FAA" size={30} />
    </div>
  );
}

export default Spinner;
