import { Fragment, useCallback, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Hooks } from "../hooks";
import { Components } from "../components";
import { Utils } from "../utils";

export function DemandeDetailView(props) {
  const abortController = useMemo(() => new AbortController(), []);

  const { id } = useParams();
  const useDemande = Hooks.useDemande();
  const { getDemande } = useDemande;

  const initialize = useCallback(async () => {
    try {
      await getDemande(id, abortController.signal);
    } catch (error) {
      if ("messages " in error) return;
      //Toaster
    }
  }, [abortController, id]);

  useEffect(() => {
    initialize();
  }, [initialize]);
  return (
    <div className="col-span-12">
      <div className="intro-y flex items-center justify-space-between mt-8">
        <h2 className="text-lg font-medium mr-auto">Details de la commande</h2>
        {!useDemande.montant_paye ? (
          <Link
            to={`/commandes/${id}/modifier`}
            className="button block 
                    bg-theme-12 text-white ml-2"
          >
            Modifer la commande
          </Link>
        ) : null}
      </div>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-4">
          <Components.InfoBox title="Informations générales">
            <Components.InfoItem
              attribute="Pays"
              value={useDemande.pays?.libelle}
            />
            <Components.InfoItem
              attribute="Type de la demande"
              value={useDemande.type_demande?.libelle}
            />
            <Components.InfoItem
              attribute="Numéro de demande"
              value={useDemande.numero_demande}
            />
            <Components.InfoItem
              attribute="Date de la demande"
              value={Utils.Date.styleDate(useDemande.created_at)}
            />
            <Components.InfoItem
              attribute="Etat de la demande"
              value={useDemande.etat_demande?.libelle}
            />
          </Components.InfoBox>
        </div>
        <div className="col-span-12 lg:col-span-8">
          <Components.InfoBox title="Informations de la demande">
            {useDemande.champs_demande ? (
              <>
                {Object.keys(JSON.parse(useDemande.champs_demande)).map(
                  (champ_demande, index) => {
                    let attribute = `${
                      champ_demande[0].toUpperCase() +
                      String(champ_demande).substring(1)
                    }`;
                    attribute = attribute.replace(/-/g, " ");

                    return (
                      <Fragment key={index}>
                        <Components.InfoItem
                          attribute={Utils.String.__(attribute)}
                          value={
                            JSON.parse(useDemande.champs_demande)[champ_demande]
                          }
                        />
                      </Fragment>
                    );
                  }
                )}
              </>
            ) : null}
          </Components.InfoBox>
        </div>
      </div>
    </div>
  );
}
