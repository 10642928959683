import { useState } from "react";

export function PouvoirSarlStep(props) {
    const formContainer = "pouvoir-form-container";

    const [pouvoir, setPouvoir] = useState({});

    const handleTypePouvoirChange = (name,value) => {
        const pouvoirCopy = 
         {...pouvoir};
              
        pouvoirCopy[name] = value;

        const formContainerElem = window.document.querySelector(
            `#${formContainer}`
          );
          Array.from(formContainerElem.querySelectorAll(".field")).forEach(
            (field) => (field.value = "")
          );

        setPouvoir(pouvoirCopy);
    }

    
    const handleInputChange = (name, value) => {
        const pouvoirCopy = 
         {...pouvoir};
              
        pouvoirCopy[name] = value;

        setPouvoir(pouvoirCopy);
    }
   
    const handleStepSubmit = e => {
        e.preventDefault();

        props.handleNextClick(e, 'pouvoir', JSON.stringify(pouvoir));
    }

   
    return (
        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? 'hidden' : ''}`}>
            <h4 className="intro-y col-span-12 text-lg leading-none">
            </h4>
            
          
                    <div className="intro-y col-span-12" id={`${formContainer}`}>
                        <small>Indiquez les informations relatives aux pouvoirs des gérants</small>
                        <div className="mb-2">Souhaitez-vous limiter les pouvoirs des gérants ?</div>
                        <div className="flex flex-col sm:flex-row mt-4 mb-2">
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2"> 
                                <input type="radio" className="input border mr-2" 
                                id={`pouvoir-oui`} 
                                name={`pouvoir`} value="non" 
                                onChange={e => handleTypePouvoirChange('gerant-pouvoir-type', "oui")}/> 
                                <label className="cursor-pointer select-none" 
                                htmlFor={`pouvoir-oui`}>
                                    Oui
                                </label> 
                            </div>
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0"> 
                                <input type="radio" className="input border mr-2" 
                                id={`pouvoir-non`} 
                                name={`pouvoir`} value="oui" 
                                onChange={e => handleTypePouvoirChange('gerant-pouvoir-type', "non")}/> 
                                <label className="cursor-pointer select-none" 
                                htmlFor={`pouvoir-non`}>
                                    Non
                                </label> 
                            </div>
                        </div>
                       
                        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${(pouvoir['gerant-pouvoir-type'] !== undefined && 
                        pouvoir['gerant-pouvoir-type'] === 'oui') ? '' : 'hidden'}`}>
                             <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Indiquer les limitations de pouvoir</div>
                                <input type="text" className="input w-full border flex-1 field"
                                placeholder="Nom" onChange={e => handleInputChange(
                                 'gerant-pouvoir', e.target.value)}/>
                            </div>
                            
                             
                            
                            
                            
                        </div>
                    </div>
                
            
            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300" onClick={props.handleQuestionnaireBackClick ?? null}>
                    Revenir
                </button>
                <button className="button w-24 justify-center block bg-theme-1 
                text-white ml-2" onClick={handleStepSubmit}>
                    Suivant
                </button>
            </div>
        </div>
    )
}