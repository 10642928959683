import { useEffect, useRef } from "react"

export function Dropdown(props) {
    const dropdown = useRef();

    useEffect(() => {
        const dropdownBox = dropdown.current.querySelector('.dropdown-box');
        const dropdownButton = dropdown.current.querySelector('.dropdown-toggle');

        const toggleDropDownVisility = e => {
            dropdownBox.classList.toggle('show');
        }

        dropdownButton.addEventListener('click', toggleDropDownVisility);

        return () => {
            dropdownButton.removeEventListener('click', toggleDropDownVisility);
        }
    }, []);

    return (
        <div className="dropdown" ref={dropdown} style={{position: "relative"}}>
            {props.children}
        </div>
    )
    
}