export const SAS = [
  {
    name: "numero-telephone",
    question: "Veuillez saisir votre numéro de téléphone (Whatsapp)",
    description: "",
    type:  "simple",
    options : [
        // {value: "Veuillez saisir votre numéro de téléphone (Whatsapp) ", price: ""},
       
    ]
},
{
    name: "email",
    question: "Veuillez saisir votre adresse mail",
    description: "",
    type:  "simple",
    options : [
        // {value: "Veuillez saisir votre adresse mail", price: ""},
       
    ]
},
{
    name: "nom",
    question: "Veuillez saisir votre nom",
    description: "",
    type:  "simple",
    options : [
        // {value: "Veuillez saisir votre nom", price: ""},
       
    ]
},
{
    name: "prenoms",
    question: "Veuillez saisir votre prénom(s)",
    description: "",
    type:  "simple",
    options : [
        // {value: "Veuillez saisir votre prénom(s)", price: ""},
       
    ]
},
  {
    name: "denomination",
    question: "Veuillez entrer le nom de votre entreprise",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "secteur-activite",
    question: "Quel est votre secteur d’activité ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Commerce général", price: "" },
      { value: "Activités Immobilières", price: "" },
      { value: "Activités Informatiques", price: "" },
      { value: "Restauration", price: "" },
      { value: "Agence de voyage", price: "" },
      { value: "Transport VTC", price: "" },
      { value: "Agence de transfert d’argent", price: "" },
      { value: "Cabinet de conseil", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  {
    name: "nombre-associes",
    question: "Combien d’associés êtes-vous ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "banque",
    question: "Dans quelle banque le compte sera-t-il ouvert ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "entreprise-anterieur",
    question: "Avez-vous déjà créé une entreprise ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Oui", price: "" },
      { value: "Non", price: "" },
    ],
  },
  {
    name: "date-reception-registre-du-commerce",
    question: "Quand voulez-vous recevoir votre Registre du commerce ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "1 semaine", price: "" },
      { value: "Je ne sais pas encore", price: "" },
    ],
  },
  {
    name: "localisation-siege",
    question: "Où se situera le siège de votre entreprise ?",
    description:
      "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "A mon domicile", price: "" },
      { value: "Je domicilie à Legafrik (25 000 FCFA/mois)", price: "" },
      { value: "Je loue un local commercial", price: "" },
      { value: "Je n'ai pas de siège", price: "" },
    ],
  },
  {
    name: "capital-social",
    question: "Quel est le montant de votre capital social ?",
    description:
      "SARL Capital minimun 100.000 FCFA et pas de capital minimum pour la SAS Sénégal",
    type: "mutiple", //"mutiple",
    options: [
      { value: "100 000 FCFA", price: "" },
      { value: "1 000 000 FCFA", price: "" },
      { value: "100 000 000 FCFA", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  {
    name: "gestion-dfe",
    question: "Souhaitez-vous que Legafrik s'occupe de votre formalité d'ouverture de compte aux impôts ?",
    description: "Il est obligatoire d'effectuer une formalité de rattachement au centre d’impôt de votre entreprise dans les 10 jours suivant sa création sous peine d'une amende de 100 000 FCFA.",
    type: "mutiple", //"mutiple",
    options : [
        {value: "Je le confie à Légafrik (50 000 FCFA)", price: 50_000},
        {value: "Je le gère moi-même", price: 50_000},
    ]
},
  {
    name: "offre",
    question: "Quelle Offre choisissez-vous ?",
    type: "mutiple", //"mutiple",
    options: [
      {
        value: "Capital inférieur ou égal à 500.000 FCFA (349 000 FCFA)",
        price: 349_000,
      },
      { value: "Capital supperieur à 500.000 FCFA (Sur devis)", price: 349_000 },
    ],
  },
  {
    name: "gestion-domiciliation",
    question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
    description: "Choisissez une formule",
    type: "mutiple", //"mutiple",
    options: [
      { value: "6 mois à 180 000 FCFA(30 000 F/MOIS)", price: 180_000 },
      { value: "12 mois à 300 000 FCFA (25 000 F/MOIS)", price: 300_000 },
      { value: "Je ne suis pas intéressé", price: "" },
    ],
  },
];
