import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Services } from "../services";
import { Components } from ".";

export function RedactionDocumentStep(props) {
  const {
    useDemande: { champs_etape_traitements },
  } = props;
  const { etapeTraitement } = props;

  const champ_etape = champs_etape_traitements
    ? JSON.parse(champs_etape_traitements)
    : "";

  const abortController = useMemo(() => new AbortController(), []);

  const [, setEtat_etape_traitements] = useState([]);
  const [, setStatut_etat_traitement] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [statusLibelle, setstatusLibelle] = useState("Veuillez patienter...");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const initialize = useCallback(async () => {
    try {
      const { documents } = await Services.DemandeService.getDocuments(
        props.useDemande.id,
        abortController.signal,
        props.etapeTraitement.id
      );

      setDocuments(documents);
      setIsDisabled(false);

      const { etat_etape_traitements } =
        await Services.EtapeTraitementService.getAllEtatTraitementsById(
          props.etapeTraitement.id,
          abortController.signal
        );

      const { status_etat_traitement } =
        await Services.DemandeService.getStatutEtatTraitementById(
          props.useDemande.id,
          props.etapeTraitement.id,
          abortController.signal
        );

      const statusLibelle = etat_etape_traitements.find(
        (eet) => eet.id === status_etat_traitement.etat_etape_traitement_id
      )?.libelle;

      setEtat_etape_traitements(etat_etape_traitements);
      setStatut_etat_traitement(status_etat_traitement);
      setstatusLibelle(statusLibelle ?? "En cours");
    } catch (error) {
      setIsDisabled(false);

      if ("messages" in error) {
        const message = await error.messages;
        if (message?.includes("status etat traitement not found"))
          setstatusLibelle("En cours");
      }
    }
  }, [abortController]);
  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <div
      className="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-10"
      style={{ animationName: "none", opacity: 1, transform: "none" }}
    >
      <div className="col-span-12 text-lg">
        Status : {statusLibelle} <br />{" "}
        {etapeTraitement.libelle === "Procédures à l’amiables"
          ? champ_etape["commentaire-amiables"]
          : etapeTraitement.libelle === "Procédures contentieuses"
          ? champ_etape["commentaire-contentieuses"]
          : etapeTraitement.libelle === "Fin de la procédure"
          ? champ_etape["commentaire-fin-procedures"]
          : null}
        <hr />
      </div>

      <Components.DocumentList documents={documents} isDisabled={isDisabled} />
      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button justify-center block bg-theme-12"
          disabled={props.useDemande.isDisabled || documents.length < 1}
          onClick={(e) => setIsModalOpen(true)}
        >
          J'ai une observation
        </button>
        {statusLibelle.toLowerCase().includes("termin") ? (
          <button
            className="button justify-center block bg-theme-1 
                    text-white ml-2"
            onClick={props.handleSubmit}
            disabled={props.useDemande.isDisabled}
          >
            {props.useDemande.isDisabled
              ? "Chargement..."
              : "Prêt pour la signature"}
          </button>
        ) : null}
      </div>
      {isModalOpen ? (
        <Components.Modal
          handleModalClose={(e) => setIsModalOpen(false)}
          title={"Ajouter mes observations"}
          isControlVisible={true}
          handleModalValidate={(e) => setIsModalOpen(false)}
        >
          {documents.map((document, index) => {
            return (
              <Fragment key={index}>
                <Components.ObservationForm
                  type_document={document.type_document}
                  useDemande={props.useDemande}
                />
              </Fragment>
            );
          })}
        </Components.Modal>
      ) : null}
    </div>
  );
}
