import { useState } from "react";
import { Components } from "../../../..";
import { Utils } from "../../../../../utils";

export function RecapStepSarlV2(props) {
  // console.log("pros", props);
  const { isJsonParseable, parseJson } = Utils.String;
  const { getCurrencyFromOffre, getAssocieName } = Utils.Demande;

  const [modalTitle, ] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [valueName, setValueName] = useState("");
  const [valueArrayIndex, setValueArrayIndex] = useState("");
  const [isDisabled, ] = useState(false);

  const { denomination, banque, offre } = props.champsDemandeObject;
  // console.log("champsDemandeObject", props);
  const capital = props.champsDemandeObject["capital-social"]
    ?.toString()
    .replace(/ /gi, "");
  const { associes, sigle, activites, gerants } =
    props.questionnaireObject;

  let associesArray = isJsonParseable(associes) ? parseJson(associes) : [];
  const gerantsArray = isJsonParseable(gerants) ? parseJson(gerants) : [];
  // if (props.demande === "individuelle") {
  //   // console.log("props.demande", props.demande);
  // }

  // const adresseObject = {
  //   ...parseJson(props.questionnaireObject?.adresse1),
  //   ...parseJson(adresse),
  // };
  const apportCapital = associesArray.map((item) => {
    return {
      associe: getAssocieName(item),
      montant: `${item.apport ?? parseInt(capital)} ${getCurrencyFromOffre(
        offre
      )}`,
      pourcentage: Math.round(
        ((item.apport ?? parseInt(capital)) * 100) / parseInt(capital ?? 0)
      ),
    };
  });
  //console.log("associesArray",associesArray);
  const infosGenerales = {
    denomination: denomination.toString(),
    activites: activites,
    sigle: isJsonParseable(sigle) ? parseJson(sigle)?.sigle : sigle,
    banque: banque,
  };
  const handleEditClick = (e, name, index = "") => {
    e.preventDefault();

    setIsEditModalOpen(true);
    setValueName(name);
    setValueArrayIndex(index);
  };
  const handleStepSubmit = (e) => {
  
    // setIsDisabled(true);
    e.preventDefault();
    props.handleSubmit(e);
    // setIsDisabled(false);
  };

  return (
    <div
      className={`grid grid-cols-1 col-span-12 box p-4
        ${props.step !== props.index ? "hidden" : ""} `}
    >
      <div className="flex-content-column col-span-12">
        {props.demande !== "individuelle" ? (
          <>
            {" "}
            <Components.RecaptItem
              title={
                props.demande === "individuelle"
                  ? "Informations relatives au déclarant"
                  : "Informations relatives aux Associés"
              }
              name={"associes"}
              value={associesArray}
              hasEditIcon={true}
              handleEditClick={handleEditClick}
              demande={props.demande}
            />
            <Components.InfoBox
              title={"Apport au capital"}
              demande={props.demande}
            >
              <div className="font-medium text-base col-span-12 mb-1">
                Montant du capital:
                {parseInt(capital) ?? ""} {getCurrencyFromOffre(offre)}
              </div>
              <div className="grid grid-cols-12 gap-6 overflow-auto lg:overflow-visible">
                <Components.Table
                  tableAttributes={{
                    associe: {},
                    montant: {},
                    pourcentage: {},
                  }}
                  tableData={apportCapital}
                  tableActions={[]}
                  controllers={{
                    handleEditClick: null,
                    handleReadClick: null,
                    handleDeleteClick: null,
                  }}
                />
              </div>
            </Components.InfoBox>
          </>
        ) : null}

        <Components.RecaptItem
          title={"Informations générales"}
          name={"infos"}
          value={infosGenerales}
          hasEditIcon2={true}
          handleEditClick={handleEditClick}
        />

        <Components.RecaptItem
          title={"Adresse de la société"}
          name={"adresse"}
          // value={
          //   props.champsDemandeObject["localisation-siege"].includes("Legafrik")
          //     ? "Domicilié à Legafrik"
          //     : adresseObject
          // }
          // hasEditIcon2={
          //   !props.champsDemandeObject["localisation-siege"].includes(
          //     "Legafrik"
          //   )
          // }
          handleEditClick={handleEditClick}
        />

        {/* {props.demande === "individuelle" ? ( */}
        <Components.RecaptItem
          title={
            props.demande === "individuelle"
              ? "Informations relatives au déclarant"
              : "Informations relatives aux gérance"
          }
          name={"gerants"}
          value={gerantsArray}
          hasEditIcon={true}
          handleEditClick={handleEditClick}
          demande={props.demande}
        />
        {/* ) : null} */}
      </div>
      <div
        className="intro-y col-span-12 flex items-center justify-center
            sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 
                text-gray-600 dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
          // disabled={isDisabled}
        >
          {isDisabled ? " Chargement en cours..." : "Suivant"}
        </button>
      </div>
      {isEditModalOpen ? (
        <Components.Modal
          handleModalClose={() => setIsEditModalOpen(false)}
          handleModalValidate={() => setIsEditModalOpen(false)}
          title={modalTitle ?? "Modification du questionnaire"}
        >
          <Components.RecapEditForm
            handleSubmit={props.handleNextClick}
            value={props.questionnaireObject[valueName]}
            valueName={valueName}
            valueArrayIndex={valueArrayIndex}
            setIsEditModalOpen={setIsEditModalOpen}
            activites={activites}
            sigle={sigle}
            currency={getCurrencyFromOffre(offre)}
          />
        </Components.Modal>
      ) : null}
    </div>
  );
}
