import { useState, useEffect } from "react"
import Alert from 'react-bootstrap/Alert';
import { Utils } from "../../../utils";

export function AssociesUniMa(props) {
    const formContainer = "associes-form-container";
    const nombreAssocies = (props.nombreAssocies && 
        Number.isInteger(parseInt(props.nombreAssocies))) ? 
        parseInt(props.nombreAssocies) : 0;
    const associeTypes = (props.associeTypes )
    ? JSON.parse(props.associeTypes): [];
        
    const [associes, setAssocies] = useState({});
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);

    const handleInputChange = ( name, value) => {
        // setMatrimonial(value);
            // matrimonial=value;
            if(name ==="situation-matrimoniale"){

                if(value==='celibataire'){
                    setChecked1(true);
                    setChecked2(false);

                }else{
                    setChecked2(true);
                    setChecked1(false);
                }

            }
        const associesCopy = {...associes}
         associesCopy[name] = value;

         setAssocies(associesCopy);
    }
    const handleStepSubmit = e => {
      e.preventDefault();
      associes.type=associeTypes[0].type;
      const associesCopy = {...associes}   
      var test=Utils.Auth.getUser();

        
        var index = test['username'].indexOf(" ");  // Gets the first index where a space occours

        

        if(associeTypes[0].type ==='moi-meme'){

            associesCopy["nom"] = test['username'].substr(0, index); // Gets the first part
            associesCopy["prenoms"] = test['username'].substr(index + 1); 
            associesCopy["numero-telephone"] = test["numero-telephone"];
                
        }
        

        setAssocies(associesCopy);
        props.handleNextClick(e, 'associes', JSON.stringify([associesCopy]));
  }

    useEffect(() => {
        if (!nombreAssocies) return;
    }, [nombreAssocies])

    return (
        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? 'hidden' : ''}`}>
            <h4 className="intro-y col-span-12">
                Qui sont les associés de la société ?
               
            </h4>
            
            <small className="intro-y col-span-12"> Indiquez les informations relatives à l'associé</small>
            
                
            <div className="intro-y col-span-12"  id={`${formContainer}-0`}>
            <Alert  variant='primary'>

            <div className={`mb-2 ${( 
                associeTypes[0]?.type === 'moi-meme') ? '' : 'hidden'}`}>Je suis moi même l'ASSOCIÉ   </div>
                <div className={`mb-2 ${( 
                associeTypes[0]?.type === 'particulier') ? '' : 'hidden'}`}>ASSOCIÉ est un particulier  </div>
                <div className={`mb-2 ${( 
                associeTypes[0]?.type === 'societe') ? '' : 'hidden'}`}>ASSOCIÉ est une entreprise  </div>
                
            </Alert>
              
               
                <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                ${(associeTypes[0]?.type !== undefined && (associeTypes[0]?.type === 'moi-meme' || 
                associeTypes[0]?.type === 'particulier')) ? '' : 'hidden'}`}>
                   <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="my-2">Civilité</div>
                        <select type="text" className="input w-full border flex-1 field"
                        onChange={e => handleInputChange('civilite', e.target.value)}>
                             <option value="monsieur">Monsieur</option>
                             <option value="madame">Madame</option>
                             <option value="mademoiselle">Mademoiselle</option>
                        </select>
                   </div>
                    {associeTypes[0]?.type === 'particulier' ? 
                        <>
                                <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Nom</div>
                                <input type="text" className="input w-full border flex-1 field"
                                placeholder="Nom" onChange={e => handleInputChange(
                                     'nom', e.target.value)}/>
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Prénom(s)</div>
                                <input type="text" className="input w-full border flex-1 field"
                                placeholder="Prénom(s)" onChange={e => handleInputChange(
                                     'prenoms', e.target.value)}/>
                            </div>
                        </>
                    : null}
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Date de naissance</div>
                        <input type="date" className="input w-full border flex-1 field"
                        placeholder="Date de naissance" onChange={e => handleInputChange(
                             'date-naissance', e.target.value)}/>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Ville de naissance</div>
                        <input type="text" className="input w-full border flex-1 field"
                        placeholder="Ville de naissance" onChange={e => handleInputChange(
                            'lieu-naissance', e.target.value)}/>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Pays de naissance</div>
                        <input type="text" className="input w-full border flex-1 field"
                        placeholder="Pays de naissance" onChange={e => handleInputChange(
                             'pays-naissance', e.target.value)}/>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Nationalité</div>
                        <input type="text" className="input w-full border flex-1 field"
                        placeholder="Nationalité" onChange={e => handleInputChange(
                             'nationalite', e.target.value)}/>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Adresse</div>
                        <input type="text" className="input w-full border flex-1 field"
                        placeholder="Adresse" onChange={e => handleInputChange(
                             'adresse', e.target.value)}/>
                    </div>

                    <div className="col-span-12">Situation matrimoniale</div>
                    <div className="flex flex-col sm:flex-row mb-2 col-span-12">
                        <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2"> 
                            <input type="radio" className="input border mr-2 field" 
                            id={`horizontal-radio-celibataire-0`} 
                            name={`associe-0-situation-matrimoniale`} checked={checked1} value="celibataire" 
                            onChange={e => handleInputChange( "situation-matrimoniale", "celibataire")}/> 
                            <label className="cursor-pointer select-none" 
                            htmlFor={`horizontal-radio-celibataire-0`}>
                                Célibataire
                            </label> 
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0"> 
                            <input type="radio" className="input border mr-2 field"
                            id={`horizontal-radio-marie-0`} 
                            name={`associe-0-situation-matrimoniale`} checked={checked2} value="marie" 
                            onChange={e => handleInputChange( "situation-matrimoniale", "marie")}/> 
                            <label className="cursor-pointer select-none" 
                            htmlFor={`horizontal-radio-marie-0`}>
                                Marié-e
                            </label> 
                        </div>
                    </div>
                    <div className={`intro-y col-span-12 ${(associes['situation-matrimoniale'] !== undefined && 
                    associes['situation-matrimoniale'] === 'marie') ? '' : 'hidden'}`}>
                        <div className="col-span-12  mb-4">Régime matrimonial</div>
                        <div className="flex flex-col sm:flex-row mb-2 col-span-12">
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2"> 
                                <input type="radio" className="input border mr-2 field"
                                id={`horizontal-radio-communaute-de-bien-0`} 
                                name={`associe-0-regime-matrimonial`} value="communaute-de-bien" 
                                onChange={e => handleInputChange( "regime-matrimonial", "Communaute de bien")}/> 
                                <label className="cursor-pointer select-none" 
                                htmlFor={`horizontal-radio-communaute-de-bien-0`}>
                                    Communauté de bien
                                </label> 
                            </div>
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0"> 
                                <input type="radio" className="input border mr-2 field"
                                id={`horizontal-radio-separation-de-bien-0`} 
                                name={`associe-0-regime-matrimonial`} value="separation-de-bien" 
                                onChange={e => handleInputChange( "regime-matrimonial", "Séparation de bien")}/> 
                                <label className="cursor-pointer select-none" 
                                htmlFor={`horizontal-radio-separation-de-bien-0`}>
                                    Séparation de bien
                                </label> 
                            </div>
                        </div>
                    </div>
                    {/* <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Combien {associes === 'moi-meme' ? 
                        "apportez-vous" : "cet associé apporte"} à la société ?</div>
                        <input type="text" className="input w-full border flex-1 field"
                        placeholder="Combien apportez-vous à la société en FCFA?" onChange={e => handleInputChange(
                             'apport', e.target.value)}/>
                    </div> */}
                </div>
                <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                ${(associeTypes[0]?.type !== undefined && associeTypes[0]?.type === 'societe') ? '' : 'hidden'}`}>
                        <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Quel est le nom de la société ?</div>
                        <input type="text" className="input w-full border flex-1 field"
                        placeholder="Quel est le nom de la société ?" onChange={e => handleInputChange(
                             'denomination', e.target.value)}/>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Quelle est sa forme sociale ?</div>
                        <input type="text" className="input w-full border flex-1 field"
                        placeholder="Quelle est sa forme sociale ?" onChange={e => handleInputChange(
                             'forme-sociale', e.target.value)}/>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Montant du capital social</div>
                        <input type="number" className="input w-full border flex-1 field"
                        placeholder="Montant du capital social" onChange={e => handleInputChange(
                             'capital-social', e.target.value)}/>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Adresse du siège social</div>
                        <input type="text" className="input w-full border flex-1 field"
                        placeholder="Adresse du siège social" onChange={e => handleInputChange(
                             'siege-sociale', e.target.value)}/>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Numéro RCCM</div>
                        <input type="text" className="input w-full border flex-1 field"
                        placeholder="Numéro RCCM" onChange={e => handleInputChange(
                             'numero-sociale', e.target.value)}/>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Ville d’immatriculation</div>
                        <input type="text" className="input w-full border flex-1 field"
                        placeholder="Ville d’immatriculation" onChange={e => handleInputChange(
                             'ville-immatriculation', e.target.value)}/>
                    </div>
                    <div className="col-span-12">Représentant Légal de la société associéé</div>
                   <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="my-2">Civilité</div>
                        <select type="text" className="input w-full border flex-1 field"
                        onChange={e => handleInputChange('civilite-representant', e.target.value)}>
                             <option value="monsieur">Monsieur</option>
                             <option value="madame">Madame</option>
                             <option value="mademoiselle">Mademoiselle</option>
                        </select>
                   </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Nom</div>
                        <input type="text" className="input w-full border flex-1 field"
                        placeholder="Nom" onChange={e => handleInputChange(
                             'nom-representant', e.target.value)}/>
                    </div>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Prénom</div>
                        <input type="text" className="input w-full border flex-1 field"
                        placeholder="Prénom" onChange={e => handleInputChange(
                             'prenoms-representant', e.target.value)}/>
                    </div>
                </div>
            </div>
                
            
            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300" onClick={props.handleQuestionnaireBackClick ?? null}>
                    Revenir
                </button>
                <button className="button w-24 justify-center block bg-theme-1 
                text-white ml-2" onClick={handleStepSubmit}>
                    Suivant
                </button>
            </div>
        </div>
    )
}