import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import * as Icons from "react-feather";

export function AdresseStep(props) {
  const [adresse, setAddresse] = useState({});

  const handleInputChange = (name, value) => {
    const adresseCopy = { ...adresse };
    adresseCopy[name] = value;

    setAddresse(adresseCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();
    props.handleNextClick(e, "adresse", JSON.stringify(adresse));
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12  ">
        <strong>Quelle est l’adresse de votre société ?</strong>
      </div>
      <div className="intro-y col-span-12  ">
        <Alert variant="danger">
          <Alert.Heading>
            {" "}
            <Icons.Info className="w-4 h-4 " />
          </Alert.Heading>
          <Alert.Link href="#">L’adresse de votre société </Alert.Link> est tout
          simplement le siège social. C’est l’adresse qui figurera sur tous les
          documents officiels de votre société. Si vous n’avez pas encore de
          bureaux, vous pouvez domicilier l’entreprise chez l’un des associés.
        </Alert>

        <small>
          {" "}
          Indiquez les informations relatives à l'adresse géographique de la
          société{" "}
        </small>
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Adresse du siège social</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Adresse du siège social"
          onChange={(e) => handleInputChange("adresse", e.target.value)}
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Code postal</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Code postal"
          onChange={(e) => handleInputChange("code-postal", e.target.value)}
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Ville</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Ville"
          onChange={(e) => handleInputChange("ville", e.target.value)}
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Commune</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Commune"
          onChange={(e) => handleInputChange("commune", e.target.value)}
        />
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
