// import { Components } from '..'

export function DocumentForm(props) {
    return (
        <form className='form' disabled={props.isDisabled ?? false}
        onSubmit={props.handleFormSubmit ?? null}>
            <div className='row'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='type_document_id'>Type_document_id</label>
                        <select className='select2 form-control' id='type_document_id' name='type_document_id' value={props.useDocument.type_document_id ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useDocument.setType_document_id(e.target.value) ?? null} required>
                            {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
                        </select>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='etat_document_id'>Etat_document_id</label>
                        <select className='select2 form-control' id='etat_document_id' name='etat_document_id' value={props.useDocument.etat_document_id ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useDocument.setEtat_document_id(e.target.value) ?? null} required>
                            {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
                        </select>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='demande_id'>Demande_id</label>
                        <select className='select2 form-control' id='demande_id' name='demande_id' value={props.useDocument.demande_id ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useDocument.setDemande_id(e.target.value) ?? null} required>
                            {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
                        </select>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='champs_document'>Champs_document</label>
                        <input className='form-control' type='text' id='champs_document' name='champs_document' 
                        placeholder='Champs_document' value={props.useDocument.champs_document ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useDocument.setChamps_document(e.target.value) ?? null} required/>
                    </div>
                </div>
				<div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='document_url'>Document_url</label>
                        <input className='form-control' type='text' id='document_url' name='document_url' 
                        placeholder='Document_url' value={props.useDocument.document_url ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useDocument.setDocument_url(e.target.value) ?? null} required/>
                    </div>
                </div>
				
                <div className='col-12 text-right'>
                    <button disabled={props.isDisabled ?? false} type='submit' className='btn btn-primary' 
                    onClick={props.handleFormSubmit}>
                        <span>Enregistrer</span>
                    </button>
                </div>
            </div>
        </form>
    )
}