import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Components } from "../components";
import { Services } from "../services";
import { Utils } from "../utils";
import { ArticleItem } from "../components/ArticleItem";

export function DashboardView(props) {
  const abortController = useMemo(() => new AbortController(), []);

  const [pays, setPays] = useState([]);
  const [, setType_demandes] = useState([]);
  const [articles, setArticles] = useState([]);
  const [currentPays, setCurrentPays] = useState({});
  const [creationDemandes, setCreationDemandes] = useState([]);
  const [autresDemarchesDemandes, setAutresDemarchesDemandes] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [totalDemande, setTotalDemande] = useState(0);

  const setTypeDemandesByType = (type_demandes) => {
    const creationDemandeCopy = [];
    const autreDemandeCopy = [];

    type_demandes.forEach((type_demande) => {
      if (
        !type_demande.libelle.toLowerCase().startsWith("cré") 
      ) {
        // console.log("type_demande", type_demande);
        autreDemandeCopy.push(type_demande);
      } else {
        creationDemandeCopy.push(type_demande);
      }
    });

    setCreationDemandes(creationDemandeCopy);
    setAutresDemarchesDemandes(autreDemandeCopy);
    setType_demandes(type_demandes);
  };

  const handlePaysClick = (e, pays) => {
    e.preventDefault();

    if (isDisabled || currentPays.id === pays.id) return;

    setCreationDemandes([]);
    setAutresDemarchesDemandes([]);
    setCurrentPays(pays);
  };

  const loadTypeDemandeByPays = useCallback(
    async (currentPays) => {
      setIsDisabled(true);

      try {
        const { type_demandes } = await Services.PaysService.getTypeDemandes(
          currentPays.id,
          abortController.signal
        );
        // console.log('type_demandes',type_demandes);
        
        setTypeDemandesByType(type_demandes);
      } catch (error) {
        if ("messages" in error) return;
        //Toat
      } finally {
        setIsDisabled(false);
      }
    },
    [abortController]
  );

  const initialize = useCallback(async () => {
    try {
      const { pays } = await Services.PaysService.getAll(
        abortController.signal
      );
      const { total } = await Services.UserService.getDemandes(
        abortController.signal
      );
      setTotalDemande(total);
      setPays(pays);

      if (pays.length < 1) return;

      setCurrentPays(pays[0]);

      const { articles } = await Services.ArticleService.getAll(
        abortController.signal
      );
      setArticles(articles);
    } catch (error) {
      if ("messages" in error) return;
      //Toat
    } finally {
      setIsDisabled(false);
    }
  }, [abortController]);

  useEffect(() => {
    if (!currentPays?.id) return;
    loadTypeDemandeByPays(currentPays);
  }, [currentPays?.id, loadTypeDemandeByPays]);
  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <>
      <div className="intro-y box p-5 bg-theme-1 text-white col-span-12 py-10">
        <div className="flex flex-row flex-wrap justify-center">
          <strong className="text-lg md:text-xl text-bold w-full text-center mb-6">
            Bonjour {Utils.Auth.getUser()?.username} 👋
          </strong>
          <input
            className="input w-full border flex-1 bg-white text-black"
            style={{ maxWidth: "400px" }}
            type="search"
            id="search"
            name="search"
            placeholder="Recherche..."
          />
        </div>
      </div>
      <div className="col-span-12 mt-8">
        <div className="intro-y flex items-center h-10">
          <h2 className="text-lg font-medium mr-5 mb-8">Pays de création</h2>
        </div>
        <div className="intro-y  ">
          <h2 className="text-xl font-medium mr-5 ">
            Pays de création <br />{" "}
          </h2>
          <i>
            <h6 className="intro-y mb-8">
              Vous avez
              <span
                className="p-1 mx-1 rounded-full 
                    text-white bg-theme-6"
              >
                {totalDemande}
              </span>
              demande{totalDemande > 0 ? "s" : ""}
            </h6>
          </i>
        </div>
        <div className="col-span-12 mb-10 grid grid-cols-12 gap-5">
          {pays.map((paysItem, index) => {
            const isCurrentPays = currentPays && currentPays.id === paysItem.id;
            return (
              <div
                className={`col-span-6 sm:col-span-2 xxl:col-span-2 box p-5
                            ${
                              isCurrentPays
                                ? "bg-theme-1 dark:bg-theme-1"
                                : "cursor-pointer zoom-in"
                            }`}
                key={index}
                onClick={(e) => handlePaysClick(e, paysItem)}
              >
                <div
                  className={`font-medium text-base truncate ${
                    isCurrentPays ? "text-white" : ""
                  }`}
                >
                  {paysItem.libelle}
                </div>
                <div
                  className={`${
                    isCurrentPays
                      ? "text-theme-25 dark:text-gray-400"
                      : "text-gray-600"
                  }`}
                >
                  {paysItem.code === "MR" ? "MA" : paysItem.code}
                </div>
              </div>
            );
          })}
        </div>
        <div className="intro-y flex items-center h-10">
          <h2 className="text-lg font-medium mr-5 mb-10">Créations</h2>
        </div>
        <div className="grid grid-cols-12 gap-0 md:gap-8 mb-10">
          {creationDemandes.map((type_demande, index) => {
            return (
              <Fragment key={index}>
                <Components.AnalyticsCard
                  name={type_demande.libelle}
                  linkTo={`/commandes/creer/${type_demande.id}`}
                />
              </Fragment>
            );
          })}
        </div>
        <div className="intro-y flex items-center h-10 mt-8">
          <h2 className="text-lg font-medium mr-5">
            Autres démarches juridiques
          </h2>
        </div>
        <div className="grid grid-cols-12 gap-0 md:gap-8 mb-10">
          {autresDemarchesDemandes.map((type_demande, index) => {
            return (
              <Fragment key={index}>
                <Components.AnalyticsCard
                  name={type_demande.libelle}
                  linkTo={`/commandes/autres/${type_demande.id}`}
                />
              </Fragment>
            );
          })}
        </div>
        {/* <div className="grid grid-cols-12 gap-8 mt-5">
                    <Components.AnalyticsCard name="Domiciliation" linkTo=''/>
                    <Components.AnalyticsCard name="Dépôt de marques" linkTo=''/>
                </div> */}
      </div>
      <div className="intro-y grid grid-cols-12 gap-4 mt-5 col-span-12 bg-theme-1 p-4 box">
        {articles.map((article, index) => {
          return (
            <Fragment key={index}>
              <ArticleItem article={article} />
            </Fragment>
          );
        })}
      </div>
    </>
  );
}
