import '../assets/css/app.css';
import { useEffect } from 'react';
import { Components } from '../components';
import { Utils } from '../utils';
import { useNavigate } from 'react-router-dom';

export function MainLayout(props){
    const navigate = useNavigate();
    useEffect(() => {
        if (!navigate) return;
        if (!Utils.Auth.isLoggedIn()) navigate(('/connexion'));
    })

    if (!Utils.Auth.isLoggedIn()) return null;
    return (
        <>
            <Components.MobileMenu />
            <div className="flex">
                <Components.SideNav />
                <div className="content">
                   <Components.TopBar />
                    <div className="grid grid-cols-12 gap-6">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}