import { useState } from 'react';
import { Services } from '../services';

export const useEtapeTraitement = () => {
    const [id, setId] = useState('');
	const [libelle, setLibelle] = useState('');
	

    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getEtapeTraitement = (etapetraitementId, signal) => {        
        return Services.EtapeTraitementService.getById(etapetraitementId, signal)
        .then(response => {
            fillEtapeTraitement(response.etapetraitement);
            setIsDisabled(false);
        });
    }

    const createEtapeTraitement = signal => {
        const payload = {
            libelle,
		
        };

        return Services.EtapeTraitementService.create(JSON.stringify(payload), signal);
    }
    const updateEtapeTraitement = (etapetraitementId, signal) => {
        const payload = {
            libelle,
		
        };

        return Services.EtapeTraitementService.update(etapetraitementId, JSON.stringify(payload), signal);
    }
    const deleteEtapeTraitement = (etapetraitementId, signal) => {
        return Services.EtapeTraitementService.destroy(etapetraitementId, signal);
    }
    const fillEtapeTraitement = (etapetraitement) => {
        setId(etapetraitement.id);
        setLibelle(etapetraitement.libelle ?? '');
		
    }
    const emptyEtapeTraitement = () => {
        setId('');
        setLibelle('');
		
    }

    return {
        id,
        libelle,
		
        errors,
        isDisabled,
        setLibelle,
		
        setId,
        setErrors,
        setIsDisabled,
        getEtapeTraitement,
        createEtapeTraitement,
        updateEtapeTraitement,
        deleteEtapeTraitement,
        fillEtapeTraitement,
        emptyEtapeTraitement
    };
}