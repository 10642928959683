import { useState } from 'react';
import { Services } from '../services';

export const useDossier = () => {
    const [id, setId] = useState('');
	const [libelle, setLibelle] = useState('');
	const [tarif, setTarif] = useState('');
	

    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getDossier = (dossierId, signal) => {        
        return Services.DossierService.getById(dossierId, signal)
        .then(response => {
            fillDossier(response.dossier);
            setIsDisabled(false);
        });
    }

    const createDossier = signal => {
        const payload = {
            libelle,
		tarif,
		
        };

        return Services.DossierService.create(JSON.stringify(payload), signal);
    }
    const updateDossier = (dossierId, signal) => {
        const payload = {
            libelle,
		tarif,
		
        };

        return Services.DossierService.update(dossierId, JSON.stringify(payload), signal);
    }
    const deleteDossier = (dossierId, signal) => {
        return Services.DossierService.destroy(dossierId, signal);
    }
    const fillDossier = (dossier) => {
        setId(dossier.id);
        setLibelle(dossier.libelle ?? '');
		setTarif(dossier.tarif ?? '');
		
    }
    const emptyDossier = () => {
        setId('');
        setLibelle('');
		setTarif('');
		
    }

    return {
        id,
        libelle,
		tarif,
		
        errors,
        isDisabled,
        setLibelle,
		setTarif,
		
        setId,
        setErrors,
        setIsDisabled,
        getDossier,
        createDossier,
        updateDossier,
        deleteDossier,
        fillDossier,
        emptyDossier
    };
}