import { Api } from "./Api";

const ENPOINTS = {
  Pays: "pays",
};

const getAll = (signal) => {
  return Api.get(ENPOINTS.Pays, signal);
};

const getAlls = (signal) => {
  return Api.gets(ENPOINTS.Pays, signal);
};

const getTypeDemandes = (id, signal) => {
  return Api.get(`${ENPOINTS.Pays}/${id}/type-demandes`, signal);
};

const getTypeDemandess = (id, signal) => {
  return Api.gets(`${ENPOINTS.Pays}/${id}/type-demandes`, signal);
};

const getById = (id, signal) => {
  return Api.get(`${ENPOINTS.Pays}/${id}`, signal);
};

const create = (payload, signal) => {
  return Api.post(ENPOINTS.Pays, payload, signal);
};
const creates = (payload, signal) => {
  return Api.posts(ENPOINTS.Pays, payload, signal);
};

const update = (id, payload, signal) => {
  return Api.put(`${ENPOINTS.Pays}/${id}`, payload, signal);
};
const destroy = (id, signal) => {
  return Api.erase(`${ENPOINTS.Pays}/${id}`, signal);
};

export const PaysService = {
  getAlls,
  getAll,
  getTypeDemandes,
  getTypeDemandess,
  getById,
  create,
  creates,
  update,
  destroy,
};
