import { useState } from "react"
import { Components } from ".";
import { Utils } from "../utils";

// handle newclick on submit 
export function RecapEditForm(props) {
    const {isJsonParseable, parseJson} = Utils.String;

    const [value, setValue] = useState(isJsonParseable(props.value) ? 
    parseJson(props.value) : '');
    const [activites, setActivites] = useState(props.activites);
    const [sigle, setsigle] = useState(isJsonParseable(props.sigle) ? 
    parseJson(props.sigle) : '');

    const handleSubmit = e => {
        e.preventDefault();

        if (props.valueName === "infos") {
            props.handleSubmit(e, 'infos', {activites, sigle}, true);
        }else {
            props.handleSubmit(e, props.valueName, JSON.stringify(value), true);
        }

        props.setIsEditModalOpen(false);
    }
    return (
        <>
            {props.valueName === 'associes' ? 
                <Components.AssociesEdit index={props.valueArrayIndex} 
                associes={value} setValue={setValue} />
            : null}
            {props.valueName === 'gerants' || props.valueName === 'presidents' 
            || props.valueName === 'directeurs' ? 
                <Components.GerantsEdit index={props.valueArrayIndex}
                 gerants={value} setValue={setValue} />
            : null}
            {props.valueName === 'adresse' ? 
                <Components.AdresseEdit adresse={value} setValue={setValue} 
                currency={props.currency}/>
            : null}
            {props.valueName === 'infos' ? 
                <Components.InfoEdit sigle={sigle} setSigle={setsigle} 
                activites={activites} setActivites={setActivites}/>
            : null}
            <div className="intro-y col-span-12 flex items-center sm:justify-end mt-10">
            <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
            dark:bg-dark-1 dark:text-gray-300" onClick={() => props.setIsEditModalOpen(false)}>
                Annuler
            </button>
            <button className="button w-24 justify-center block bg-theme-1 
            text-white ml-2" onClick={handleSubmit}>
                Valider
            </button>
            </div>
        </>
    )
}