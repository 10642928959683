import { Link } from "react-router-dom";

export function DropdownItem(props) {
    return (
        <Link to={props.link ?? "/"} className="block p-2 transition duration-300 ease-in-out 
        bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
            {props.children}
        </Link>
    )
    
}