import { Api } from './Api';

const  ENPOINTS = {
    Piece: 'pieces',
};

const getAll = signal => {
    return Api.get(ENPOINTS.Piece, signal)
}

const getById = (id, signal) => {
    return Api.get(`${ENPOINTS.Piece}/${id}`, signal);
}

const create = (payload, signal) => {
    return Api.post(`user/${ENPOINTS.Piece}`, payload, signal)
}

const update = (id, payload, signal) => {
    return Api.put(`${ENPOINTS.Piece}/${id}`, payload, signal)
}
const destroy = (id, signal) => {
    return Api.erase(`${ENPOINTS.Piece}/${id}`, signal)
}

export const PieceService = {
    getAll,
    getById,
    create,
    update,
    destroy
}