import { useState } from 'react';
import { Services } from '../services';

export const useDocument = () => {
    const [id, setId] = useState('');
	const [type_document_id, setType_document_id] = useState('');
	const [etat_document_id, setEtat_document_id] = useState('');
	const [demande_id, setDemande_id] = useState('');
	const [champs_document, setChamps_document] = useState('');
	const [document_url, setDocument_url] = useState('');
	

    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getDocument = (documentId, signal) => {        
        return Services.DocumentService.getById(documentId, signal)
        .then(response => {
            fillDocument(response.document);
            setIsDisabled(false);
        });
    }

    const createDocument = signal => {
        const payload = {
            type_document_id,
		etat_document_id,
		demande_id,
		champs_document,
		document_url,
		
        };

        return Services.DocumentService.create(JSON.stringify(payload), signal);
    }
    const updateDocument = (documentId, signal) => {
        const payload = {
            type_document_id,
		etat_document_id,
		demande_id,
		champs_document,
		document_url,
		
        };

        return Services.DocumentService.update(documentId, JSON.stringify(payload), signal);
    }
    const deleteDocument = (documentId, signal) => {
        return Services.DocumentService.destroy(documentId, signal);
    }
    const fillDocument = (document) => {
        setId(document.id);
        setType_document_id(document.type_document_id ?? '');
		setEtat_document_id(document.etat_document_id ?? '');
		setDemande_id(document.demande_id ?? '');
		setChamps_document(document.champs_document ?? '');
		setDocument_url(document.document_url ?? '');
		
    }
    const emptyDocument = () => {
        setId('');
        setType_document_id('');
		setEtat_document_id('');
		setDemande_id('');
		setChamps_document('');
		setDocument_url('');
		
    }

    return {
        id,
        type_document_id,
		etat_document_id,
		demande_id,
		champs_document,
		document_url,
		
        errors,
        isDisabled,
        setType_document_id,
		setEtat_document_id,
		setDemande_id,
		setChamps_document,
		setDocument_url,
		
        setId,
        setErrors,
        setIsDisabled,
        getDocument,
        createDocument,
        updateDocument,
        deleteDocument,
        fillDocument,
        emptyDocument
    };
}