import { useState } from "react";

export function Montant(props) {
  // console.log('props',props);

  const [adresse, setAddresse] = useState({});

  const handleInputChange = (name, value) => {
    const adresseCopy = { ...adresse };
    adresseCopy[name] = value;

    setAddresse(adresseCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();
    props.handleNextClick(e, "montant", JSON.stringify(adresse));
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12  ">
        <strong>
          Montant des contributions (NE PEUVENT EXCÉDER 10.000 FRANCS CFA)
        </strong>
      </div>
      <div className="intro-y col-span-12  "></div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">
          Le montant du droit d’adhésion{" "}
          {!props.demande.includes("SCOOP") ? "à l’ONG" : null}{" "}
        </div>
        <input
          type="number"
          className="input w-full border flex-1"
          placeholder="Montant du droit d’adhésion"
          onChange={(e) => handleInputChange("montant-droit", e.target.value)}
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Le montant des cotisations annuelles</div>
        <input
          type="number"
          className="input w-full border flex-1"
          placeholder="montant des cotisations"
          onChange={(e) =>
            handleInputChange("montant-cotisation", e.target.value)
          }
        />
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
