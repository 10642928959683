const styleDate = (dateString, style='long') => {
    if (!dateString || dateString === "") return null;

    return new window.Date(dateString)
    .toLocaleDateString("fr", {dateStyle: style});
}

export const Date = {
    styleDate
}

