import { useState } from "react";
// import Alert from "react-bootstrap/Alert";
// import * as Icons from "react-feather";

export function AdresseOneStepRecouvre(props) {
  const [adresse, setAddresse] = useState({});

  const handleInputChange = (name, value) => {
    const adresseCopy = { ...adresse };
    adresseCopy[name] = value;

    setAddresse(adresseCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();
    props.handleNextClick(e, "adresse", JSON.stringify(adresse));
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12  text-lg">
        <strong>
          Quelle est l’adresse du siège social de la société débitrice ?
        </strong>
      </div>
      <div className="intro-y col-span-12  ">
        {/* <Alert variant="danger">
          <Alert.Heading>
            {" "}
            <Icons.Info className="w-4 h-4 " />
          </Alert.Heading>
          L’adresse de votre <strong>société débitrice</strong>  est tout simplement le siège votre <strong>société</strong> . C’est
          l’adresse qui figurera sur tous les documents officiels de votre <strong>société débitrice</strong> .
          Si vous n’avez pas encore de bureaux, vous pouvez domicilier la <strong>société débitrice </strong>  
          chez le président
        </Alert> */}

        <div>
          {" "}
          Indiquez les informations relatives à l'adresse géographique de la <strong>société débitrice</strong> {" "}
        </div>
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Ville</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Ville"
          onChange={(e) => handleInputChange("ville", e.target.value)}
        />
      </div>
      {/* <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Quartier</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Quartier"
          onChange={(e) => handleInputChange("quartier", e.target.value)}
        />
      </div> */}
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Commune</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Commune"
          onChange={(e) => handleInputChange("commune", e.target.value)}
        />
      </div>
      {/* <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Numéro de boîte postale</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Numéro boite postale"
          onChange={(e) => handleInputChange("code-postale", e.target.value)}
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Nom et Prénom(s) du propriétaire du local</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Nom prenom proprietaire"
          onChange={(e) =>
            handleInputChange("nom-prenoms-proprietaire", e.target.value)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Numéro de téléphone du propriétaire du local</div>
        <input
          type="tel"
          className="input w-full border flex-1"
          placeholder="Numéro téléphone"
          onChange={(e) =>
            handleInputChange("numero-telephone", e.target.value)
          }
        />
      </div>
       */}
      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
