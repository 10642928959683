import { CategorieUserService } from "./CategorieUserService";
import { UserService } from "./UserService";
import { RoleService } from "./RoleService";
import { PermissionService } from "./PermissionService";
import { DossierService } from "./DossierService";
import { PaysService } from "./PaysService";
import { EtatDemandeService } from "./EtatDemandeService";
import { EtapeTraitementService } from "./EtapeTraitementService";
import { TypePaiementService } from "./TypePaiementService";
import { MoyenPaiementService } from "./MoyenPaiementService";
import { DemandeService } from "./DemandeService";
import { CodePromoService } from "./CodePromoService";
import { PaiementService } from "./PaiementService";
import { TypeDocumentService } from "./TypeDocumentService";
import { EtatDocumentService } from "./EtatDocumentService";
import { DocumentService } from "./DocumentService";
import { TypePieceService } from "./TypePieceService";
import { PieceService } from "./PieceService";
import { AuthService } from "./AuthService";
import { TypeDemandeService } from "./TypeDemandeService";
import { CinetPaySerivce } from "./CinetpayService";
import { HubspotService } from "./HubspotService";
import { FileService } from "./FileService";
import { ObservationService } from "./ObservationService";
import { ArticleService } from "./ArticleService";
import { dataDataGreffeNom, dataDataGreffeRccm } from "./DatagreffeService";

export const Services = {
  dataDataGreffeNom,
  dataDataGreffeRccm,
  CategorieUserService,
  UserService,
  RoleService,
  PermissionService,
  DossierService,
  PaysService,
  EtatDemandeService,
  EtapeTraitementService,
  TypePaiementService,
  MoyenPaiementService,
  DemandeService,
  CodePromoService,
  PaiementService,
  TypeDocumentService,
  EtatDocumentService,
  DocumentService,
  TypePieceService,
  PieceService,
  AuthService,
  TypeDemandeService,
  ObservationService,
  FileService,
  HubspotService,
  CinetPaySerivce,
  ArticleService,
};
