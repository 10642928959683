import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Hooks } from "../hooks";
import { Services } from "../services";
import { Components } from "../components";
import { Utils } from "../utils";
import * as Icons from "react-feather";

export function DemandeEtapeTraitementView(props) {
  // console.log('props',props);
  const abortController = useMemo(() => new AbortController(), []);

  const { id } = useParams();
  const useDemande = Hooks.useDemande();
  const [stepIndividuelle, setStepIndividuelle] = useState();
  const [indexStep, setIndexStep] = useState(0);
  // console.log("useDemande", useDemande);

  const [etape_traitements, setEtape_traitements] = useState([]);
  const [etapeTraitement, setEtapeTraitement] = useState(null);
  const [step, setStep] = useState("");
  const [questionnaireStep, setQuestionnaireStep] = useState(0);
  const [champs_questionnaireObject, setChamps_questionnaireObject] = useState(
    {}
  );

  // const [hasCompletedQuestionnaire, setHasCompletedQuestionnaire] =
  //   useState(false);
  const [hasValideLead] = useState(false);

  // const [type_demandes, setType_demandes] = useState([]);
  const [typeMyDemande, setTypeMyDemande] = useState("");
  const [autresDemarchesDemandes, setAutresDemarchesDemandes] = useState([]);
  const setTypeDemandesByType = (type_demandes) => {
    const autreDemandeCopy = [];

    type_demandes.forEach((type_demande) => {
      if (!type_demande.libelle.toLowerCase().startsWith("créer")) {
        autreDemandeCopy.push(type_demande);
      }
    });

    setAutresDemarchesDemandes(autreDemandeCopy);
    // setType_demandes(type_demandes);
  };

  const loadTypeDemandeByPays = async (currentPays) => {
    // console.log('currentPays',currentPays);
    try {
      const { type_demandes } = await Services.PaysService.getTypeDemandes(
        currentPays.id,
        abortController.signal
      );
      setTypeDemandesByType(type_demandes);
    } catch (error) {
      if ("messages" in error) return;
      //Toat
    }
  };

  const handleQuetionnaireNextClick = (e, name, value, isEnd = false) => {
    e.preventDefault();
    // setStepIndividuelle()
    // console.log("name", name);
    // console.log("value", value);
    if (name === "associes") {
      setStepIndividuelle(name);
    }
    // console.log("vuseDemandealue", useDemande.type_demande.libelle);
    const champs_questionnaireObjectCopy = { ...champs_questionnaireObject };
    // setSteped(name);

    if (name !== "infos") {
      champs_questionnaireObjectCopy[name] = value;
    } else {
      champs_questionnaireObjectCopy["activites"] = value["activites"];

      champs_questionnaireObjectCopy["sigle"] = JSON.stringify(value["sigle"]);
    }
    setChamps_questionnaireObject(champs_questionnaireObjectCopy);

    if (isEnd) return;
    setQuestionnaireStep(questionnaireStep + 1);
    setIndexStep(questionnaireStep + 1);
    // window.location.reload()
  };
  const handleQuestionnaireBackClick = (e, value) => {
    // console.log("e", e);
    e.preventDefault();

    if (questionnaireStep === 1) return;

    if (useDemande.type_demande?.libelle.includes("SAS")) {
      setQuestionnaireStep(questionnaireStep - 2);
    } else if (
      stepIndividuelle === "associes" &&
      useDemande.type_demande?.libelle.includes("individuelle")
    ) {
      //console.log("stepIndividuelle", stepIndividuelle);
      setQuestionnaireStep(questionnaireStep - 3);
    } else {
      setQuestionnaireStep(questionnaireStep - 1);
    }
  };
  const handleEndClick = async (e) => {
    useDemande.setIsDisabled(true);

    try {
      if (useDemande.etat_demande.libelle.toLowerCase().includes("en cours"))
        await Services.DemandeService.end(
          useDemande.id,
          abortController.signal
        );
    } catch (error) {
      if ("messages" in error) return;
      //Toaster
    } finally {
      useDemande.setIsDisabled(false);
      setStep("End");
    }
  };

  const handleBackClick = (e) => {
    e.preventDefault();

    const stepEtapeTraitmentIndex = etape_traitements.findIndex(
      (etape_traitement) => step === etape_traitement.libelle
    );
    const nextStepEtapeTraitemnt =
      etape_traitements[stepEtapeTraitmentIndex - 1];

    if (!nextStepEtapeTraitemnt || nextStepEtapeTraitemnt === undefined) return;

    setStep(nextStepEtapeTraitemnt.libelle);
    setEtapeTraitement(nextStepEtapeTraitemnt);
  };

  const handleNextClick = (e) => {
    e.preventDefault();

    const stepEtapeTraitmentIndex = etape_traitements.findIndex(
      (etape_traitement) => step === etape_traitement.libelle
    );
    // console.log("stepEtapeTraitmentIndex", stepEtapeTraitmentIndex);
    // setIndexStep(stepEtapeTraitmentIndex + 1);
    const previousStepEtapeTraitemnt =
      etape_traitements[stepEtapeTraitmentIndex + 1];

    if (!previousStepEtapeTraitemnt || previousStepEtapeTraitemnt === undefined)
      return;

    setStep(previousStepEtapeTraitemnt.libelle);
    setEtapeTraitement(previousStepEtapeTraitemnt);
    // window.location.reload()
  };
  const handleQuestionnaireSubmit = async (e, name, value) => {
    e.preventDefault();

    const champs_questionnaireObjectCopy = { ...champs_questionnaireObject };
    champs_questionnaireObjectCopy[name] = value;

    setChamps_questionnaireObject(champs_questionnaireObjectCopy);
    useDemande.setIsDisabled(true);

    try {
      const payload = {
        champs_questionnaire: JSON.stringify(champs_questionnaireObjectCopy),
      };

      const { demande } = await Services.DemandeService.updateQuestionnaire(
        useDemande.id,
        JSON.stringify(payload),
        abortController.signal
      );

      useDemande.fillDemande(demande);
      // console.log("etape_traitements", etape_traitements);
      setEtapeTraitement(etape_traitements[1]);
      // setHasCompletedQuestionnaire(true);
    } catch (error) {
      if ("messages" in error) return;
      //Toaster
    } finally {
      useDemande.setIsDisabled(false);
    }
  };
  const handleStepSubmit = async () => {
    useDemande.setIsDisabled(true);

    try {
      const etapeTraitementIndex = etape_traitements.findIndex(
        (etape_traitement) => etapeTraitement.id === etape_traitement.id
      );
      const nextEtapeTraitemnt = etape_traitements[etapeTraitementIndex + 1];
      const payload = {
        etape_traitement_id: nextEtapeTraitemnt.id,
      };

      await Services.DemandeService.updateEtapeTraitement(
        useDemande.id,
        JSON.stringify(payload),
        abortController.signal
      );

      setEtapeTraitement(nextEtapeTraitemnt);
    } catch (error) {
      if ("messages" in error) return;
      //Toaster
    } finally {
      useDemande.setIsDisabled(false);
    }
  };

  const initialize = useCallback(async () => {
    useDemande.setIsDisabled(true);

    try {
      const { demande } = await Services.DemandeService.getById(
        id,
        abortController.signal
      );
      const { etape_traitements } =
        await Services.TypeDemandeService.getEtapeTraitements(
          demande.type_demande_id,
          abortController.signal
        );

      const { champs_questionnaire } = demande;
      // console.log("demande",demande);
      const { type_demande } = demande;
      setTypeMyDemande(type_demande.libelle);

      const etapeTraitement = etape_traitements.find(
        (etape_traitement) =>
          etape_traitement.id === demande.etape_traitement_id
      );

      setEtape_traitements(etape_traitements);
      setEtapeTraitement(etapeTraitement);
      // setHasValideLead(checkDataValidity(JSON.parse(demande.champs_demande)));
      // console.log("etapeTraitement", etapeTraitement);

      // const champ_etape = demande?.champs_etape_traitements
      //   ? JSON.parse(demande.champs_etape_traitements)
      //   : "";
      // setCommentaire(champ_etape);

      // console.log("commentaire", commentaire);

      useDemande.fillDemande(demande);

      setEtapeTraitement(
        etape_traitements.find(
          (etape_traitement) =>
            demande.etape_traitement_id === etape_traitement.id
        )
      );

      loadTypeDemandeByPays(demande.pays);
      if (
        !champs_questionnaire ||
        type_demande.libelle.includes("statutaire")
      ) {
        setQuestionnaireStep(1);
        return;
      }

      // setHasCompletedQuestionnaire(true);
    } catch (error) {
      if ("messages" in error) return;
      //Toaster
    } finally {
      useDemande.setIsDisabled(false);
    }
  }, [abortController, id]);

  useEffect(() => {
    if (!useDemande.etape_traitement_id || !etapeTraitement) return;
    setStep(etapeTraitement.libelle);
  }, [etapeTraitement]);

  useEffect(() => {
    initialize();
  }, [initialize]);
  return hasValideLead ? (
    <Components.DemandeVerification></Components.DemandeVerification>
  ) : (
    <div className="col-span-12">
      <div
        className="intro-y flex items-center mt-8"
        style={{ position: "relative", zIndex: 2 }}
      >
        <h2 className="text-lg font-medium mr-auto">
          Etapes de traitement "{useDemande.type_demande?.libelle}{" "}
          {useDemande.pays?.libelle}" {step}
        </h2>
      </div>
      <div
        className="intro-y box py-10 sm:py-20 mt-5"
        style={{ opacity: 1, animationName: "none", transform: "none" }}
      >
        <div
          className="wizard flex flex-col lg:flex-row justify-center px-5 sm:px-20 
                    overflow-x-scroll pb-5"
          style={{ position: "relative", zIndex: 2 }}
        >
          {etape_traitements.map((etape_traitement, index) => {
            return (
              <div
                className="intro-x sm:hidden lg:text-center flex items-center 
                lg:block flex-1 z-10"
                key={index}
              >
                <button
                  className={`w-10 h-10 rounded-full  button 
                                    ${
                                      etape_traitement.libelle === step
                                        ? "text-white bg-theme-1"
                                        : "text-gray-600 bg-gray-200 dark:bg-dark-1"
                                    }`}
                >
                  {index + 1}
                </button>

                <div
                  className={`lg:w-32 text-base lg:mt-3 ml-3 
                                    lg:mx-auto ${
                                      etape_traitement.libelle === step
                                        ? "font-medium "
                                        : "text-gray-700 dark:text-gray-600"
                                    }`}
                >
                  {etape_traitement.libelle}
                </div>
              </div>
            );
          })}
          {/* {etape_traitements.map((etape_traitement, index) => ( */}
          <div className="md:hidden lg:hidden">
            <Components.ProgressBar
              className="md:hidden lg:hidden"
              value={indexStep}
              label={step}
              max={etape_traitements.length}
            />
          </div>
          {/* ))} */}
        </div>
        {step.includes("Questionnaire") ? (
          <div
            className="px-5 sm:px-20 mt-3 pt-10 border-t border-gray-200 
                        dark:border-dark-5"
          >
            <div className="font-medium text-base">Questionnaire</div>
            <Components.QuestionnaireSteps
              step={questionnaireStep}
              useDemande={useDemande}
              handleNextClick={handleQuetionnaireNextClick}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              champs_questionnaireObject={champs_questionnaireObject}
              handleSubmit={handleQuestionnaireSubmit}
            />
          </div>
        ) : null}
        {step.includes("Ajout") ? (
          <div
            className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
                        dark:border-dark-5"
          >
            <div className="font-medium text-base">
              Transmettez nous vos documents
            </div>
            {typeMyDemande.includes("Recouvrement") ? (
              <div>
                <Components.AjoutDocumentStepRecouv
                  useDemande={useDemande}
                  handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                  handleSubmit={handleStepSubmit}
                />
              </div>
            ) : (
              <div>
                <Components.AjoutDocumentStep
                  useDemande={useDemande}
                  handleQuestionnaireBackClick={handleQuestionnaireBackClick}
                  handleSubmit={handleStepSubmit}
                />
              </div>
            )}
          </div>
        ) : null}
        {/* {step.includes("Procédures à l’amiables") ? (
          <div
            className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
                     dark:border-dark-5"
          >
            <div className="font-medium text-base">Rédaction des documents</div>
            <Components.RedactionDocumentStep
              useDemande={useDemande}
              etapeTraitement={etapeTraitement}
              handleSubmit={handleStepSubmit}
            />
          </div>
        ) : null} */}
        {step.includes("Documents pour formalités") ? (
          <div
            className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
                        dark:border-dark-5"
          >
            <div className="font-medium text-base">
              Documents pour formalités
            </div>
            <Components.DocumentFormaliteStep
              useDemande={useDemande}
              handleQuestionnaireBackClick={handleQuestionnaireBackClick}
              etapeTraitement={etapeTraitement}
              handleSubmit={handleStepSubmit}
            />
          </div>
        ) : null}
        {step.includes("Rédaction") ||
        step.includes("Redaction") ||
        step.includes("Relecture") ||
        step.includes("Procédures") ? (
          <div
            className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
                        dark:border-dark-5"
          >
            <div className="font-medium text-base">Rédaction des documents</div>
            <Components.RedactionDocumentStep
              useDemande={useDemande}
              etapeTraitement={etapeTraitement}
              handleSubmit={handleStepSubmit}
            />
          </div>
        ) : null}
        {step.includes("Signature") ? (
          <div
            className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
                     dark:border-dark-5"
          >
            <div className="font-medium text-base">Signature des documents</div>
            <Components.SignatureDocumentStep
              etapeTraitement={etapeTraitement}
              useDemande={useDemande}
            />
          </div>
        ) : null}
        {step.includes("Formalités") ? (
          <div
            className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
                     dark:border-dark-5"
          >
            <div className="font-medium text-base">
              Formalités d'immatriculation
            </div>
            <Components.FormalitesStep
              etapeTraitement={etapeTraitement}
              useDemande={useDemande}
            />
          </div>
        ) : null}
        {step.includes("RCCM") ? (
          <div
            className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
                     dark:border-dark-5"
          >
            <div className="font-medium text-base">Transmission RCCM & IDU</div>
            <Components.LivraisonStep
              etapeTraitement={etapeTraitement}
              useDemande={useDemande}
            />
          </div>
        ) : null}
        {step.includes("Rattachement") ? (
          <div
            className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
                     dark:border-dark-5"
          >
            <div className="font-medium text-base">Rattachement Fiscal</div>
            <Components.FormalitesStep
              etapeTraitement={etapeTraitement}
              useDemande={useDemande}
            />
          </div>
        ) : null}
        {step.includes("Transmission DFE") ||
        step.includes("Transmission des documents") ? (
          <div
            className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
                     dark:border-dark-5"
          >
            <div className="font-medium text-base">
              Transmission des documents
            </div>
            <Components.LivraisonStep
              etapeTraitement={etapeTraitement}
              useDemande={useDemande}
            />
          </div>
        ) : null}
        {step.toLowerCase().includes("notaire") ? (
          <div
            className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
                     dark:border-dark-5"
          >
            <div className="font-medium text-base">
              Authentification des actes par un notaire
            </div>
            <Components.FormalitesStep
              etapeTraitement={etapeTraitement}
              useDemande={useDemande}
            />
          </div>
        ) : null}
        {step
          .toLowerCase()
          .includes("verification de la disponibilité du nom") ? (
          <div
            className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
                     dark:border-dark-5"
          >
            <div className="font-medium text-base">
              Verification de la disponibilité du nom
            </div>
            <Components.VerificationDisponibiliteNomStep
              etapeTraitement={etapeTraitement}
              useDemande={useDemande}
            />
          </div>
        ) : null}
        {step.toLowerCase().includes("end") ? (
          <div
            className="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 
                        dark:border-dark-5"
          >
            <div className="font-light text-base sm:text-3xl text-2xl">
              Votre demande N° {useDemande.numero_demande} est terminée avec
              succès
            </div>
            <div className="font-light text-base sm:text-3xl text-2xl my-2">
              Merci d'avoir choisi Légafrik.
            </div>
            <div style={{ maxWidth: "500px" }}>
              <Components.InfoBox title="">
                <Components.InfoItem
                  attribute="Type de la demande"
                  value={useDemande.type_demande?.libelle}
                />
                <Components.InfoItem attribute="Options" value={""} />
                <Components.InfoItem
                  attribute="Pays"
                  value={useDemande.pays?.libelle}
                />
                <Components.InfoItem
                  attribute="Solde"
                  value={`${useDemande.montant_paye} ${useDemande.pays.monnaie}`}
                />
                <Components.InfoItem
                  attribute="Date d'emission"
                  value={Utils.Date.styleDate(useDemande.created_at)}
                />
              </Components.InfoBox>
            </div>
            <div className="intro-y flex items-center h-10 mt-8">
              <h2 className="text-lg font-medium mr-5">
                Legafrik vous accompagne aussi pour :
              </h2>
            </div>
            <div className="grid grid-cols-12 gap-0 md:gap-8 mb-10">
              {autresDemarchesDemandes.map((type_demande, index) => {
                return (
                  <Fragment key={index}>
                    <Components.AnalyticsCard
                      name={type_demande.libelle}
                      linkTo={`/commandes/autres/${type_demande.id}`}
                    />
                  </Fragment>
                );
              })}
            </div>
          </div>
        ) : null}

        {etape_traitements.length >= 1 && etapeTraitement.id ? (
          <div
            className="intro-y row-auto 
                        sm:px-20 sm:mt-20 mt-10 px-3"
          >
            {etapeTraitement.id ===
              etape_traitements[etape_traitements.length - 1].id &&
            step.includes("Fin") ? (
              <div className="intro-y grid grid-cols-12 gap-3 sm:gap-6 md:gap-12 ">
                <div className="col-span-12 text-lg ">
                  Fin de la procédure de recouvrement <br />
                  <br />
                  <Components.RedactionDocumentStep
                    useDemande={useDemande}
                    etapeTraitement={etapeTraitement}
                    handleSubmit={handleStepSubmit}
                  />
                </div>
                <div className="intro-y col-span-12  flex items-center justify-between ">
                  <button
                    className="button px-2 block flex items-center"
                    onClick={handleBackClick}
                    disabled={useDemande.isDisabled}
                  >
                    <Icons.ArrowLeft className="mr-2" size={17} /> Retour
                  </button>
                  <button
                    className="button justify-center block bg-theme-1 
                                        text-white ml-2 px-10"
                    onClick={handleEndClick}
                    disabled={useDemande.isDisabled}
                  >
                    {useDemande.isDisabled
                      ? "Chargement..."
                      : "J'ai téléchargé les documents"}
                  </button>
                </div>
              </div>
            ) : null}
            {etape_traitements[
              etape_traitements.findIndex(
                (et) => et.id === etapeTraitement.id
              ) - 1
            ] ? (
              <>
                {etape_traitements[
                  etape_traitements.findIndex(
                    (et) => et.id === etapeTraitement.id
                  ) - 1
                ].libelle
                  .toLowerCase()
                  .includes("transmission") ? (
                  <button
                    className="button px-2 block flex items-center"
                    onClick={handleBackClick}
                    disabled={useDemande.isDisabled}
                  >
                    <Icons.ArrowLeft className="mr-2" size={17} /> Retour
                  </button>
                ) : null}
                {etapeTraitement.libelle
                  .toLowerCase()
                  .includes("transmission") &&
                etapeTraitement.id !==
                  etape_traitements[etape_traitements.length - 1].id ? (
                  <button
                    className="button block px-2 text-theme-1 flex items-center"
                    onClick={handleNextClick}
                    disabled={useDemande.isDisabled}
                  >
                    Suivant <Icons.ArrowRight className="ml-2" size={17} />
                  </button>
                ) : null}
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
