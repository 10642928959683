export const SAS = [
  {
    name: "denomination",
    question: "Veuillez entrer le nom de votre entreprise",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "secteur-activite",
    question: "Quel est votre secteur d’activité ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Commerce général", price: "" },
      { value: "Activités Immobilières", price: "" },
      { value: "Activités Informatiques", price: "" },
      { value: "Restauration", price: "" },
      { value: "Agence de voyage", price: "" },
      { value: "Transport VTC", price: "" },
      { value: "Agence de transfert d’argent", price: "" },
      { value: "Cabinet de conseil", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  {
    name: "nombre-associes",
    question: "Combien d’associés êtes-vous ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "banque",
    question: "Dans quelle banque le compte sera-t-il ouvert ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "entreprise-anterieur",
    question: "Avez-vous déjà créé une entreprise ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Oui", price: "" },
      { value: "Non", price: "" },
    ],
  },
  {
    name: "date-reception-registre-du-commerce",
    question: "Quand voulez-vous recevoir votre Registre du commerce ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "1 semaine", price: "" },
      { value: "Je ne sais pas encore", price: "" },
    ],
  },
  {
    name: "localisation-siege",
    question: "Où se situera le siège de votre entreprise ?",
    description:
      "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "A mon domicile", price: "" },
      { value: "Je domicilie à Legafrik (351 313 Franc guinéen/mois)", price: "" },
      { value: "Je loue un local commercial", price: "" },
      { value: "Je n'ai pas de siège", price: "" },
    ],
  },
  {
    name: "capital-social",
    question: "Quel est le montant de votre capital social ?",
    description:
      "SARL Capital minimun 1 405 255 Franc guinéen et pas de capital minimum pour la SAS Guinée",
    type: "mutiple", //"mutiple",
    options: [
      { value: "1 405 255 Franc guinéen", price: "" },
      { value: "14 052 553 Franc guinéen", price: "" },
      { value: "1 405 255 365 Franc guinéen", price: "" },
      { value: "Autres", price: "" },
    ],
  },

  {
    name: "offre",
    question: "Quelle Offre choisissez-vous ?",
    type: "mutiple", //"mutiple",
    options: [
      {
        value: "Création d'une SAS en Guinée à 4 908 324  Franc guinéen ",
        price: 4_908_324,
      },
      // {value: "Capital supperieur à 5.000.000 FCFA (sur devis)", price: 350_000},
    ],
  },
  {
    name: "gestion-domiciliation",
    question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
    description: "Choisissez une formule",
    type: "mutiple", //"mutiple",
    options: [
      {
        value: "06 MOIS à 1 682 856 Franc guinéen (280 476 Franc guinéen/MOIS)",
        price: 280_476,
      },
      {
        value: "12 MOIS à 3 365 712 Franc guinéen (280 476 Franc guinéen/MOIS)",
        price: 280_476,
      },
      { value: "Je ne suis pas intéressé", price: "" },
    ],
  },
];
