import React, { useState } from "react";
import logoImg from "../assets/images/logo-legafrik.png";
import { useNavigate, useParams } from "react-router-dom";

export default function SuiviDemande() {
  const history = useNavigate();
  const param = useParams();

  const [formData, setFormData] = useState({});

  // console.log("param", param);

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: param.reference ? param.reference : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    history(`/suivi-questionaire/`, {
      state: {
        reference: param.reference ? param.reference : formData.reference,
        // type_demandes: "individuelle",
        paraPays: "CI",
      },
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="my-15 mx-auto md:w-1/2 shadow-none lg:w-1/3 box p-10"
    >
      <div className="flex justify-center">
        <img
          alt="Midone Tailwind HTML Admin Template"
          className="w-16 -intro-x "
          src={logoImg}
        />
      </div>
      <div className=" ">
        <p className="-intro-x text-dark-1 font-medium text-2xl  my-8">
          Gérez votre compte et suivez sereinement l'évolution de vos dossiers
          en temps réel, au même endroit !
        </p>
        <p className="text-dark-1">
          Entrez le numéro de référence de votre demande
        </p>
        <input
          type="text"
          className="intro-x w-full input input--lg 
                border border-gray-300 block"
          placeholder="numéro de référence"
          id="reference"
          defaultValue={param.reference ? param.reference : formData.reference}
          required
          onChange={(e) => handleChange("reference", e.target.value)}
        />
      </div>
      <div className="flex justify-center gap-4 mt-10">
        <button
          className="button button--lg sm:w-56  md:w-48 xl:w-64 text-white 
                bg-dark-1 shadow-none"
          type="submit"
        >
          Recherche
        </button>
      </div>
    </form>
  );
}
