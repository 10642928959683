import { useCallback, useEffect, useMemo, useState } from "react";
import { Services } from "../services";

export function EnregistrementCepiciStep(props) {
    const abortController = useMemo(() => new AbortController(), []);

    const [, setEtat_etape_traitements] = useState([]);
    const [status_etat_traitement, setStatut_etat_traitement] = useState(null);
    const [statusLibelle, setstatusLibelle] = useState("Veuillez patienter...");

    const [, setIsDisabled] = useState(true);

    const initialize = useCallback(async () => {
        try {
            const {etat_etape_traitements} = await Services.EtapeTraitementService
            .getAllEtatTraitementsById(props.etapeTraitement.id, abortController.signal);
            setEtat_etape_traitements(etat_etape_traitements);

            const {status_etat_traitement} = await Services.DemandeService
            .getStatutEtatTraitementById(props.useDemande.id, props.etapeTraitement.id, 
                abortController.signal);
            setStatut_etat_traitement(status_etat_traitement
                );

            const statusLibelle = etat_etape_traitements.find(
                    eet => eet.id === status_etat_traitement.etat_etape_traitement_id)?.libelle;
            
            setstatusLibelle(statusLibelle ?? "En cours");
            setIsDisabled(true);
        } catch (error) {
            const message = await error.messages;
            if (message.includes("status etat traitement not found"))
                setstatusLibelle("En cours");

            setIsDisabled(false);
            if ('messages' in error) return;
                //Toaster
        }
      },
      [abortController],
    )
    
    useEffect(() => {
        initialize();
    }, [initialize])
    return (
        <div className="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-10 col-span-12">
            <div className="col-span-12 text-base">{status_etat_traitement?.description}</div>
            <div className="col-span-12">
                <strong>Délai estimé :</strong> {status_etat_traitement?.temps_estime}
            </div>
            <div className="col-span-12">
                <strong>Status :</strong> {statusLibelle}
            </div>
        </div>
    )
}