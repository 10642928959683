import { useState } from "react"
import Alert from 'react-bootstrap/Alert';
import * as Icons from 'react-feather';

export function SigleStep(props) {
    const formContainer = "sigle-form-container";

    const [sigle, setSigle] = useState({});
    const [sigleType, setSigleType] = useState('');

    const handleInputChange = ( name, value) => {
        const sigleCopy = {...sigle}       
        sigleCopy[name] = value;
        // const gerantsCopy = gerants.map(gerrant => {
        //     return {...gerrant};
        // });        
        // gerantsCopy[index][name] = value;

         setSigle(sigleCopy);
    }
    const handleSigleChange=(value)=>{

        if(value==='non'){

            const formContainerElem = window.document.querySelector(
                `#${formContainer}`)

            Array.from(formContainerElem.querySelectorAll('.field'))
            .forEach(field => field.value = "");
            
            setSigle({});
        }
        setSigleType(value)
    }
    const handleStepSubmit = e => {
        e.preventDefault();

           if( sigleType==='oui' || sigleType==='non'  ){
               if(  sigleType==='oui' && sigle.sigle===undefined ){
                return alert("Vous devez remplir le formulaire !");
               }
               props.handleNextClick(e, 'sigle', JSON.stringify(sigle));
           }
        else {
            return alert("Vous devez remplir le formulaire !");
        } 
    }
    return (
        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                ${props.step !== props.index ? 'hidden' : ''}`} id={`${formContainer}`}>
                    <h4 className="intro-y col-span-12 text-lg leading-none">
                        Le nom de votre société est : 
                        <strong> {props.nomSociete.toString()}</strong>
                    </h4>
                    <div className="intro-y col-span-12 ">
                    <div className="intro-y col-span-12  "> 
                    <strong>Avez vous un sigle ?</strong>  
                </div>

                <div className="intro-y col-span-12" >        
                    <Alert variant='danger'>
                        <Alert.Heading><Icons.Info className="w-4 h-4 "/></Alert.Heading>
                        <Alert.Link href="#">Le sigle</Alert.Link> d’une société correspond à 
                        <Alert.Link href="#"> un diminutif du nom de la société</Alert.Link>. 
                        Le sigle peut être utile lorsque le nom de la société est long.
                    </Alert>           
                </div>          
                <div className="flex flex-col sm:flex-row mt-4 mb-2">
                    <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0"> 
                        <input type="radio" className="input border mr-2" 
                        id={`horizontal-non-0`} 
                        name={`sigle-0`} value="oui" 
                        onChange={e => handleSigleChange("non")}/> 
                        <label className="cursor-pointer select-none" 
                        htmlFor={`horizontal-non-0`}>
                            Non
                        </label> 
                    </div>
                    <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2"> 
                        <input type="radio" className="input border mr-2" 
                        id={`horizontal-radio-oui-0`} 
                        name={`sigle-0`} value="non" 
                        onChange={e => handleSigleChange("oui")}/> 
                        <label className="cursor-pointer select-none" 
                        htmlFor={`horizontal-radio-oui-0`}>
                            Oui
                        </label> 
                    </div>
                </div>
                <input type="text" className={`input w-full border flex-1 field 
                ${sigleType==='non' ?'hidden': ' '}`} placeholder="Votre réponse" 
                disabled={(!sigle?.sigle && sigleType==='') || sigleType==='non'} 
                onChange={e => handleInputChange('sigle', e.target.value)}/>
                <div className={`${(!sigle?.sigle && sigleType==='') ||
                 sigleType==='non' ? 'hidden' : ''}`}>
                    <div className="">Le sigle proposé est-il obligatoire?</div>
                    <div className="flex flex-col sm:flex-row mt-2 mb-2">

                        <div className="flex items-center text-gray-700 dark:text-gray-500 
                        mr-2 mt-2 sm:mt-0"> 
                            <input type="radio" className="input border mr-2" 
                            id={`horizontal-non-0`} 
                            name={`sigle-1`} value="oui" 
                            onChange={e => handleInputChange('sigle-obligatoire', 'non')}/> 
                            <label className="cursor-pointer select-none" 
                            htmlFor={`horizontal-non-0`}>
                                Non
                            </label> 
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2"> 
                            <input type="radio" className="input border mr-2" 
                            id={`horizontal-radio-oui-0`} 
                            name={`sigle-1`} value="non" 
                            onChange={e => handleInputChange('sigle-obligatoire', 'oui') }/> 
                            <label className="cursor-pointer select-none" 
                            htmlFor={`horizontal-radio-oui-0`}>
                                Oui
                            </label> 
                        </div>
                    </div>
                </div>
            </div>
                
            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                {/* <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300" onClick={props.handleBackClick ?? null}>
                    Revenir
                </button> */}
                <button className="button w-24 justify-center block bg-theme-1 
                text-white ml-2" onClick={handleStepSubmit}>
                    Suivant
                </button>
            </div>
        </div>
    )
}