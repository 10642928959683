import React from "react";
import { BeatLoader } from "react-spinners";

function Loading({ size = "40px", color = "#006699" }) {
  return (
    <div>
      <BeatLoader size={size} color={color} />
    </div>
  );
}

export default Loading;
