import { useState } from 'react';
import { Services } from '../services';

export const useMoyenPaiement = () => {
    const [id, setId] = useState('');
	const [libelle, setLibelle] = useState('');
	

    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getMoyenPaiement = (moyenpaiementId, signal) => {        
        return Services.MoyenPaiementService.getById(moyenpaiementId, signal)
        .then(response => {
            fillMoyenPaiement(response.moyenpaiement);
            setIsDisabled(false);
        });
    }

    const createMoyenPaiement = signal => {
        const payload = {
            libelle,
		
        };

        return Services.MoyenPaiementService.create(JSON.stringify(payload), signal);
    }
    const updateMoyenPaiement = (moyenpaiementId, signal) => {
        const payload = {
            libelle,
		
        };

        return Services.MoyenPaiementService.update(moyenpaiementId, JSON.stringify(payload), signal);
    }
    const deleteMoyenPaiement = (moyenpaiementId, signal) => {
        return Services.MoyenPaiementService.destroy(moyenpaiementId, signal);
    }
    const fillMoyenPaiement = (moyenpaiement) => {
        setId(moyenpaiement.id);
        setLibelle(moyenpaiement.libelle ?? '');
		
    }
    const emptyMoyenPaiement = () => {
        setId('');
        setLibelle('');
		
    }

    return {
        id,
        libelle,
		
        errors,
        isDisabled,
        setLibelle,
		
        setId,
        setErrors,
        setIsDisabled,
        getMoyenPaiement,
        createMoyenPaiement,
        updateMoyenPaiement,
        deleteMoyenPaiement,
        fillMoyenPaiement,
        emptyMoyenPaiement
    };
}