import { useState } from "react";
import PhoneInput from "react-phone-input-2";

export function CommissaireAdjoint(props) {
  const formContainer = "gerants-form-container";
  const [adresseAdjoint, setAddresseAdjoint] = useState({});
  const [gerantType, setGerantType] = useState("");

  const handleInputChangeAdjoint = (name, value) => {
    const adresseCopyed = { ...adresseAdjoint };

    adresseCopyed[name] = value;

    setAddresseAdjoint(adresseCopyed);
  };

  const handleCommissaireAdjoint = (value) => {
    if (value === "oui") {
      const formContainerElem = window.document.querySelector(
        `#${formContainer}-0`
      );
      Array.from(formContainerElem.querySelectorAll(".field")).forEach(
        (field) => (field.value = "")
      );
    }

    setGerantType(value);
  };

  const handleStepSubmit = async (e) => {
    e.preventDefault();
    props.handleNextClick(
      e,
      "commissaire-adjoint",
      JSON.stringify(adresseAdjoint)
    );
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12">
        <strong>
          Avez-vous des membres du Commissariat aux Comptes Adjoint ?
        </strong>
      </div>
      <div className="intro-y col-span-12" id={`${formContainer}-0`}>
        {/* <small className="mb-4">Indiquez les informations relatives au gérant </small> */}

        <div className="flex flex-col sm:flex-row mt-4 mb-2">
          <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-radio-oui-0`}
              name={`gerant-0`}
              value="non"
              onChange={(e) => handleCommissaireAdjoint("oui")}
            />
            <label
              className="cursor-pointer select-none"
              htmlFor={`horizontal-radio-oui-0`}
            >
              Oui
            </label>
          </div>
          <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-non-0`}
              name={`gerant-0`}
              value="oui"
              onChange={(e) => handleCommissaireAdjoint("non")}
            />
            <label
              className="cursor-pointer select-none"
              htmlFor={`horizontal-non-0`}
            >
              Non
            </label>
          </div>
        </div>
      </div>

      <div
        className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${
                          gerantType !== undefined && gerantType === "oui"
                            ? ""
                            : "hidden"
                        }`}
      >
        <div className="intro-y col-span-12 sm:col-span-6">
          <div className="mb-2">
            Nom et Prénom(s) du Commissaire aux Comptes Adjoint ?
          </div>
          <input
            type="text"
            className="input w-full border flex-1"
            placeholder="Nom prenom proprietaire"
            onChange={(e) => handleInputChangeAdjoint("nom", e.target.value)}
          />
        </div>
        <div className="intro-y col-span-12 sm:col-span-6">
          <div className="mb-2">
            Nationalité du Commissaire aux Comptes Adjoint ?
          </div>
          <input
            type="text"
            className="input w-full border flex-1"
            placeholder="Nationnalité"
            onChange={(e) =>
              handleInputChangeAdjoint("nationalite", e.target.value)
            }
          />
        </div>
        <div className="intro-y col-span-12 sm:col-span-6">
          <div className="mb-2">
            Numéro de téléphone du Commissaire aux Comptes Adjoint ?
          </div>
          <PhoneInput
            country={"ci"}
            // value={e.target.value
            onChange={(e) => handleInputChangeAdjoint("numero-telephone", e)}
            autoFormat={false}
            inputStyle={{
              padding: "21px1 20px 21px 52px",
              width: "100%",
              boxShadow: "0px 3px 5px #00000007",
              borderColor: "rgba(226, 232, 240, 1)",
            }}
            placeholder="Numéro de téléphone"
          />
          {/* <input
            type="tel"
            className="input w-full border flex-1"
            placeholder="Numéro téléphone"
            onChange={(e) =>
              handleInputChangeAdjoint("numero-telephone", e.target.value)
            }
          /> */}
        </div>
      </div>
      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
