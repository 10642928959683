// import { Components } from "..";

export function PieceForm(props) {
  return (
    <form
      className="form"
      disabled={props.isDisabled ?? false}
      onSubmit={props.handleFormSubmit ?? null}
    >
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="user_id">User_id</label>
            <select
              className="select2 form-control"
              id="user_id"
              name="user_id"
              value={props.usePiece.user_id ?? ""}
              disabled={props.isDisabled}
              onChange={(e) =>
                props.usePiece.setUser_id(e.target.value) ?? null
              }
              required
            >
              {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
            </select>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="type_piece_id">Type_piece_id</label>
            <select
              className="select2 form-control"
              id="type_piece_id"
              name="type_piece_id"
              value={props.usePiece.type_piece_id ?? ""}
              disabled={props.isDisabled}
              onChange={(e) =>
                props.usePiece.setType_piece_id(e.target.value) ?? null
              }
              required
            >
              {/* {
                                props.items.map(item => {
                                    return <option key={Math.random()} value={item.id ?? ''}>{item.name}</option>
                                })
                            }  */}
            </select>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="piece_url">Piece_url</label>
            <input
              className="form-control"
              type="text"
              id="piece_url"
              name="piece_url"
              placeholder="Piece_url"
              value={props.usePiece.piece_url ?? ""}
              disabled={props.isDisabled}
              onChange={(e) =>
                props.usePiece.setPiece_url(e.target.value) ?? null
              }
              required
            />
          </div>
        </div>

        <div className="col-12 text-right">
          <button
            disabled={props.isDisabled ?? false}
            type="submit"
            className="btn btn-primary"
            onClick={props.handleFormSubmit}
          >
            <span>Enregistrer</span>
          </button>
        </div>
      </div>
    </form>
  );
}
