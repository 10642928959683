export const DOMICILIATION = [
    {
        name: "denomination",
        question: "A quel premier nom  avez-vous pensé pour votre entreprise ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "denomination",
        question: "A quel deuxième nom avez-vous pensé pour votre entreprise ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "denomination",
        question: "A quel troisième nom  avez-vous pensé pour votre entreprise ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "secteur-activite",
        question: "Quelle est votre domaine d'activité ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "BTP", price: ""},
            {value: "Evènementiel", price: ""},
            {value: "Informatiques", price: ""},
            {value: "Finance", price: ""},
            {value: "Formation", price: ""},
            {value: "Autres", price: ""},
        ]
    },
    {
        name: "offre",
        question: "Quelle Offre choisissez-vous ?",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Je domicilie à Legafrik pour 6 mois (START BUSINESS : 4160 MAD)", price: 4_160},
            {value: "Je domicilie à Legafrik pour 12 mois (CONFORT : 5760 MAD)", price: 5_760},
            {value: "Je domicilie à Legafrik pour 24 mois (CONFORT PLUS : 6610 MAD)", price: 6_610},
        ]
    }
]