export const DOMICILIATION = [
    {
        name: "denomination",
        question: "Quel est le nom de l’entreprise que vous domicilier ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "secteur-activite",
        question: "Quelle est votre domaine d'activité ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "BTP", price: ""},
            {value: "Evènementiel", price: ""},
            {value: "Informatiques", price: ""},
            {value: "Finance", price: ""},
            {value: "Formation", price: ""},
            {value: "Autres", price: ""},
        ]
    },
    {
        name: "offre",
        question: "Quelle Offre choisissez-vous ?",
        type: "mutiple", //"mutiple",
        options : [
            {value: "6 mois de domiciliation (150 000 FCFA)", price: 150_000},
            {value: "1 an de domiciliation (300 000 FCFA)", price: 300_000},
        ]
    }
]