import { useState } from 'react';
import { Services } from '../services';

export const useObservation = () => {
    const [id, setId] = useState('');
	const [demande_id, setDemande_id] = useState('');
	const [type_document_id, setType_document_id] = useState('');
	const [content, setContent] = useState('');
	const [document_url, setDocument_url] = useState('');
	

    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getObservation = (ObservationId, signal) => {        
        return Services.ObservationService.getById(ObservationId, signal)
        .then(response => {
            fillObservation(response.Observation);
            setIsDisabled(false);
        });
    }

    const createObservation = signal => {
        const payload = {
            demande_id,
		type_document_id,
		content,
		document_url,
		
        };

        return Services.ObservationService.create(JSON.stringify(payload), signal);
    }
    const updateObservation = (ObservationId, signal) => {
        const payload = {
            demande_id,
		type_document_id,
		content,
		document_url,
		
        };

        return Services.ObservationService.update(ObservationId, JSON.stringify(payload), signal);
    }
    const deleteObservation = (ObservationId, signal) => {
        return Services.ObservationService.destroy(ObservationId, signal);
    }
    const fillObservation = (Observation) => {
        setId(Observation.id);
        setDemande_id(Observation.demande_id ?? '');
		setType_document_id(Observation.type_document_id ?? '');
		setContent(Observation.content ?? '');
		setDocument_url(Observation.document_url ?? '');
		
    }
    const emptyObservation = () => {
        setId('');
        setDemande_id('');
		setType_document_id('');
		setContent('');
		setDocument_url('');
		
    }

    return {
        id,
        demande_id,
		type_document_id,
		content,
		document_url,
		
        errors,
        isDisabled,
        setDemande_id,
		setType_document_id,
		setContent,
		setDocument_url,
		
        setId,
        setErrors,
        setIsDisabled,
        getObservation,
        createObservation,
        updateObservation,
        deleteObservation,
        fillObservation,
        emptyObservation
    };
}