import { useEffect, useState } from "react"
import logoImg from '../assets/images/logo-legafrik.png';
import loginImg from '../assets/images/illustration.svg';
import { Components } from "../components";
import { AuthLayout } from "../layouts/AuthLayout";
import { Services } from "../services"
import { Hooks } from "../hooks";
// import { useNavigate } from "react-router-dom";
import { Utils } from "../utils";

export function RegisterView(props){
    const abortController = new AbortController();

    // const nvigate = useNavigate();
    const useUser = Hooks.useUser();

    const [password_confirmation, setPassword_confirmation] = useState('');
    const [hasAgreed, setHasAgreed] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    // const [isDisabled, setIsDisabled] = useState(false);


    // const getPhoneCodes = async () => {
    //     try {
    //         return await fetch("https://restcountries.com/v3.1/all?fields=cca3,idd")
    //         .then(res => res.json());
    //     } catch (error) {
    //         throw new Error(error?.message ?? 
    //             "Une erreur est suvenue lo'rs de la récupération des code pays");
    //     }
    // }

    const handleRegisterSubmit = async (e) => {
        e.preventDefault();
        setErrorMessages([]);

        if (!hasAgreed) {
            setErrorMessages('Vous devez accepter les conditions d\'utilisation');
            return;
        }

        if (password_confirmation !== useUser.password) {
            setErrorMessages('Les mots de passe ne sont pas valides');
            return;
        }

        useUser.setIsDisabled(true);

        try {
            const payload = {
                username: useUser.username,
                email: useUser.email,
                password: useUser.password,
                numero_telephone: "+" + useUser.numero_telephone
            }

            const data = await Services.AuthService.register(
                JSON.stringify(payload), abortController.signal);

            Utils.Auth.setSessionToken(data.token)
            Utils.Auth.setUser(data.user)
            useUser.setIsDisabled(false);
            window.location.replace('/');
            
        } catch (error) {
            useUser.setIsDisabled(false);
            if ('messages' in error)
                error.messages.then(messages => setErrorMessages(messages));
        }
    }

    useEffect(() => {
        window.document.body.className = 'login';

        return () => {
            window.document.body.className = 'app';
        }
    }, [])

    return (
        <AuthLayout>
             <div className="container sm:px-10">
                <div className="block xl:grid grid-cols-2 gap-4">
                    <div className="hidden xl:flex flex-col min-h-screen">
                        <span className="-intro-x flex items-center pt-5">
                            <img alt="Inscription" 
                            className="w-6" src={logoImg} />
                            <span className="text-white text-lg ml-3"> 
                                Leg<span className="font-medium">afrik</span> 
                            </span>
                        </span>
                        <div className="my-auto">
                            <img alt="Inscription" 
                            className="-intro-x w-1/2 -mt-16" src={loginImg} />
                            <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                                Quelques clics de plus pour vous
                                <br />connecter à votre compte.
                            </div>
                            <div className="-intro-x mt-5 text-lg text-white dark:text-gray-500">
                                Créez votre compte et accédez à votre espace client, téléchargez 
                                vos documents et suivez en temps réel l’évolution de votre dossier !                           
                            </div>
                        </div>
                    </div>                     
                    <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
                        <div className="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent 
                        px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full 
                        sm:w-3/4 lg:w-2/4 xl:w-auto">
                             <Components.ErrorMessages>
                                {errorMessages}
                            </Components.ErrorMessages>
                            <Components.RegisterForm useUser={useUser}  
                            password_confirmation={password_confirmation} 
                            setPassword_confirmation={setPassword_confirmation} 
                            handleSubmit={handleRegisterSubmit} 
                            hasAgreed={hasAgreed} setHasAgreed={setHasAgreed}/>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}