import { useState } from "react";

export function SignatureSarlStep(props) {
    const formContainer = "signature-form-container";

    const [signature, setSignature] = useState({});
    
    const handleInputChange = (name, value) => {
        const signatureCopy = 
         {...signature};
              
        signatureCopy[name] = value;

        setSignature(signatureCopy);
    }
   
    const handleStepSubmit = e => {
        e.preventDefault();

        props.handleNextClick(e, 'signature', JSON.stringify(signature));
    }

   
    return (
        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? 'hidden' : ''}`}>
            <h4 className="intro-y col-span-12 text-lg leading-none">
            </h4>
            
          
                    <div className="intro-y col-span-12" id={`${formContainer}`}>
                        <small>Indiquez les informations relatives à la signature des gérants</small>
                        <div className="mb-2">En cas de cogérance, quel type de signature engage la société  ?</div>
                        <div className="flex flex-col sm:flex-row mt-4 mb-2">
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2"> 
                                <input type="radio" className="input border mr-2" 
                                id={`signature-oui`} 
                                name={`signature`} value="non" 
                                onChange={e => handleInputChange('type-signature', "Signature cointe")}/> 
                                <label className="cursor-pointer select-none" 
                                htmlFor={`signature-oui`}>
                                    Signature cointe
                                </label> 
                            </div>
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0"> 
                                <input type="radio" className="input border mr-2" 
                                id={`signature-non`} 
                                name={`signature`} value="oui" 
                                onChange={e => handleInputChange('type-signature', "Signature séparée")}/> 
                                <label className="cursor-pointer select-none" 
                                htmlFor={`signature-non`}>
                                    Signature séparée
                                </label> 
                            </div>
                        </div>
                       
                        
                    </div>
                
            
            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300" onClick={props.handleQuestionnaireBackClick ?? null}>
                    Revenir
                </button>
                <button className="button w-24 justify-center block bg-theme-1 
                text-white ml-2" onClick={handleStepSubmit}>
                    Suivant
                </button>
            </div>
        </div>
    )
}