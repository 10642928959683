import { useState } from "react";

export function MandatSarlStep(props) {
    const formContainer = "mandat-form-container";

    const [mandat, setMandat] = useState({["type-mandat"]:"illimitée"});
  
    const handleInputChange = ( name, value) => {
        if(name==='mandat-type'){
            const formContainerElem = window.document.querySelector(
                `#${formContainer}-0`)
            Array.from(formContainerElem.querySelectorAll('.field'))
            .forEach(field => field.value = "");
            const mandatCopy = 
             {...mandat};
            
        mandatCopy[name] = value;

        setMandat(mandatCopy);

        }else{
            const mandatCopy = 
             {...mandat};
            
        mandatCopy[name] = value;

        setMandat(mandatCopy);
        }
        
    }
    
    const handleStepSubmit = e => {
        e.preventDefault();

        props.handleNextClick(e, 'mandat', JSON.stringify(mandat));
    }

 
    return (
        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? 'hidden' : ''}`}>
            <h4 className="intro-y col-span-12 text-lg leading-none">
            </h4>
            
                
                    <div className="intro-y col-span-12"  id={`${formContainer}-0`}>
                        <small>Indiquez les informations relatives au mandat</small>
                        <div className="mb-2">Quelle est la durée du mandat du gérant ?</div>
                        <div className="flex flex-col sm:flex-row mt-4 mb-2">
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2"> 
                                <input type="radio" className="input border mr-2" 
                                id={`mandat-oui`} 
                                name={`mandat`} value="non" 
                                onChange={e => handleInputChange("type-mandat","illimitée")}/> 
                                <label className="cursor-pointer select-none" 
                                htmlFor={`mandat-oui`}>
                                    Illimitée
                                </label> 
                            </div>
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0"> 
                                <input type="radio" className="input border mr-2" 
                                id={`mandat-non`} 
                                name={`mandat`} value="oui" 
                                onChange={e => handleInputChange("type-mandat","limité")}/> 
                                <label className="cursor-pointer select-none" 
                                htmlFor={`mandat-non`}>
                                    Limitée
                                </label> 
                            </div>
                        </div>
                        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${(mandat['type-mandat']!== undefined && 
                        mandat['type-mandat'] === 'illimitée') ? 'hidden'  :'' }`}>
                             <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2"></div>
                                <input type="text" className="input w-full border flex-1 field"
                                placeholder="Votre réponse" onChange={e => handleInputChange(
                                     'mandat-nom', e.target.value)}/>
                            </div>
                            
                        </div>
                       
                        
                    </div>
                
           
            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300" onClick={props.handleQuestionnaireBackClick ?? null}>
                    Revenir
                </button>
                <button className="button w-24 justify-center block bg-theme-1 
                text-white ml-2" onClick={handleStepSubmit}>
                    Suivant
                </button>
            </div>
        </div>
    )
}