import { useState } from 'react';
import { Services } from '../services';

export const useUser = () => {
    const [id, setId] = useState('');
	const [username, setUsername] = useState('');
	const [numero_telephone, setNumero_telephone] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [birthdate, setBirthdate] = useState('');
	const [sex, setSex] = useState('');
	const [address, setAddress] = useState('');
	const [profile_url, setProfile_url] = useState('');
	const [is_active, setIs_active] = useState('');
	const [categorie_user_id, setCategorie_user_id] = useState('');

	const [dossier, setDossier] = useState(null)
	

    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getUser = (signal) => {        
        return Services.UserService.getCurrentUser(signal)
        .then(response => {
            fillUser(response.user);
            setIsDisabled(false);
        });
    }

	const getDossier = signal => {
		return Services.UserService.getDossier(signal)
		 .then(response => {
			 setDossier(response.dossier);
		 })
	 } 

    const createUser = signal => {
        const payload = {
            username,
		numero_telephone,
		email,
		password,
		birthdate,
		sex,
		address,
		profile_url,
		is_active,
		categorie_user_id,
		
        };

        return Services.UserService.create(JSON.stringify(payload), signal);
    }
    const updateUser = (signal) => {
        const payload = {
            username,
		numero_telephone,
		email,
		birthdate,
		sex,
		address,
		profile_url
		
        };

        return Services.UserService.update(JSON.stringify(payload), signal);
    }
    const deleteUser = (userId, signal) => {
        return Services.UserService.destroy(userId, signal);
    }
    const fillUser = (user) => {
        setId(user.id);
        setUsername(user.username ?? '');
		setNumero_telephone(user.numero_telephone ?? "");
		setEmail(user.email ?? '');
		setPassword(user.password ?? '');
		setBirthdate(new Date(user.birthdate).toLocaleDateString('fr-ca') ?? '');
		setSex(user.sex ?? '');
		setAddress(user.address ?? '');
		setProfile_url(user.profile_url ?? '');
		setIs_active(user.is_active ?? '');
		setCategorie_user_id(user.categorie_user_id ?? '');
		setDossier(user.dossier ?? '');
		
    }
    const emptyUser = () => {
        setId('');
        setUsername('');
		setNumero_telephone('');
		setEmail('');
		setPassword('');
		setBirthdate('');
		setSex('');
		setAddress('');
		setProfile_url('');
		setIs_active('');
		setCategorie_user_id('');
		setDossier(null);
		
    }

    return {
        id,
        username,
		numero_telephone,
		email,
		password,
		birthdate,
		sex,
		address,
		profile_url,
		is_active,
		categorie_user_id,
		dossier,
		
        errors,
        isDisabled,
        setUsername,
		setNumero_telephone,
		setEmail,
		setPassword,
		setBirthdate,
		setSex,
		setAddress,
		setProfile_url,
		setIs_active,
		setCategorie_user_id,
		
        setId,
        setErrors,
        setIsDisabled,
        getUser,
		getDossier,
        createUser,
        updateUser,
        deleteUser,
        fillUser,
        emptyUser
    };
}