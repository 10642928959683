export const SARL = [
    {
        name: "numero-telephone",
        question: "Veuillez saisir votre numéro de téléphone (Whatsapp)",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre numéro de téléphone (Whatsapp) ", price: ""},
           
        ]
    },
    {
        name: "email",
        question: "Veuillez saisir votre adresse mail",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre adresse mail", price: ""},
           
        ]
    },
    {
        name: "nom",
        question: "Veuillez saisir votre nom",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre nom", price: ""},
           
        ]
    },
    {
        name: "prenoms",
        question: "Veuillez saisir votre prénom(s)",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre prénom(s)", price: ""},
           
        ]
    },
    {
        name: "verifiEntreprise",
        question: "Verifier si le nom choisir pour votre entreprise existe deja",
        description: "",
        type: "simple", //"mutiple",
        options: [
          // {value: "", price: ""}
        ],
      },
    
    {
        name: "denomination",
        question: "Veuillez entrer le nom de votre entreprise",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "secteur-activite",
        question: "Quel est votre secteur d’activité ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Commerce général", price: ""},
            {value: "Activités Immobilières", price: ""},
            {value: "Activités Informatiques", price: ""},
            {value: "Restauration", price: ""},
            {value: "Agence de voyage", price: ""},
            {value: "Transport VTC", price: ""},
            {value: "Agence de transfert d’argent", price: ""},
            {value: "Cabinet de conseil", price: ""},
            {value: "Autres", price: ""},
        ]
    },
    {
        name: "nombre-associes",
        question: "Combien d’associés êtes-vous ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "banque",
        question: "Dans quelle banque le compte sera-t-il ouvert ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "entreprise-anterieur",
        question: "Avez-vous déjà créé une entreprise ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Oui", price: ""},
            {value: "Non", price: ""}
        ]
    },
    {
        name: "date-reception-certificat",
        question: "Quand voulez-vous recevoir votre certificat d'immatriculation (IDU) ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "1 semaine", price: ""},
            {value: "Je ne sais pas encore", price: ""}
        ]
    },
    {
        name: "localisation-siege",
        question: "Où se situera le siège de votre entreprise ?",
        description: "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
        type: "mutiple", //"mutiple",
        options : [
            {value: "A mon domicile", price: ""},
            // {value: "Je domicilie chez Legafrik", price: ""},
            {value: "Je loue un local commercial", price: ""},
            {value: "Je n'ai pas de siège", price: ""}
        ]
    },
    {
        name: "capital-social",
        question: "Quel est le montant de votre capital social ?",
        description: "Pas de capital minimum exigé pour une SARL ou une SAS en Côte d'Ivoire",
        type: "mutiple", //"mutiple",
        options : [
            {value: "100 000 FCFA", price: ""},
            {value: "1 000 000 FCFA", price: ""},
            {value: "100 000 000 FCFA", price: ""},
            {value: "Autres", price: ""},
        ]
    },
    {
        name: "offre",
        question: "Quelle Offre choisissez-vous ?",
        type: "mutiple", //"mutiple",
        options : [
            {value: "À abidjan (129 000 FCFA)", price: 129_000},
            {value: "À l'interieur du pays (180 000 FCFA)", price: 180_000},
        ]
    },
    // {
    //     name: "localisation-siege",
    //     question: "Où se situera le siège de votre entreprise ?",
    //     description: "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
    //     type: "mutiple", //"mutiple",
    //     options : [
    //         {value: "A mon domicile .( Nous aurons obligatoirement besoin de votre contrat de Bail, ou l'attestation de propriété avec la mention du nom du propriétaire )", price: ""},
    //         {value: "Je domicilie chez Legafrik", price: ""},
    //         {value: "Je loue un local commercial", price: ""},
    //         {value: "Je n'ai pas de siège. ( Vous devez obligatoirement trouver un siège pour les formalités de créations de votre entreprise )", price: ""}
    //     ]
    // },
    {
        name: "gestion-dfe",
        question: "Souhaitez-vous que Legafrik s'occupe de votre formalité de rattachement fiscal et d'obtention de Déclaration Fiscale D'existence (DFE) ?",
        description: "Il est obligatoire d'effectuer une formalité de rattachement au centre d’impôt de votre entreprise dans les 10 jours suivant sa création sous peine d'une amende de 100 000 FCFA.",
        type: "mutiple", //"mutiple",
        options : [
            {
                value: "Je confie mon rattachement fiscal à Legafrik",
                price: 50_000,
              },
            //   {
            //     value: "Je confie à Légafrik sans rattachement  DFE (30 000 FCFA)",
            //     price: 30_000,
            //   },
              { value: "Je le gère moi-même", price: "" },
        ]
    },
    {
        name: "gestion-graphique",
        question: "Avez-vous pensé à donner une image de marque à votre entreprise avec un logo et une charte graphique faits par des professionnels ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Je le confie à Légafrik (59 000 FCFA)", price: 59_000},
            {value: "Je ne suis pas intéressé", price: ""},
        ]
    },
    {
        name: "gestion-facture-normalisees",
        question: "Souhaitez-vous que Legafrik s'occupe des formalités d'obtention de vos factures normalisées ?",
        description: "Toutes entreprises exerçant en en Côte d'Ivoire a l'obligation de délivrer des factures normalisées sous peine d'amende.",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Lot de 50 factures normalisées à 65 000 FCFA", price: 65_000},
            {value: "Lot de 100 factures normalisées à 90 000 FCFA", price: 90_000},
            {value: "Lot de 150 factures normalisées à 110 000 FCFA", price: 110_000},
            {value: "Lot de 200 factures normalisées à 130 000 FCFA", price: 130_000},
            {value: "Je ne suis pas intéressé", price: ""}
        ]
    },
    {
        name: "gestion-comptable",
        question: "Souhaitez-vous bénéficier de notre solution d'accompagnement comptable ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            { value: "Je confie ma compta à Legafrik (85 000/mois)", price: 255_000 },
            {value: "J'ai déjà un comptable", price: ""}
        ]
    },
]