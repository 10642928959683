import * as Icons from 'react-feather';

export function InfoBox(props) {
    // console.log('InfoBox', props.children);
    return (
        <div className="intro-y box lg:mt-5">
            {props.title ? 
                <div className="flex items-center justify-between px-0 py-5 sm:py-3 border-t-0 border-r-0 border-l-0 border-b 
                border-theme-1 mx-5 ">
                    <h2 className="font-medium text-xl text-theme-1 mr-auto">
                        {props.title}
                    </h2>
                    {props.hasEditIcon ? 
                        <Icons.Edit onClick={props.handleEditClick} />
                    : null}
                </div>
            : null}
            <div className="p-5">
                {props.children}
            </div>
    </div>
    )
}