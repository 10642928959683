import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import * as Icons from "react-feather";

export function ActiviteStep(props) {
  const formContainer = "activite-form-container";

  const [activite, setActivite] = useState("");

  const handleStepSubmit = (e) => {
    e.preventDefault();
    props.handleNextClick(e, "activites", activite);
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 box p-4
        ${props.step !== props.index ? "hidden" : ""}`}
      id={`${formContainer}`}
    >
      <div className="intro-y col-span-12 ">
        <div className="intro-y col-span-12  ">
          <strong>Quelles sont vos activités ?</strong>
        </div>
        <div className="intro-y col-span-12">
          <Alert variant="danger">
            <Alert.Heading>
              <Icons.Info className="w-4 h-4 " />
            </Alert.Heading>
            Renseignez les activités qui seront effectuées par votre organisation.
            Veuillez les séparer par un ";". Ex : Activité1; Activité2;
            Activité3
          </Alert>
        </div>
        <textarea
          placeholder="Votre réponse..."
          className={`input w-full border flex-1 field`}
          rows="5"
          cols="33"
          onChange={(e) => setActivite(e.target.value)}
        />
      </div>
      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
