export const SASU = [
    {
        name: "denomination",
        question: "Veuillez entrer le nom de votre entreprise",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "secteur-activite",
        question: "Quel est votre secteur d’activité ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Commerce général", price: ""},
            {value: "Activités Immobilières", price: ""},
            {value: "Activités Informatiques", price: ""},
            {value: "Restauration", price: ""},
            {value: "Agence de voyage", price: ""},
            {value: "Transport VTC", price: ""},
            {value: "Agence de transfert d’argent", price: ""},
            {value: "Cabinet de conseil", price: ""},
            {value: "Autres", price: ""},
        ]
    },
   
    {
        name: "banque",
        question: "Dans quelle banque le compte sera-t-il ouvert ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "entreprise-anterieur",
        question: "Avez-vous déjà créé une entreprise ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Oui", price: ""},
            {value: "Non", price: ""}
        ]
    },
    {
        name: "date-reception-registre-du-commerce",
        question: "Quand voulez-vous recevoir votre Registre du commerce ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "1 semaine", price: ""},
            {value: "Je ne sais pas encore", price: ""}
        ]
    },
    {
        name: "localisation-siege",
        question: "Où se situera le siège de votre entreprise ?",
        description: "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
        type: "mutiple", //"mutiple",
        options : [
            {value: "A mon domicile", price: ""},
            {value: "Je domicilie à Legafrik pour 6 mois à (150 000 FCFA)", price: 150_000},
            {value: "Je domicilie à Legafrik pour 12 mois à 300 000 FCFA", price: 300_000},
            {value: "Je loue un local commercial", price: ""},
            {value: "Je n'ai pas de siège", price: ""}
        ]
    },
    {
        name: "capital-social",
        question: "Quel est le montant de votre capital social ?",
        description: "Choisissez une formule",
        type: "mutiple", //"mutiple",
        options : [
            {value: "100 000 FCFA", price: ""},
            {value: "1 000 000 FCFA", price: ""},
            {value: "100 000 000 FCFA", price: ""},
            {value: "Autres", price: ""},
        ]
    },
    {
        name: "offre",
        question: "Quelle Offre choisissez-vous ?",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Capital inférieur ou égal à 500.000 FCFA (299 000 FCFA)", price: 299_000},
            {value: "Capital supperieur à 500.000 FCFA (Sur devis)", price: 299_000},
        ]
    }
]