import { useState } from 'react';
import { Services } from '../services';

export const useTypePaiement = () => {
    const [id, setId] = useState('');
	const [libelle, setLibelle] = useState('');

    
    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getTypePaiement = (typepaiementId, signal) => {        
        return Services.TypePaiementService.getById(typepaiementId, signal)
        .then(response => {
            fillTypePaiement(response.typepaiement);
            setIsDisabled(false);
        });
    }

    const createTypePaiement = signal => {
        const payload = {
            libelle,
		
        };

        return Services.TypePaiementService.create(JSON.stringify(payload), signal);
    }
    const updateTypePaiement = (typepaiementId, signal) => {
        const payload = {
            libelle,
		
        };

        return Services.TypePaiementService.update(typepaiementId, JSON.stringify(payload), signal);
    }
    const deleteTypePaiement = (typepaiementId, signal) => {
        return Services.TypePaiementService.destroy(typepaiementId, signal);
    }
    const fillTypePaiement = (typepaiement) => {
        setId(typepaiement.id);
        setLibelle(typepaiement.libelle ?? '');
		
    }
    const emptyTypePaiement = () => {
        setId('');
        setLibelle('');
		
    }

    return {
        id,
        libelle,
		
        errors,
        isDisabled,
        setLibelle,
		
        setId,
        setErrors,
        setIsDisabled,
        getTypePaiement,
        createTypePaiement,
        updateTypePaiement,
        deleteTypePaiement,
        fillTypePaiement,
        emptyTypePaiement
    };
}