import { useState } from "react";

export function SigleStepRecouvre(props) {
  // console.log("poppp", props);
  const formContainer = "sigle-form-container";

  // const [formJuridique, setFormJuridique] = useState("");
  const [sigle, setSigle] = useState("");

  // const [sigleType, setSigleType] = useState("");

  // const handleSigleChange = (value) => {
  //   if (value === "") {
  //     const formContainerElem = window.document.querySelector(
  //       `#${formContainer}`
  //     );

  //     Array.from(formContainerElem.querySelectorAll(".field")).forEach(
  //       (field) => (field.value = "")
  //     );

  //     setSigle("");
  //   }

  //   // setFormJuridique(value);
  // };
  const handleStepSubmit = (e) => {
    e.preventDefault();

    if (sigle) {
      // const sigleObject = { sigle, "sigle-obligatoire": "" };
      props.handleNextClick(
        e,
        "forme-juridique-debitrice",
        JSON.stringify(sigle)
      );
    } else {
      return alert("Vous devez remplir le formulaire !");
    }
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
      id={`${formContainer}`}
    >
      <h4 className="intro-y col-span-12 text-lg leading-none">
        Le nom de la société débitrice est : <strong>{props.nomSociete}</strong>
      </h4>
      <div className="intro-y col-span-12 ">
        <div className="intro-y col-span-12 text-lg">
          <strong>Quelle est sa forme juridique débitrice ?</strong>
        </div>
        <div className="intro-y col-span-12">
          {/* <Alert variant="danger">
            <Alert.Heading>
              {" "}
              <Icons.Info className="w-4 h-4 " />
            </Alert.Heading>
            <Alert.Link href="#">Le sigle</Alert.Link> d’une ong correspond à{" "}
            <Alert.Link href="#">
              un diminutif de la dénomination sociale
            </Alert.Link>
            . Le sigle peut être utile lorsque la dénomination sociale de la ong
            est longue.
          </Alert> */}
        </div>{" "}
        <div className="flex flex-col sm:flex-row mt-4 mb-2"></div>
        <input
          type="text"
          className={`input w-full border flex-1 field`}
          placeholder="Veuillez entrer la forme juridique débitrice"
          onChange={(e) => setSigle(e.target.value)}
        />
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        {/* <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300" onClick={props.handleBackClick ?? null}>
                    Revenir
                </button> */}
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          disabled={!sigle}
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
