// import { Components } from '..'

export function CodePromoForm(props) {
    return (
        <form className='form' disabled={props.isDisabled ?? false}
        onSubmit={props.handleFormSubmit ?? null}>
            <div className='row'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='pourcentage'>Pourcentage</label>
                        <input className='form-control' type='number' id='pourcentage' name='pourcentage' 
                        placeholder='Pourcentage' value={props.useCodePromo.pourcentage ?? ''}
                        disabled={props.isDisabled} 
                        onChange={ e => props.useCodePromo.setPourcentage(e.target.value) ?? null} required/>
                    </div>
                </div>
				
                <div className='col-12 text-right'>
                    <button disabled={props.isDisabled ?? false} type='submit' className='btn btn-primary' 
                    onClick={props.handleFormSubmit}>
                        <span>Enregistrer</span>
                    </button>
                </div>
            </div>
        </form>
    )
}