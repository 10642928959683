import { useState, useEffect } from "react";
import { Utils } from "../../../utils";
import Alert from "react-bootstrap/Alert";
import * as Icons from "react-feather";
import PhoneInput from "react-phone-input-2";

export function SecretaireGeneral(props) {
  // console.log("props==>", props);

  const formContainer = "associes-form-container";
  const nombreAssocies =
    props.nombreAssocies && Number.isInteger(parseInt(props.nombreAssocies))
      ? parseInt(props.nombreAssocies)
      : 0;
  const [associes, setAssocies] = useState({});
  const [typeSecretaire, setTypeSecretaire] = useState("");

  // const [isMeme, setIsMeme] = useState(100);
  var test = Utils.Auth.getUser();

  const handleTypeAssocieChange = (value) => {
    setTypeSecretaire(value);
  };
  const handleInputChange = (name, value) => {
    const associesCopy = { ...associes };

    associesCopy[name] = value;

    setAssocies(associesCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();
    const associesCopy = { ...associes };

    if (typeSecretaire === "moi-meme") {
      var ligne = test["username"].indexOf(" "); // Gets the first index where a space occours
      associesCopy["nom"] = test["username"].substr(0, ligne); // Gets the first part
      associesCopy["prenoms"] = test["username"].substr(ligne + 1);

      associesCopy["numero-telephone"] = test["numero-telephone"];
    }

    associesCopy["type-secretaire"] = typeSecretaire;

    props.handleNextClick(e, "secretaire", JSON.stringify(associesCopy));
  };

  useEffect(() => {
    if (!nombreAssocies) return;
    setAssocies(Array(nombreAssocies).fill({}));
  }, [nombreAssocies]);

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12  ">
        <strong>
          Qui est le Secrétaire
          {!props.demande.includes("SCOOP")
            ? " Général Bureau Exécutif "
            : " comité de gestion du SCOOP"}
          ?
        </strong>
      </div>
      <div className="intro-y col-span-12  ">
        <Alert variant="danger">
          <Alert.Heading>
            {" "}
            <Icons.Info className="w-4 h-4 " />
          </Alert.Heading>
          Renseignez les informations personnelles du Secrétaire
          {!props.demande.includes("SCOOP")
            ? " Général Bureau Exécutif "
            : " comité de gestion du SCOOP "}
          (y compris vous-mêmes si vous êtes associé). Si vous n’avez pas
          certaines informations, vous pourrez compléter plus tard
        </Alert>
      </div>

      <div className="intro-y col-span-12">
        <div className="intro-y col-span-12" id={`${formContainer}`}>
          <div className="grid grid-cols-12 mx-auto max-w-md  items-center mt-4 mb-2">
            <div
              className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in "
              id="main-select"
            >
              <input
                type="radio"
                className="overlay opacity-0"
                id={`horizontal-radio-moi-meme-${0}`}
                name={`associe-${0}`}
                value="moi-meme"
                onChange={(e) => handleTypeAssocieChange("moi-meme")}
              />

              <div className="ml-2 mr-auto">
                <div className="font-medium">Moi même</div>
              </div>

              <div className="radio-circle">
                <div className="radio-circle__outer-circle">
                  <div className="radio-circle__inner-circle"></div>
                </div>
              </div>
            </div>

            <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
              <input
                type="radio"
                className="overlay opacity-0"
                id={`horizontal-radio-particulier-${0}`}
                name={`associe-${0}`}
                value="particulier"
                onChange={(e) => handleTypeAssocieChange("particulier")}
              />
              <div className="radio-circle">
                <div className="radio-circle__outer-circle">
                  <div className="radio-circle__inner-circle"></div>
                </div>
              </div>

              <div className="ml-2 mr-auto">
                <div className="font-medium">Un particulier</div>
              </div>
            </div>
          </div>
          <div
            className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12
               ${
                 typeSecretaire !== undefined &&
                 (typeSecretaire === "moi-meme" ||
                   typeSecretaire === "particulier")
                   ? ""
                   : "hidden"
               }`}
          >
            {typeSecretaire === "particulier" ? (
              <>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <div className="mb-2">Nom</div>
                  <input
                    type="text"
                    className="input w-full border flex-1 field"
                    placeholder="Nom"
                    onChange={(e) => handleInputChange("nom", e.target.value)}
                  />
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <div className="mb-2">Prénom(s)</div>
                  <input
                    type="text"
                    className="input w-full border flex-1 field"
                    placeholder="Prénom(s)"
                    onChange={(e) =>
                      handleInputChange("prenoms", e.target.value)
                    }
                  />
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <div className="mb-2">Numéro de téléphone du Secrétaire </div>
                  <PhoneInput
                    country={"ci"}
                    // value={e.target.value
                    onChange={(e) => handleInputChange("numero-telephone", e)}
                    autoFormat={false}
                    inputStyle={{
                      padding: "21px1 20px 21px 52px",
                      width: "100%",
                      boxShadow: "0px 3px 5px #00000007",
                      borderColor: "rgba(226, 232, 240, 1)",
                    }}
                    placeholder="Numéro de téléphone"
                  />
                  {/* <input
                    type="text"
                    className="input w-full border flex-1 field"
                    placeholder="Numéro de téléphone du secrétaire"
                    onChange={(e) =>
                      handleInputChange("numero-telephone", e.target.value)
                    }
                  /> */}
                </div>
              </>
            ) : null}
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Nationalité du Secrétaire</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Nationalité du Secrétaire"
                onChange={(e) =>
                  handleInputChange("nationalite", e.target.value)
                }
              />
            </div>
            {props.demande.includes("SCOOP") && (
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nombre de part detenue</div>
                <input
                  type="number"
                  min={0}
                  className="input w-full border flex-1 field"
                  placeholder="Nombre de part detenue"
                  onChange={(e) =>
                    handleInputChange("nombre-de-part-detenue", e.target.value)
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
