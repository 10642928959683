import { Fragment } from "react";
import { Components } from ".";
import * as Icons from 'react-feather';

export function NewDemandeDropdownButton(props) {
    return (
         <Components.Dropdown>
            <button className="dropdown-toggle button flex items-center 
            inline-block bg-theme-1 text-white">
                Nouvelle commande <Icons.ChevronDown size={14} style={{marginLeft: "5px"}}/>
            </button>
            <Components.DropdownBox>
                {props.type_demandes.map((type_demande, index) => {
                    return (<Fragment key={index}>
                        <Components.DropdownItem link={`/commandes/creer/${type_demande.id}`}>
                            {type_demande.libelle}
                        </Components.DropdownItem>
                    </Fragment>)
                })}
            </Components.DropdownBox>
        </Components.Dropdown>
    )
}