import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export function LoginForm(props) {
  const navigate = useNavigate();

  return (
    <form name="loginForm" id="loginForm" onSubmit={props.handleSubmit}>
      <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
        Connectez-vous !
      </h2>
      <div className="intro-x mt-8">
        <input
          type="email"
          className="intro-x login__input input input--lg 
                border border-gray-300 block"
          placeholder="Email"
          id="username"
          name="username"
          value={props.email}
          onChange={(e) => props.setEmail(e.target.value)}
          disabled={props.isDisabled}
          required
        />

        <input
          type="password"
          className="intro-x login__input input input--lg 
                border border-gray-300 block mt-4"
          placeholder="Mot de passe"
          id="password"
          name="password"
          value={props.password}
          onChange={(e) => props.setPassword(e.target.value)}
          disabled={props.isDisabled}
          required
        />
      </div>
      <div className="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4">
        <div className="flex items-center mr-auto">
          <input
            type="checkbox"
            className="input border mr-2"
            id="remember-me"
          />
          <label className="cursor-pointer select-none" htmlFor="remember-me">
            Se souvenir de moi
          </label>
        </div>
        <Link to="/mot-de-passe">Mot de passe oublié?</Link>
      </div>
      <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
        <button
          className="button button--lg w-full xl:w-32 text-white 
                bg-theme-1 xl:mr-3 align-top"
          type="submit"
          onClick={props.handleSubmit}
          disabled={props.isDisabled}
        >
          {props.isDisabled ? "Chargement" : "Connexion"}
        </button>
        <button
          className="button button--lg w-full xl:w-40 text-gray-700 
                border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
          disabled={props.isDisabled}
          onClick={() => navigate("/inscription")}
        >
          Inscrivez-vous
        </button>
      </div>
      <div className="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left">
        En vous connectant, vous acceptez nos
        <br />
        <Link className="text-theme-1 dark:text-theme-10" to="/">
          Conditions d'utilisation
        </Link>{" "}
        &{" "}
        <Link className="text-theme-1 dark:text-theme-10" href="/">
          Politique de confidentialité
        </Link>
      </div>
    </form>
  );
}
