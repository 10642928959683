import { Api } from "./Api";

const ENPOINTS = {
  Hubspot: "hubspot",
};

const createLead = (payload = "", signal) => {
  return Api.post(`${ENPOINTS.Hubspot}/leads`, payload, signal);
};
const createLeads = (token = "", payload = "", signal) => {
  return Api.posts(`${ENPOINTS.Hubspot}/leads`, token, payload, signal);
};

export const HubspotService = {
  createLead,
  createLeads,
};
