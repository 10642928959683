// import { Components } from '..'

export function TypePaiementForm(props) {
    return (
        <form className='form' disabled={props.isDisabled ?? false}
        onSubmit={props.handleFormSubmit ?? null}>
            <div className='row'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='libelle'>Libelle</label>
                        <input className='form-control' type='text' id='libelle' name='libelle' 
                        placeholder='Libelle' value={props.useTypePaiement.libelle ?? ''}
                        disabled={props.isDisabled} onChange={ e => 
                        props.useTypePaiement.setLibelle(e.target.value) ?? null} required/>
                    </div>
                </div>
				
                <div className='col-12 text-right'>
                    <button disabled={props.isDisabled ?? false} type='submit' 
                    className='btn btn-primary' onClick={props.handleFormSubmit}>
                        <span>Enregistrer</span>
                    </button>
                </div>
            </div>
        </form>
    )
}