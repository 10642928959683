import { useState } from "react";

export function AdresseTwoStepRecouvre(props) {
  const [adresse, setAddresse] = useState({});

  const adresse1 = props?.adresseNew ? JSON.parse(props?.adresseNew) : "{}";

  const handleInputChange = (name, value) => {
    const adresseCopy = { ...adresse };
    adresseCopy[name] = value;

    setAddresse(adresseCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();

    const newAdresseCopy={...adresse1, ...adresse};

    // console.log("newAdresseCopy", newAdresseCopy);
    setAddresse(newAdresseCopy);

    props.handleNextClick(e, "adresse", JSON.stringify(newAdresseCopy));
  };
  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12 text-lg leading-none">
        <strong>Qui est votre interlocuteur dans cette société ?</strong>
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Civilité</div>
        <select
          type="text"
          className="input w-full border flex-1 field"
          onChange={(e) =>
            handleInputChange("civilite-interlocuteur", e.target.value)
          }
        >
          <option value="" className="text-gray-600">
            Veuillez selectionner  la civilité ?
          </option>
          <option value="monsieur">Monsieur</option>
          <option value="madame">Madame</option>
          <option value="mademoiselle">Mademoiselle</option>
        </select>
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Nom de l'interlocuteur</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Nom de l'interlocuteur"
          onChange={(e) =>
            handleInputChange("nom-interlocuteur", e.target.value)
          }
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Prénom(s) de l'interlocuteur</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Prénom(s) de l'interlocuteur"
          onChange={(e) =>
            handleInputChange("prenoms-interlocuteur", e.target.value)
          }
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Email de l'interlocuteur</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Email de l'interlocuteur"
          onChange={(e) =>
            handleInputChange("adresse-mail", e.target.value)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Téléphone</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Téléphone"
          onChange={(e) =>
            handleInputChange("numero-telephone-interlocuteur", e.target.value)
          }
        />
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
