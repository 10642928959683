import * as Icons from "react-feather";

export function DocumentList(props) {
  const handleDownloadLinkClick = (e, document) => {
    if (document.etat_document.libelle.includes("cours")) {
      e.preventDefault();
      return;
    }
  };
  return (
    <>
      {props.isDisabled || props.documents.length === 0 ? (
        <div className="intro-y col-span-12 my-10 text-center">
          Vos documents s'afficheront ci-dessous lorsqu'ils seront prêts...
        </div>
      ) : null}
      {props.documents.map((document, index) => {
        return (
          <div
            className="intro-y col-span-6 sm:col-span-4 md:col-span-3 
                    xxl:col-span-2"
            key={index}
          >
            <div
              className="file box rounded-md px-5 pt-8 pb-5 px-3 
                        sm:px-5 relative zoom-in"
            >
              <div className="absolute left-0 top-0 mt-3 ml-3">
                <Icons.Download />
              </div>
              <a
                href={document.document_url}
                target="_blank"
                rel="noreferrer"
                className="w-3/5 file__icon file__icon--directory mx-auto"
                onClick={(e) => handleDownloadLinkClick(e, document)}
              >
                {" "}
              </a>
              <a
                href={document.document_url}
                target="_blank"
                rel="noreferrer"
                className="block font-medium mt-4 text-center truncate"
                onClick={(e) => handleDownloadLinkClick(e, document)}
              >
                {document.type_document.libelle.includes("recouvrement")
                  ? document.titre_document
                  : document.type_document.libelle}
              </a>
              <div className="text-gray-600 text-xs text-center">
                {document.etat_document.libelle}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
