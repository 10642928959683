import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Components } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import { Hooks } from "../hooks";
import { Data } from "../data";
import { Services } from "../services";
import { useRef } from "react";
import { Utils } from "../utils";

export function DemandeEditView(props) {
    const abortController = useMemo(() => new AbortController(), []);
    const { ChampsDemande } = useMemo(() => Data, []);

    const { id } = useParams();
    const navigate = useNavigate();
    const useDemande = Hooks.useDemande();
    const usePaiement = Hooks.usePaiement();
    const demandeForm = useRef();

    const [champsDemandeData, setChampsDemandeData] = useState([]);
    const [champsDemandeObject, setChampsDemandeObject] = useState({});
    const [step, setStep] = useState(0);

    const handleNextClick = (e, name, value) => {
        e.preventDefault();

        let inputArray  = Array.from(document.querySelectorAll((`[name=${name}]`)));

        if ((!value || value === "") && inputArray[0].value !== "")
            value = inputArray[0].value;

        if (inputArray[0].type === 'radio' && (!value || value === "" || value === "on")) {
            const input = inputArray.find(inputElem => inputElem.checked === true);
            value = input?.nextElementSibling?.innerText ?? "";
        }

        const champsDemandeObjectCopy = {...champsDemandeObject};
        champsDemandeObjectCopy[name] = value;

        setChampsDemandeObject(champsDemandeObjectCopy);
        setStep(step + 1);
    }

    const handleBackClick = e => {
        e.preventDefault();

        if (step === 0 || useDemande.isDisabled) return;

        setStep(step - 1)
    }

    const handleSetAmount =  val => {
        usePaiement.setMontant(val);
        useDemande.setMontant_total(val);
    }

    const handleValidateClick = async e => {
        if (useDemande.isDisabled) return;
        useDemande.setIsDisabled(true);

        const {username, numero_telephone, email} = Utils.Auth.getUser();
        const lastname = username.split(" ")[0] ?? "";
        const firstname = username.split(lastname)[1] ?? "";

        champsDemandeObject['nom'] = lastname.trim();
        champsDemandeObject['prenoms'] = firstname.trim();
        champsDemandeObject['numero-telephone'] = numero_telephone;
        champsDemandeObject['adresse-mail'] = email;
        
        try {
            const payload = {
                type_demande_id: useDemande.type_demande_id,
                dossier_id: useDemande.dossier_id,
                pays_id: useDemande.pays_id,
                champs_demande: JSON.stringify(champsDemandeObject),
                montant_total: useDemande.montant_total
            };

            await Services.DemandeService.update(
                id, JSON.stringify(payload), abortController.signal);

            navigate(`/commandes/${id}`);
        } catch (error) {
            useDemande.setIsDisabled(false);
            if ('messages' in error)
                return; //Toast
        }
    }

    const initialize = useCallback(async() => {
        try {
            await useDemande.getDemande(id, abortController.signal);
        } catch (error) {
            if ('messages' in error) return; 
            //toast
        }
    }, [abortController, id])

    useEffect(() => {
        initialize();
    }, [initialize])

    useEffect(() => {
        if (!useDemande.pays?.code) return;

        const LIBELLE = useDemande.type_demande.libelle;
        const CODE_PAYS = useDemande.pays.code.toUpperCase();        

        if (LIBELLE.includes('SARL')) setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARL]);
        if (LIBELLE.includes('SARLU')) setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARLU]);
        if (LIBELLE.includes('SAS')) setChampsDemandeData([...ChampsDemande[CODE_PAYS].SAS]);
        if (LIBELLE.includes('SASU')) setChampsDemandeData([...ChampsDemande[CODE_PAYS].SASU]);
        if (LIBELLE.includes('ONG')) setChampsDemandeData([...ChampsDemande[CODE_PAYS].ONG]);
        if (LIBELLE.includes('Association')) 
            setChampsDemandeData([...ChampsDemande[CODE_PAYS].ASSOCIATION]);
        if (LIBELLE.includes('Fondation')) 
            setChampsDemandeData([...ChampsDemande[CODE_PAYS].FONDATION]);
        if (LIBELLE.includes('Domiciliation')) 
            setChampsDemandeData([...ChampsDemande[CODE_PAYS].DOMICILIATION]);
        if (LIBELLE.includes('Marque')) 
            setChampsDemandeData([...ChampsDemande[CODE_PAYS].DEPOT_MARQUE]);

        setStep(1);
    }, [useDemande.type_demande?.libelle, ChampsDemande, useDemande.pays?.code])
    useEffect(() => {
        if (champsDemandeData.length < 1) return;

        const champs_demande = Utils.String.parseJson(useDemande.champs_demande);
        Array.from(demandeForm.current.querySelectorAll('input'))
        .forEach(input => {
            if (input.type === "radio") {
                if (champs_demande[input.name] === 
                    input.nextElementSibling.innerText) input.checked = true;
            }else {
                input.value = champs_demande[input.name];
            }
        })
    },[champsDemandeData.length])

    return (
        <div className="col-span-12 mt-8">
            <h2 className="intro-y text-lg font-medium">
                Modifier la commande "{useDemande.type_demande?.libelle} {useDemande.pays?.libelle}"</h2>
            <div className="intro-y box py-10 sm:py-20 mt-" ref={demandeForm}>
                <div className="hidden sm:flex justify-center">
                    {champsDemandeData.map((champsDemandeDataItem, index) => {
                        return (
                            <button key={index} className={`intro-y w-10 h-10 rounded-full 
                            button ${step === index + 1 ? 'text-white bg-theme-1 mx-2': 
                            'bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2'}`}>
                                {index + 1}
                            </button>
                        )
                    })}
                </div>
                <Components.ProgressBar value={step} max={champsDemandeData.length} />
                {champsDemandeData.map((champsDemandeDataItem, index) => {
                    return (
                        <Fragment key={index}>
                            <Components.DemandeStep step={step} index={index + 1} 
                            length={champsDemandeData.length}
                            champsDemandeData={champsDemandeDataItem}
                            handleNextClick={handleNextClick} 
                            handleBackClick={handleBackClick}
                            champsDemandeObject={champsDemandeObject}/>
                        </Fragment>
                    )
                })}
                
                {(champsDemandeData.length > 0 && step - champsDemandeData.length === 1) ? 
                    <Components.Cart productList={champsDemandeData} 
                    selectedProductList={Object.keys(champsDemandeObject)
                        .map(key => { return {[key]: champsDemandeObject[key]}})}
                    handleRemoveProduct={null} handleBackClick={handleBackClick}
                    handleValidateClick={handleValidateClick} isDisabled={useDemande.isDisabled}
                    setAmount={handleSetAmount} amount={usePaiement.montant}
                    currency={useDemande.pays.monnaie}/>
                : null}
            </div>
        </div>
    )
}