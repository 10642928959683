import { useState } from "react";
import PhoneInput from "react-phone-input-2";

export function Commissaire(props) {
  const [adresse, setAddresse] = useState({});

  const handleInputChange = (name, value) => {
    const adresseCopy = { ...adresse };

    adresseCopy[name] = value;

    setAddresse(adresseCopy);
  };

  const handleStepSubmit = async (e) => {
    e.preventDefault();

    props.handleNextClick(e, "commissaire", JSON.stringify(adresse), false, 0);
  };
  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12">
        <strong>Quels sont les membres du Commissariat aux Comptes ?</strong>
      </div>
      <div className="intro-y col-span-12  "></div>
      <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12 ">
        <div className="intro-y col-span-12 sm:col-span-6">
          <div className="mb-2">
            Nom et Prénom(s) du Commissaire aux Comptes ?
          </div>
          <input
            type="text"
            className="input w-full border flex-1"
            placeholder="Nom et Prénom(s)"
            onChange={(e) => handleInputChange("nom", e.target.value)}
          />
        </div>
        <div className="intro-y col-span-12 sm:col-span-6">
          <div className="mb-2">Nationalité du Commissaire aux Comptes ?</div>
          <input
            type="text"
            className="input w-full border flex-1"
            placeholder="Nationalité du Commissaire"
            onChange={(e) => handleInputChange("nationalite", e.target.value)}
          />
        </div>

        <div className="intro-y col-span-12 sm:col-span-6">
          <div className="mb-2">
            Numéro de téléphone du Commissaire aux Comptes ?
          </div>
          {/* <input
            type="text"
            className="input w-full border flex-1"
            placeholder="Numéro de téléphone"
            onChange={(e) =>
              handleInputChange("numero-telephone", e.target.value)
            }
          /> */}
          <PhoneInput
            country={"ci"}
            // value={e.target.value
            onChange={(e) => handleInputChange("numero-telephone", e)}
            autoFormat={false}
            inputStyle={{
              padding: "21px1 20px 21px 52px",
              width: "100%",
              boxShadow: "0px 3px 5px #00000007",
              borderColor: "rgba(226, 232, 240, 1)",
            }}
            placeholder="Numéro de téléphone"
          />
        </div>
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
