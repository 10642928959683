import { useCallback, useEffect, useMemo, useState } from "react";
import { Services } from "../services";
import { Components } from ".";

export function LivraisonStep(props) {
    const abortController = useMemo(() => new AbortController(), []);

    const [documents, setDocuments] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);

    const initialize = useCallback(async () => {
        try {
            const {documents} = await Services.DemandeService.getDocuments(
                props.useDemande.id, abortController.signal, props.etapeTraitement.id);
            setDocuments(documents)
        } catch (error) {
            if ('messages' in error) return;
                //Toaster
        } finally {
            setIsDisabled(false);
            props.useDemande.setIsDisabled(false);
        }

      },
      [abortController],
    )
    
    useEffect(() => {
        initialize();
    }, [initialize])

    return (
        <div className="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-10 col-span-12" 
        style={{animationName: "none", opacity: 1, transform: "none"}}>
            <Components.DocumentList documents={documents} isDisabled={isDisabled}/>
        </div>
    )
}