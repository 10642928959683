import * as Icons from "react-feather";
import { Components } from "../components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Services } from "../services";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../components/Loader/Loading";

export function DemandeListView(props) {
  const abortController = useMemo(() => new AbortController(), []);

  const tableAttributes = {
    reference: {},
    demande: {},
    date: { thClassName: "text-center", tdClassName: "text-center" },
    etat: {
      thClassName: "text-center",
      tdClassName: "sm:w-0 md:w-40 lg:w-40 text-center",
    },
    paiement: {},
  };

  const navigate = useNavigate();

  const [tableActions, ] = useState(["read"]);
  const [demandes, setDemandes] = useState([]);
  const [etat_demandes, setEtat_demandes] = useState([]);
  const [demandeTableData, setDemandeTableData] = useState([]);
  const [etatDemande, setEtatDemande] = useState("");
  const [page, setPage] = useState(1);
  const [stateLoading, setStateLoading] = useState(false);
  const [totalDemande, setTotalDemande] = useState(0);

  const handleReadClick = (e, demande) =>
    navigate(`/commandes/${demande.id}/etape-traitements`);
  const handlePageClick = (number) => {
    setPage((page) => page + number);
  };

  const handleVoirDetailClick = (e, demande) =>
    navigate(`/commandes/${demande.id}`);
  const handleVoirSoleClick = (e, demande) =>
    navigate(`/commandes/${demande.id}/solde`);

  const initialize = useCallback(async () => {
    setStateLoading(true);
    setDemandes([]);

    try {
      const { demandes } = await Services.UserService.getDemandes(
        abortController.signal,
        page
      );
      const { total } = await Services.UserService.getDemandes(
        abortController.signal
      );
      setTotalDemande(total);
      if (page === 1) {
        const { etat_demandes } = await Services.EtatDemandeService.getAll(
          abortController.signal
        );
        setEtat_demandes(
          etat_demandes.filter(
            (etat_demande) => !etat_demande.libelle.includes("Init")
          )
        );
        setEtatDemande(etat_demandes[1].libelle);
      }

      setDemandes(demandes);
      setStateLoading(false);

      if (demandes.length < 1) window.location.replace("/");
    } catch (error) {
      if ("messages" in error) return;
      //Toat
    }
  }, [abortController, page]);

  useEffect(() => {
    if (demandes.length < 1) return;

    const demandesCopy = demandes
      .filter((demande) => {
        const etatDemandeLibelle = demande.etat_demande.libelle.toLowerCase();
        if (!etatDemande.toLowerCase().includes("fin")) {
          return (
            etatDemandeLibelle.includes("ini") ||
            etatDemandeLibelle.includes("cours")
          );
        } else {
          return etatDemandeLibelle.includes("fin");
        }
      })
      .map((demande) => {
        let etatText = "";
        let montant_paye = demande.montant_paye ?? 0;
        let montant_total = demande.montant_total ?? 0;
        let remaining_amount = montant_total - montant_paye;

        if (montant_paye === 0) {
          etatText = (
            <span
              className="px-3 py-1 rounded-full 
                    text-white bg-theme-6"
            >
              À&nbsp;payer
            </span>
          );
        } else {
          if (remaining_amount <= 0) {
            etatText = (
              <span
                className="px-3 py-1 rounded-full 
                        text-white bg-theme-9"
              >
                Payé
              </span>
            );
          } else {
            etatText = (
              <span
                className="px-3 py-1 rounded-full 
                        text-white bg-theme-11"
              >
                À&nbsp;solder
              </span>
            );
          }
        }

        return {
          id: demande.id,
          reference: demande.numero_demande,
          demande: (
            <Link
              to={`/commandes/${demande.id}`}
              className="text-theme-1 "
              title="Voir les details de la commande"
            >
              {demande.type_demande.libelle}
            </Link>
          ),
          date: new Date(demande.created_at).toLocaleDateString("fr", {
            dateStyle: "medium",
          }),
          etat: etatText,
          canShow: montant_paye > 0,
          paiement: (
            <button
              className="flex items-center text-theme-1"
              onClick={(e) => navigate(`/commandes/${demande.id}/solde`)}
            >
              <Icons.CreditCard className="w-4 h-4 mr-1" /> Payer
            </button>
          ),
        };
      });

    setDemandeTableData(demandesCopy);
  }, [etatDemande, demandes, navigate]);

  // console.log("stateLoading", stateLoading);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <div className="col-span-12 mt-8">
      <h2 className="intro-y text-lg font-medium">Liste de vos commandes</h2>
      <i>
        <h6 className="intro-y my-2">
          Vous avez
          <span
            className="p-1 mx-1 rounded-full 
                    text-white bg-theme-6"
          >
            {totalDemande}
          </span>
          demande{totalDemande > 0 ? "s" : ""}
        </h6>
      </i>{" "}
      <div className="grid grid-cols-12 gap-6 mt-5">
        <div
          className="intro-y col-span-12 flex flex-wrap sm:flex-no-wrap 
                items-center mt-2 justify-between"
        >
          <div className="box p-2 chat">
            <div className="chat__tabs nav-tabs justify-center flex">
              {etat_demandes.map((etat_demande, index) => {
                return (
                  <button
                    className={`flex-1 py-2 rounded-md text-center px-5 
                                sm:px-10 ${
                                  etatDemande === etat_demande.libelle
                                    ? "active"
                                    : ""
                                }`}
                    key={index}
                    style={{ whiteSpace: "nowrap" }}
                    onClick={(e) => setEtatDemande(etat_demande.libelle)}
                  >
                    <>
                      {etat_demande.libelle.toLowerCase().includes("fin")
                        ? "Terminées"
                        : "En cours"}
                    </>
                  </button>
                );
              })}
            </div>
          </div>
          {/* <div className="hidden md:block mx-auto text-gray-600">
                        Affichage de 1 à 10 sur 150 entrées
                    </div> */}
          <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <div className="w-full sm:w-56 relative text-gray-700 dark:text-gray-300">
              <Link
                to="/"
                className="button justify-center block bg-theme-1 
                            text-white ml-2"
              >
                Nouvelle commande
              </Link>
            </div>
          </div>
        </div>
        {stateLoading ? (
          <div className="col-span-12 text-center -intro-y mt-24">
            <Loading />
            </div>
        ) : (
          <>
            <div className="intro-y flex items-center col-span-12 overflow-auto lg:overflow-visible">
              <Components.Table
                tableAttributes={tableAttributes}
                tableData={demandeTableData}
                tableActions={tableActions}
                controllers={{
                  handleEditClick: null,
                  handleReadClick,
                  handleVoirSoleClick,
                  handleVoirDetailClick,
                  handleDeleteClick: null,
                }}
              />
            </div>
            <div className="flex col-span-12">
              <button
                className="button flex items-center bg-theme-1 text-white px-4 mr-4"
                onClick={(e) => handlePageClick(-1)}
                disabled={page === 1}
              >
                <Icons.ArrowLeft className="h-4" /> Précédent
              </button>
              <button
                disabled={demandes.length < 1}
                className="button flex items-center bg-theme-1 text-white px-4"
                onClick={(e) => handlePageClick(1)}
              >
                Suivant <Icons.ArrowRight className="h-4" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
