import { useState } from 'react';
import { Services } from '../services';

export const useTypePiece = () => {
    const [id, setId] = useState('');
	const [libelle, setLibelle] = useState('');
	

    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getTypePiece = (typepieceId, signal) => {        
        return Services.TypePieceService.getById(typepieceId, signal)
        .then(response => {
            fillTypePiece(response.typepiece);
            setIsDisabled(false);
        });
    }

    const createTypePiece = signal => {
        const payload = {
            libelle,
		
        };

        return Services.TypePieceService.create(JSON.stringify(payload), signal);
    }
    const updateTypePiece = (typepieceId, signal) => {
        const payload = {
            libelle,
		
        };

        return Services.TypePieceService.update(typepieceId, JSON.stringify(payload), signal);
    }
    const deleteTypePiece = (typepieceId, signal) => {
        return Services.TypePieceService.destroy(typepieceId, signal);
    }
    const fillTypePiece = (typepiece) => {
        setId(typepiece.id);
        setLibelle(typepiece.libelle ?? '');
		
    }
    const emptyTypePiece = () => {
        setId('');
        setLibelle('');
		
    }

    return {
        id,
        libelle,
		
        errors,
        isDisabled,
        setLibelle,
		
        setId,
        setErrors,
        setIsDisabled,
        getTypePiece,
        createTypePiece,
        updateTypePiece,
        deleteTypePiece,
        fillTypePiece,
        emptyTypePiece
    };
}