import { CI  } from "./ci";
import { SN  } from "./sn";
import { BF  } from "./bf";
import { BN  } from "./bn";
import { RDC  } from "./rdc";
import { MR  } from "./ma";
import { CM  } from "./cm";
import { GN  } from "./gn";

export const QuestionnairesForms = {
    CI,
    SN,
    BF,
    BN,
    RDC,
    MR,
    CM,
    GN
}