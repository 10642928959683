import { Api } from './Api';

const  ENPOINTS = {
    EtapeTraitement: 'etape-traitements',
};

const getAll = signal => {
    return Api.get(ENPOINTS.EtapeTraitement, signal)
}

const getAllEtatTraitementsById = (id, signal) => {
    return Api.get(`user/${ENPOINTS.EtapeTraitement}/${id}/etat-etape-traitements`, signal)
}

const getById = (id, signal) => {
    return Api.get(`${ENPOINTS.EtapeTraitement}/${id}`, signal);
}

const create = (payload, signal) => {
    return Api.post(ENPOINTS.EtapeTraitement, payload, signal)
}

const update = (id, payload, signal) => {
    return Api.put(`${ENPOINTS.EtapeTraitement}/${id}`, payload, signal)
}
const destroy = (id, signal) => {
    return Api.erase(`${ENPOINTS.EtapeTraitement}/${id}`, signal)
}

export const EtapeTraitementService = {
    getAll,
    getAllEtatTraitementsById,
    getById,
    create,
    update,
    destroy,
}