import { TestView } from "./TestView";
import { DashboardView } from "./DashbaordView";
import { LoginView } from "./LoginView";
import { PasswordView } from "./PasswordView";
import { PasswordViews } from "./PasswordViews";

import { AideView } from "./AideView";
import { RegisterView } from "./RegisterView";
import { DemandeListView } from "./DemandeListView";
import { DocumentListView } from "./DocumentListView";
import { DemandeDetailView } from "./DemandeDetailView";
import { DemandeCreateView } from "./DemandeCreateView";
import { DemandeCreateViewInitiale } from "./DemandeCreateViewInitiale";
import { DemandeEtapeTraitementView } from "./DemandeEtapeTraitementView";
import { DemandePaiementView } from "./DemandePaiementView";
import { PaiementView } from "./PaiementView";
import { PaiementCheckoutView } from "./PaiementCheckoutView";
import { ProfileView } from "./ProfileView";
import { NewPasswordView } from "./NewPasswordView";
import { DemandeEditView } from "./DemandeEditView";
import { MailView } from "./MailView";
import SuiviDemande from "./SuiviDemande";
import QuestionnaireSuivi from "./QuestionnaireSuivi";

export const Views = {
  SuiviDemande,
  QuestionnaireSuivi,
  TestView,
  DashboardView,
  LoginView,
  RegisterView,
  DemandeListView,
  DocumentListView,
  DemandeDetailView,
  DemandeCreateView,
  DemandeCreateViewInitiale,
  DemandeEtapeTraitementView,
  DemandePaiementView,
  PasswordView,
  PasswordViews,
  PaiementView,
  ProfileView,
  NewPasswordView,
  DemandeEditView,
  AideView,
  MailView,
  PaiementCheckoutView,
};
