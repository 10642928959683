import { useState } from 'react';
import { Services } from '../services';

export const useEtatDocument = () => {
    const [id, setId] = useState('');
	const [libelle, setLibelle] = useState('');
	

    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getEtatDocument = (etatdocumentId, signal) => {        
        return Services.EtatDocumentService.getById(etatdocumentId, signal)
        .then(response => {
            fillEtatDocument(response.etatdocument);
            setIsDisabled(false);
        });
    }

    const createEtatDocument = signal => {
        const payload = {
            libelle,
		
        };

        return Services.EtatDocumentService.create(JSON.stringify(payload), signal);
    }
    const updateEtatDocument = (etatdocumentId, signal) => {
        const payload = {
            libelle,
		
        };

        return Services.EtatDocumentService.update(etatdocumentId, JSON.stringify(payload), signal);
    }
    const deleteEtatDocument = (etatdocumentId, signal) => {
        return Services.EtatDocumentService.destroy(etatdocumentId, signal);
    }
    const fillEtatDocument = (etatdocument) => {
        setId(etatdocument.id);
        setLibelle(etatdocument.libelle ?? '');
		
    }
    const emptyEtatDocument = () => {
        setId('');
        setLibelle('');
		
    }

    return {
        id,
        libelle,
		
        errors,
        isDisabled,
        setLibelle,
		
        setId,
        setErrors,
        setIsDisabled,
        getEtatDocument,
        createEtatDocument,
        updateEtatDocument,
        deleteEtatDocument,
        fillEtatDocument,
        emptyEtatDocument
    };
}