import { useEffect, useState } from "react";
import { Utils } from "../../../utils";
import PhoneInput from "react-phone-input-2";

export function GerantStep(props) {
  // console.log("props", props);
  const formContainer = "gerants-form-container";
  const associes = props.nombreAssocies || JSON.parse(props.associes);

  const [nombreGerant, setNombreGerant] = useState("");
  const [gerants, setGerants] = useState([]);

  const handleInputChange = (index, name, value) => {
    const gerantsCopy = gerants.map((gerrant) => {
      return { ...gerrant };
    });

    if (name === "associe-gerant") {
      gerantsCopy[index] = { "associe-gerant": "oui", ...JSON.parse(value) };
    } else {
      gerantsCopy[index]["type"] = "moi-meme";
      gerantsCopy[index][name] = value;
    }

    setGerants(gerantsCopy);
  };
  const handleTypeGerantChange = (index, value) => {
    const gerantsCopy = gerants.map((gerant) => {
      return { ...gerant };
    });
    gerantsCopy[index] = {};
    gerantsCopy[index]["associe-gerant"] = value;

    const formContainerElem = window.document.querySelector(
      `#${formContainer}-${index}`
    );
    Array.from(formContainerElem.querySelectorAll(".field")).forEach(
      (field) => (field.value = "")
    );

    setGerants(gerantsCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();

    if (!nombreGerant) return alert("Vous devez remplir le formulaire !");
    props.handleNextClick(e, "gerants", JSON.stringify(gerants));
  };

  useEffect(() => {
    if (!nombreGerant) return;
    setGerants(Array(parseInt(nombreGerant)).fill({}));
  }, [nombreGerant]);
  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 box p-4 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      {/* <h4 className="intro-y col-span-12 text-lg leading-none"></h4> */}
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Combien de gérants voulez-vous nommer ? </div>
        <input
          type="number"
          className="input w-full border flex-1"
          value={nombreGerant}
          min={0}
          max={4}
          placeholder="Combien de gérants voulez-vous nommer ?"
          onChange={(e) => setNombreGerant(e.target.value)}
        />
      </div>
      {gerants.map((gerant, index) => {
        return (
          <div
            className="intro-y col-span-12"
            key={index}
            id={`${formContainer}-${index}`}
          >
            <small>
              Indiquez les informations relatives au gérant {index + 1}
            </small>
            <div className="mb-2">
              Le gérant (numéro {index + 1}) fait partie des Associés ?
            </div>
            <div className="flex flex-col sm:flex-row mt-4 mb-2">
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                <input
                  type="radio"
                  className="input border mr-2"
                  id={`horizontal-radio-oui-${index}`}
                  name={`gerant-${index}`}
                  value="non"
                  onChange={(e) => handleTypeGerantChange(index, "oui")}
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`horizontal-radio-oui-${index}`}
                >
                  Oui
                </label>
              </div>
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                <input
                  type="radio"
                  className="input border mr-2"
                  id={`horizontal-non-${index}`}
                  name={`gerant-${index}`}
                  value="oui"
                  onChange={(e) => handleTypeGerantChange(index, "non")}
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`horizontal-non-${index}`}
                >
                  Non
                </label>
              </div>
            </div>
            <div
              className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${
                          gerant["associe-gerant"] !== undefined &&
                          gerant["associe-gerant"] === "oui"
                            ? ""
                            : "hidden"
                        }`}
            >
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Indiquez l’associé gérant</div>
                <select
                  type="text"
                  className="input w-full border flex-1 field"
                  onChange={(e) =>
                    handleInputChange(index, "associe-gerant", e.target.value)
                  }
                >
                  {associes.map((associe, index) => {
                    return (
                      <option value={JSON.stringify(associe)} key={index}>
                        {Utils.Demande.getAssocieName(associe)}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom & Prénom(s) du père du gérant</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nom & Prénom(s) du père du gérant"
                  onChange={(e) =>
                    handleInputChange(index, "nom-pere", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom & Prénom(s) de la mère du gérant</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nom & Prénom(s) de la mère du  gérant"
                  onChange={(e) =>
                    handleInputChange(index, "nom-mere", e.target.value)
                  }
                />
              </div>
            </div>
            <div
              className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${
                          gerant["associe-gerant"] !== undefined &&
                          gerant["associe-gerant"] === "non"
                            ? ""
                            : "hidden"
                        }`}
            >
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">
                  Numéro de votre pièce d’identité{" "}
                  <small>
                    (les anciennes pièces ne sont pas prises en compte )
                  </small>
                </div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder=" Numéro de votre pièce d’identité"
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "numero-piece-identite",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Type de la piece d'identité</div>
                <select
                  type="text"
                  className="input w-full border flex-1 field"
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "type-piece-identite",
                      e.target.value
                    )
                  }
                >
                  <option value="CNI">CNI</option>
                  <option value="Passeport">Passeport</option>
                  <option value="Attestation d'identité">
                    Attestation d'identité
                  </option>
                  <option value="Titre de séjour">Titre de séjour</option>
                  <option value="Carte de résident">Carte de résident</option>
                  <option value="Autres">Autres</option>
                </select>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Pays de délivrance de la pièce</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Pays de délivrance de la pièce"
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "pays-piece-identite",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Date d’établissement de la pièce</div>
                <input
                  type="date"
                  className="input w-full border flex-1 field"
                  placeholder="Date d’établissement de la pièce"
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "date-piece-identite",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Date de validité de la pièce</div>
                <input
                  type="date"
                  className="input w-full border flex-1 field"
                  placeholder="Date de validité de la pièce"
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "date-fin-validite-piece-identite",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Nationalite</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nationalite"
                  onChange={(e) =>
                    handleInputChange(index, "nationalite", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Adresse</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Adresse"
                  onChange={(e) =>
                    handleInputChange(index, "adresse", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Civilité</div>
                <select
                  type="text"
                  className="input w-full border flex-1 field"
                  onChange={(e) =>
                    handleInputChange(index, "civilite", e.target.value)
                  }
                >
                  <option value="monsieur">Monsieur</option>
                  <option value="madame">Madame</option>
                  <option value="mademoiselle">Mademoiselle</option>
                </select>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nom"
                  onChange={(e) =>
                    handleInputChange(index, "nom", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Prenom</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Prenom"
                  onChange={(e) =>
                    handleInputChange(index, "prenoms", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de téléphone (whatsapp) </div>
                {/* <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Numéro de téléphone (whatsapp)"
                  onChange={(e) =>
                    handleInputChange(index, "numero-telephone", e.target.value)
                  }
                /> */}
                <PhoneInput
                  country={"ci"}
                  // value={e.target.value
                  onChange={(e) =>
                    handleInputChange(index, "numero-telephone", e)
                  }
                  autoFormat={false}
                  inputStyle={{
                    padding: "21px1 20px 21px 52px",
                    width: "100%",
                    boxShadow: "0px 3px 5px #00000007",
                    borderColor: "rgba(226, 232, 240, 1)",
                  }}
                  placeholder="Numéro de téléphone"
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Adresse mail </div>
                <input
                  type="email"
                  className="input w-full border flex-1 field"
                  placeholder="adresse mail"
                  onChange={(e) =>
                    handleInputChange(index, "adresse-mail", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Date de naissance</div>
                <input
                  type="date"
                  className="input w-full border flex-1 field"
                  placeholder="Date de naissance"
                  onChange={(e) =>
                    handleInputChange(index, "date-naissance", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Lieu de naissance</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Lieu de naissance"
                  onChange={(e) =>
                    handleInputChange(index, "lieu-naissance", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom & Prénom(s) du père du gérant</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nom & Prénom(s) du père du gérant"
                  onChange={(e) =>
                    handleInputChange(index, "nom-pere", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom & Prénom(s) de la mère du gérant</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nom & Prénom(s) de la mère du  gérant"
                  onChange={(e) =>
                    handleInputChange(index, "nom-mere", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        );
      })}
      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
          disabled={props.isDisabled}
        >
          Revenir
        </button>
        <button
          className="button justify-center block bg-theme-1 
                text-white ml-2 px-6"
          onClick={handleStepSubmit}
          disabled={props.isDisabled}
        >
          {props.isDisabled ? "Chargement en cours..." : "Suivant"}
        </button>
      </div>
    </div>
  );
}
