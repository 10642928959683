export const ONG = [
  {
    name: "denomination",
    question: "Quel est le nom de votre ONG ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
//   {
//     name: "Voulez-vous denomicilier votre ONG ?",
//     question: "Quel est le nom de votre ONG ?",
//     description: "",
//     type: "simple", //"mutiple",
//     options: [
//       { value: "Oui", stateDomicilie: true },
//       { value: "Non", stateDomicilie: false },
//     ],
//   },
  {
    name: "secteur-activite",
    question: "Quel est votre domaine d’action ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Santé", price: "" },
      { value: "Action sociale", price: "" },
      { value: "Humanitaire", price: "" },
      { value: "Environnement", price: "" },
      { value: "Art & Culture", price: "" },
      { value: "Religion", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  {
    name: "est-president",
    question: "Êtes vous le président ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Oui", price: "" },
      { value: "Non", price: "" },
    ],
  },
  {
    name: "offre",
    question: "Quelle Offre choisissez-vous ?",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Abidjan (150 000 FCFA)", price: 150_000 },
      { value: "Interieur du pays (200 000 FCFA)", price: 280_000 },
    ],
  },
];
