import { useState } from 'react';
import { Services } from '../services';

export const usePays = () => {
    const [id, setId] = useState('');
	const [libelle, setLibelle] = useState('');
	

    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getPays = (paysId, signal) => {        
        return Services.PaysService.getById(paysId, signal)
        .then(response => {
            fillPays(response.pays);
            setIsDisabled(false);
        });
    }

    const createPays = signal => {
        const payload = {
            libelle,
		
        };

        return Services.PaysService.create(JSON.stringify(payload), signal);
    }
    const updatePays = (paysId, signal) => {
        const payload = {
            libelle,
		
        };

        return Services.PaysService.update(paysId, JSON.stringify(payload), signal);
    }
    const deletePays = (paysId, signal) => {
        return Services.PaysService.destroy(paysId, signal);
    }
    const fillPays = (pays) => {
        setId(pays.id);
        setLibelle(pays.libelle ?? '');
		
    }
    const emptyPays = () => {
        setId('');
        setLibelle('');
		
    }

    return {
        id,
        libelle,
		
        errors,
        isDisabled,
        setLibelle,
		
        setId,
        setErrors,
        setIsDisabled,
        getPays,
        createPays,
        updatePays,
        deletePays,
        fillPays,
        emptyPays
    };
}