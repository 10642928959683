import { useEffect } from "react"

export function OptionalStep(props) {
    useEffect(() => {
        if (props.isOptional === undefined || props.isOptional === null) return;
        if (props.isOptional === true) props.callback();
    }, [props.isOptional])
    return (
        <>{props.children}</>
    )
}