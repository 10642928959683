export function ProgressBar(props) {
    // console.log('props',props);
  return (
    <div className="col-span-12 sm:d-bloc md:d-bloc px-5">
      <div className="mb-1">
        <small>
          {props.label ? props.label : "Progression"} 
         {!props.className?(props.value <= props.max ? ` (${props.value} / ${props.max})`  : props.max / props.max ):null} 
        </small>
      </div>
      <progress
        className="rounded-full w-full"
        value={props.value}
        max={props.max}
      />
    </div>
  );
}
