import * as Icons from 'react-feather';

export function ErrorMessages(props) {

    if (props.children.length <= 0) return null;

    const AlertIcon = <Icons.AlertOctagon className='w-6 h-6 mr-2'/>;

    return (
        <>
            {props.children instanceof Array ? 
                props.children.map((item, index) => {
                    return (
                        <div className="rounded-md flex items-center px-5 py-4 
                        mb-2 bg-theme-31 text-theme-6" key={index}>
                            {AlertIcon} {item}
                        </div>
                    )
                }) : 
                <div className="rounded-md flex items-center px-5 py-4 mb-2 
                bg-theme-31 text-theme-6">
                    {AlertIcon} {props.children}
                </div>
                }
        </>
    )
    
}