import { useState } from 'react';
import { Services } from '../services';

export const useTypeDocument = () => {
    const [id, setId] = useState('');
	const [libelle, setLibelle] = useState('');
	

    const [errors, setErrors] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getTypeDocument = (typedocumentId, signal) => {        
        return Services.TypeDocumentService.getById(typedocumentId, signal)
        .then(response => {
            fillTypeDocument(response.typedocument);
            setIsDisabled(false);
        });
    }

    const createTypeDocument = signal => {
        const payload = {
            libelle,
		
        };

        return Services.TypeDocumentService.create(JSON.stringify(payload), signal);
    }
    const updateTypeDocument = (typedocumentId, signal) => {
        const payload = {
            libelle,
		
        };

        return Services.TypeDocumentService.update(typedocumentId, JSON.stringify(payload), signal);
    }
    const deleteTypeDocument = (typedocumentId, signal) => {
        return Services.TypeDocumentService.destroy(typedocumentId, signal);
    }
    const fillTypeDocument = (typedocument) => {
        setId(typedocument.id);
        setLibelle(typedocument.libelle ?? '');
		
    }
    const emptyTypeDocument = () => {
        setId('');
        setLibelle('');
		
    }

    return {
        id,
        libelle,
		
        errors,
        isDisabled,
        setLibelle,
		
        setId,
        setErrors,
        setIsDisabled,
        getTypeDocument,
        createTypeDocument,
        updateTypeDocument,
        deleteTypeDocument,
        fillTypeDocument,
        emptyTypeDocument
    };
}