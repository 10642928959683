import { useCallback, useEffect, useMemo, useState } from "react";
import { Services } from "../services";
import { Components } from ".";

export function LivraisonDfeStep(props) {
    const abortController = useMemo(() => new AbortController(), []);

    const [documents, setDocuments] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);

    const initialize = useCallback(async () => {
        try {
            const {documents} = await Services.DemandeService.getDocuments(
                props.useDemande.id, abortController.signal, props.etapeTraitement.id);
            setDocuments(documents)
        } catch (error) {
            if ('messages' in error) return;
                //Toaster
        } finally {
            setIsDisabled(false);
            props.useDemande.setIsDisabled(false);
        }
      },
      [abortController],
    )
    
    useEffect(() => {
        initialize();
    }, [initialize])

    return (
        <div className="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-10 col-span-12" 
        style={{animationName: "none", opacity: 1, transform: "none"}}>
            {/* <div className="md:col-span-6 col-span-12">
                <div className="px-5 md:px-20">
                    {etat_etape_traitements.map((etat_etape_traitement, index) => {
                        return (
                            <div className="intro-x flex items-center my-4" key={index}>
                                <button className={`w-10 h-10 rounded-full button 
                                ${status_etat_traitement
                                    ?.etat_etape_traitement_id === etat_etape_traitement.id ? 
                                "text-white bg-theme-1" : "text-gray-600 bg-gray-200 dark:bg-dark-1"}`}>
                                    {index + 1}
                                </button>
                                <div className={`ml-3 text-base ${status_etat_traitement
                                    ?.etat_etape_traitement_id === etat_etape_traitement.id ? 
                                    "text-medium" : "text-gray-700"}`}>
                                    {etat_etape_traitement.libelle}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div> */}
            <Components.DocumentList documents={documents} isDisabled={isDisabled}/>
        </div>
    )
}