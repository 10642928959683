import { useCallback, useEffect, useMemo, useState } from "react";
import { Utils } from "../utils";
import { Hooks } from "../hooks";
import { Components } from "../components";

export function ProfileView(props) {
    const abortController = useMemo(() => new AbortController(), []);

    const useUser = Hooks.useUser();
    const [, setErrorMessages] = useState([]);

    const handleUserSubmit = async e => {
        e.preventDefault();
        useUser.setIsDisabled(true);

        try {
            const {user} = await useUser.updateUser(abortController.signal);

            delete user.password

            Utils.Auth.setUser(user);
            alert('Vos informations ont bien été modifiées !');
        } catch (error) {
            if ('messages' in error) {
                const messages = await error.messages

                setErrorMessages(messages);
                alert(messages.join(' '));
            }
        }finally {
            useUser.setIsDisabled(false);
        }
    }

    const initalize = useCallback(async () => {
        useUser.setIsDisabled(true);
        try {
            await useUser.getUser(abortController.signal);
        } catch (error) {
            if ('messages' in error) return;
            //Toast
        }
    }, [abortController])

    useEffect(() => {
        initalize()
    }, [initalize])
    
    return (
        <div className="col-span-12">
        <div className="intro-y flex items-center mt-8">
            <h2 className="text-lg font-medium mr-auto">
                Modifier le profil
            </h2>
        </div>
        <div className="grid grid-cols-12 gap-6 mt-4">
            <div className="sm:col-span-7 col-span-12">
                <Components.UserForm useUser={useUser} isDisabled={useUser.isDisabled}
                handleFormSubmit={handleUserSubmit}/>
            </div>
        </div>
        </div>
    )
}