export const MODIFICATION_STATUTAIRE = [
  {
    name: "connaissance",
    question: "Veuillez entrer le nom ou le numéro RCCM de l'entreprise",
    description: "",
    type: "",
    options: [
      // {value: "Veuillez saisir votre numéro de téléphone (Whatsapp) ", price: ""},
    ],
  },

  {
    name: "denomination",
    question: "Quel est le nom de votre entreprise ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "numero-rccm",
    question: "Veuillez saisir le numéro RCCM",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "ville-immatriculation",
    question: "Dans quelle ville avez-vous enregistré votre entreprise ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "localisation-siege",
    question: "Quelle est l'adresse  de votre entreprise?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // { value: "A mon domicile", price: "" },
      // // { value: "Je domicilie à Legafrik (30 000 FCFA/mois)", price: "" },
      // { value: "Je loue un local commercial", price: "" },
      // { value: "Je n'ai pas de siège", price: "" },
    ],
  },
 
  {
    name: "nouvelle-forme-juridique",
    question: "Quelle est la forme juridique de votre entreprise? ",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "SARL (Société à responsabilité limitée)", price: "" },
      { value: "SARLU (SARL unipersonnelle)", price: "" },
      // { value: "Je domicilie à Legafrik (30 000 FCFA/mois)", price: "" },
      { value: "SAS (Société par actions simplifiée)", price: "" },
      {
        value: "SASU (Société par actions simplifiée unipersonnelle)",
        price: "",
      },
      { value: "Entreprise Individuelle", price: 120_000 },
      { value: "SCI (Société civile immobilière)", price: "" },
    ],
  },
  
  {
    name: "capital-social",
    question: "Quel est votre capital social ?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      { value: "100 000 FCFA", price: "" },
      { value: "1 000 000 FCFA", price: "" },
      { value: "100 000 000 FCFA", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  {
    name: "insertion-journal",
    question:
      "Souhaiter vous que LEGAFRIK s'occupe de votre insertion dans un journal d’annonce légale?",
    description: "",
    type: "mutiple", //"mutiple",
    options: [
      {
        value:
          "Je confie mon insertion dans un journal d’annonce légale à LEGAFRIK",
        price: 30000,
      },
      { value: "Je ne suis pas intéressé ", price: "" },
    ],
  },
  // {
  //   name: "numero-telephone",
  //   question: "Veuillez saisir votre numéro de téléphone (Whatsapp)",
  //   description: "",
  //   type: "simple",
  //   options: [
  //     // {value: "Veuillez saisir votre numéro de téléphone (Whatsapp) ", price: ""},
  //   ],
  // },
  // {
  //   name: "email",
  //   question: "Veuillez saisir votre adresse mail",
  //   description: "",
  //   type: "simple",
  //   options: [
  //     // {value: "Veuillez saisir votre adresse mail", price: ""},
  //   ],
  // },
  // {
  //   name: "nom",
  //   question: "Veuillez saisir votre nom",
  //   description: "",
  //   type: "simple",
  //   options: [
  //     // {value: "Veuillez saisir votre nom", price: ""},
  //   ],
  // },
  // {
  //   name: "prenom",
  //   question: "Veuillez saisir votre prénom(s)",
  //   description: "",
  //   type: "simple",
  //   options: [
  //     // {value: "Veuillez saisir votre prénom(s)", price: ""},
  //   ],
  // },

  {
    name: "modification-du-statut",
    question: "Quelles modifications voulez-vous apporter à votre entreprise ?",
    description: "Vous pouvez faire plusieurs modifications en même temps !",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Je change le nom de  mon entreprise", price: "" },
      { value: "Je modifie mes activités", price: "" },
      { value: "Je transfère mon siège social", price: "" },
      { value: "Je nomme un nouveau Gérant/Président", price: "" },
      { value: "J augmente mon capital social", price: "" },
      { value: "Je cède des parts", price: "" },
    ],
  },

  // {
  //   name:"valeur-de-la-modification",
  //   question: "",
  //   description: "",
  //   type: "simple", //"mutiple",
  //   options: [
  //     // {value: "", price: ""}
  //   ],
  // },
  // {
  //   name:"vendeur-part",
  //   question: "",
  //   description: "",
  //   type: "simple", //"mutiple",
  //   options: [
  //     // {value: "", price: ""}
  //   ],
  // },
  // {
  //   name:"nouveau-capital",
  //   question: "",
  //   description: "",
  //   type: "simple", //"mutiple",
  //   options: [
  //     // {value: "", price: ""}
  //   ],
  // },
  // {
  //   name:"nouveau-siege",
  //   question: "",
  //   description: "",
  //   type: "simple", //"mutiple",
  //   options: [
  //     // {value: "", price: ""}
  //   ],
  // },
  // {
  //   name: "nombre-associes",
  //   question: "Combien d’associés êtes-vous ?",
  //   description: "",
  //   type: "simple", //"mutiple",
  //   options: [
  //     // {value: "", price: ""}
  //   ],
  // },
  // {
  //   name: "banque",
  //   question: "Dans quelle banque le compte sera-t-il ouvert ?",
  //   description: "",
  //   type: "simple", //"mutiple",
  //   options: [
  //     // {value: "", price: ""}
  //   ],
  // },
  // {
  //   name: "entreprise-anterieur",
  //   question: "Avez-vous déjà créé une entreprise ?",
  //   description: "",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     { value: "Oui", price: "" },
  //     { value: "Non", price: "" },
  //   ],
  // },
  // {
  //   name: "date-reception-certificat",
  //   question:
  //     "Quand voulez-vous recevoir votre certificat d'immatriculation (IDU) ?",
  //   description: "",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     { value: "1 semaine", price: "" },
  //     { value: "Je ne sais pas encore", price: "" },
  //   ],
  // },
  // {
  //   name: "localisation-ville",
  //   question: "Dans quelle ville se situe votre organisation ?",
  //   description:
  //     "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     { value: "Abidjan", price: 60_000 },
  //     { value: "Hors d'Abidjan", price: "" },
  //   ],
  // },

  // {
  //   name: "offre",
  //   question: "Quelle Offre choisissez-vous ?",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     {
  //       value: "Abidjan: capital inferieur ou egal à 1 million (330 000 FCFA)",
  //       price: 330_000,
  //     },
  //     {
  //       value:
  //         "Interieur du pays: capital inferieur ou egal à 1 millon (399 000 FCFA)",
  //       price: 399_000,
  //     },
  //   ],
  // },
  // {
  //   name: "gestion-domiciliation",
  //   question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
  //   description: "Choisissez une formule",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     { value: "6 mois à 180 000 FCFA (30 000 F/MOIS)", price: 180_000 },
  //     { value: "12 mois à 300 000 FCFA (25 000 F/MOIS)", price: 300_000 },
  //     { value: "Je ne suis pas intéressé", price: "" },
  //   ],
  // },
  // {
  //   name: "gestion-dfe",
  //   question:
  //     "Souhaitez-vous que Legafrik s'occupe de votre formalité de rattachement fiscal et d'obtention de Déclaration Fiscale D'existence (DFE) ?",
  //   description:
  //     "Il est obligatoire d'effectuer une formalité de rattachement au centre d’impôt de votre entreprise dans les 10 jours suivant sa création sous peine d'une amende de 100 000 FCFA.",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     {
  //       value: "Je confie mon rattachement fiscal à Legafrik",
  //       price: 60_000,
  //     },
  //     // {
  //     //   value: "Je confie à Légafrik sans rattachement  DFE",
  //     //   price: 30_000,
  //     // },
  //     { value: "Je le gère moi-même", price: "" },
  //   ],
  // },
  // {
  //   name: "gestion-graphique",
  //   question:
  //     "Avez-vous pensé à donner une image de marque à votre entreprise avec un logo et une charte graphique faits par des professionnels ?",
  //   description: "",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     {
  //       value:
  //         "Je confie mon logo et ma charte graphique à Legafrik (100 000 FCFA)",
  //       price: 100_000,
  //     },
  //     { value: "Je ne suis pas intéressé", price: "" },
  //   ],
  // },
  // {
  //   name: "gestion-comptable",
  //   question:
  //     "Souhaitez-vous bénéficier de notre solution d'accompagnement comptable ?",
  //   description: "",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     { value: "Je confie ma compta à Legafrik (85 000/mois)", price: 225_000 },
  //     { value: "J'ai déjà un comptable", price: "" },
  //   ],
  // },
  // {
  //   name: "gestion-facture-normalisees",
  //   question:
  //     "Souhaitez-vous que Legafrik s'occupe des formalités d'obtention de vos factures normalisées ?",
  //   description:
  //     "Toutes entreprises exerçant en en Côte d'Ivoire a l'obligation de délivrer des factures normalisées sous peine d'amende.",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     { value: "Lot de 50 factures normalisées à 75 000 FCFA", price: 75_000 },
  //     {
  //       value: "Lot de 100 factures normalisées à 140 000 FCFA",
  //       price: 140_000,
  //     },
  //     {
  //       value: "Lot de 200 factures normalisées à 260 000 FCFA",
  //       price: 260_000,
  //     },
  //     // {
  //     //   value: "Lot de 200 factures normalisées à 130 000 FCFA",
  //     //   price: 130_000,
  //     // },
  //     { value: "Je ne suis pas intéressé", price: "" },
  //   ],
  // },
  // {
  //   name: "solution-pay",
  //   question:
  //     "Souhaitez-vous bénéficier de notre solution d'accompagnement RH ?",
  //   description:
  //     "(Automatisation de la paie; rédaction contrat de travail; édition bulletin de paie; Déclaration rapide des salariés; gestion des absences et congés) ",
  //   type: "mutiple", //"mutiple",
  //   options: [
  //     {
  //       value: "Je confie ma gestion RH à Legafrik (85 000 Fr/Mois)",
  //       price: 225_000,
  //     },
  //     { value: "J'ai déja un RH", price: "" },
  //   ],
  // },
];
