import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import * as Icons from "react-feather";
import PhoneInput from "react-phone-input-2";

export function AdresseOneStep(props) {
  const [adresse, setAddresse] = useState({});

  const handleInputChange = (name, value) => {
    const adresseCopy = { ...adresse };
    adresseCopy[name] = value;

    setAddresse(adresseCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();
    props.handleNextClick(e, "adresse", JSON.stringify(adresse));
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 box p-4   
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12">
        <strong>Quelle est l’adresse de votre siège social ? </strong>
      </div>
      <div className="intro-y col-span-12  ">
        <Alert variant="danger">
          <Alert.Heading>
            <Icons.Info className="w-4 h-4 " />
          </Alert.Heading>
          <Alert.Link href="#">L’adresse de votre siège social </Alert.Link>
          est tout simplement l'adresse géographique de vos bureaux. Si vous
          n’avez pas encore de bureaux officiels, vous pouvez utiliser le
          domicile du président comme siège et mentionner son adresse
        </Alert>
        <small>
          Indiquez les informations relatives à l'adresse géographique du siège
          social
        </small>
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Ville</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Ville"
          onChange={(e) => handleInputChange("ville", e.target.value)}
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Quartier</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Quartier"
          onChange={(e) => handleInputChange("quartier", e.target.value)}
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Commune</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Commune"
          onChange={(e) => handleInputChange("commune", e.target.value)}
        />
      </div>
      {/* <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Adresse </div>
                <input type="text" className="input w-full border flex-1"
                placeholder="Adresse" onChange={e => 
                handleInputChange('adresse-personnelles', e.target.value)}/>
            </div> */}

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Numéro de boîte postale</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Numéro boite postale"
          onChange={(e) =>
            handleInputChange("numero-boite-postale", e.target.value)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Civilité du proprietaire</div>
        <select
          type="text"
          className="input w-full border flex-1 field"
          onChange={(e) =>
            handleInputChange("civilite-proprietaire", e.target.value)
          }
        >
          <option value="monsieur">Monsieur</option>
          <option value="madame">Madame</option>
          <option value="mademoiselle">Mademoiselle</option>
        </select>
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Nom du propriétaire du local</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Nom proprietaire"
          onChange={(e) =>
            handleInputChange("nom-proprietaire", e.target.value)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Prénom(s) du propriétaire du local</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Prenom proprietaire"
          onChange={(e) =>
            handleInputChange("prenoms-proprietaire", e.target.value)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Numéro de téléphone du propriétaire du local</div>
        <PhoneInput
          country={"ci"}
          // value={e.target.value
          onChange={(e) =>
            handleInputChange("numero-telephone-proprietaire", e)
          }
          autoFormat={false}
          inputStyle={{
            padding: "21px1 20px 21px 52px",
            width: "100%",
            boxShadow: "0px 3px 5px #00000007",
            borderColor: "rgba(226, 232, 240, 1)",
          }}
          placeholder="Numéro de téléphone"
        />
        {/* <input
          type="tel"
          className="input w-full border flex-1"
          placeholder="Numéro téléphone"
          onChange={(e) =>
            handleInputChange("numero-telephone-proprietaire", e.target.value)
          }
        /> */}
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">
          Numéro de pièces d'identité du propriétaire du local
        </div>
        <input
          type="tel"
          className="input w-full border flex-1"
          placeholder="Numéro de pièces d'identité"
          onChange={(e) =>
            handleInputChange("numero-piece-proprietaire", e.target.value)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Adresse mail du propriétaire du local</div>
        <input
          type="email"
          className="input w-full border flex-1"
          placeholder="Email"
          onChange={(e) =>
            handleInputChange("adresse-email-proprietaire", e.target.value)
          }
        />
      </div>
      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 
                text-gray-600 dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
