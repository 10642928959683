import { Link, useNavigate } from "react-router-dom";
// import placeholderProfileImg from "../assets/images/profile-1.jpg";
import { Utils } from "../utils";
import { Services } from "../services";
import * as Icons from "react-feather";

export function TopBar(props) {
  const navigate = useNavigate();

  return (
    <div className="top-bar  sm:hidden" style={{ position: "relative", zIndex: 50 }}>
      <div className="-intro-x breadcrumb mr-auto hidden sm:flex">
        {/* <a href="/" className="">Application</a> 
                <Icons.ChevronRight className='breadcrumb breadcrumb__icon' size={13}/>
            <a href="/" className="breadcrumb--active">Tableau de bord</a>  */}
      </div>
      <div className="intro-x dropdown mr-auto sm:mr-6"></div>
      <div className="intro-x dropdown w-8 h-8 ">
        <div
          className="dropdown-toggle bg-theme-1 w-8 h-8 rounded-full overflow-hidden 
                shadow-lg image-fit  zoom-in"
          onClick={() => Utils.Dom.toggleVisibility("#profile-dropdown")}
        >
          {/* <img
            alt="Midone Tailwind HTML Admin Template"
            src={placeholderProfileImg}
          /> */}
          <Icons.BarChart2
            className="dropdown-toggle  w-8 h-8 rounded-full overflow-hidden 
            shadow-lg image-fit zoom-in text-white   transform -rotate-90"
          />
        </div>
        <div className="dropdown-box w-56" id="profile-dropdown">
          <div className="dropdown-box__content box bg-theme-38 dark:bg-dark-6 text-white">
            <div className="p-4 border-b border-theme-40 dark:border-dark-3">
              <div className="font-medium">
                {Utils.Auth.getUser()?.username ?? "Utilisateur"}
              </div>
              <div className="text-xs text-theme-41 dark:text-gray-600">
                En ligne
              </div>
            </div>
            <div className="p-2">
              <Link
                to="/profil"
                className="flex items-center block p-2 transition duration-300 
                            ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
              >
                <Icons.User className="w-4 h-4 mr-2" /> Profil
              </Link>
              <Link
                to="/nouveau-mot-de-passe"
                className="flex items-center block p-2 transition duration-300 
                            ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
              >
                <Icons.Lock className="w-4 h-4 mr-2" /> Mot de passe
              </Link>
            </div>
            <div className="p-2 border-t border-theme-40 dark:border-dark-3">
              <button
                className="flex items-center block p-2 transition duration-300 
                            ease-in-out bg-theme-1 hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                style={{ width: "100%" }}
                onClick={() => Services.AuthService.logout(navigate)}
              >
                <Icons.ToggleRight className="w-4 h-4 mr-2" /> Deconnexion
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
